import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';

@Component({template: ''})
export class BaseComponent implements OnDestroy {

  private subscriptionsList: Subscription[] = [];

  /**
   * Call for each subscription done inside init.
   *
   * ex:
   * ```ts
   *  super.addSubscription(
   *    exampleObservable.subscribe(() => {
   *      // inner code
   *    })
   *  );
   * ```
   */
  protected addSubscription(sub: Subscription): void {
    this.subscriptionsList.push(sub);
  }

  ngOnDestroy(): void {
    // Handles automatic unsubscription on destroy
    this.subscriptionsList.forEach((sub) => sub.unsubscribe());
  }
}
