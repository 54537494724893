import {Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {BaseComponent} from '../../shared/core/base-component';
import {MessageBusService} from '../../shared/core/message-bus.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})
export class IframeComponent extends BaseComponent implements OnChanges {

  @ViewChild('my_iframe', {static: true}) iframe!: ElementRef;
  @Input() path!: string;
  @Input() params!: any;
  @Input() url!: string;

  frameUrl = '';

  constructor(private sanitizer: DomSanitizer, private msgBus: MessageBusService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.path) {
      /*
       * First change must load the iframe
       * from the second on, send navigation messages
       */
      if (changes.url.isFirstChange()) {
        const basePath = location.href.substr(0, location.href.lastIndexOf('/#'));
        this.frameUrl = basePath + environment.ANGULAR_JS_APP_RELATIVE_PATH + '/#' + this.url;
      } else {
        this.msgBus.navigateIframeTo({params: this.params, path: this.path});
      }
    } else if (changes.params) {
      if (changes.params.isFirstChange() || changes.params.previousValue !== changes.params.currentValue) {
        this.msgBus.navigateIframeTo({params: this.params, path: this.path});
      }
    }
  }

  onLoadCompleted(): void {
    const iframeWindow: Window = this.iframe.nativeElement.contentWindow;
    if (iframeWindow) {
      // Register iframe's window for post message
      this.msgBus.registerIframe(iframeWindow);
    }
  }
}
