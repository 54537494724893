import {Component, OnInit} from '@angular/core';
import {CrudInlineComponent} from '../../../shared/crud/crud-inline.component';
import {
  GetListaGruppiUORequest,
  GruppiUnitaOperativeControllerService,
  GruppoUnitaOperativa, GruppoUnitaOperativaSearch,
  UnitaOperativa,
  UnitaOperativeSearch
} from '../../../api';
import {GrantsService} from '../../../shared/core/grants.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {UnitaOperativaService} from './unita-operativa.service';

@Component({
  selector: 'app-unita-operativa',
  templateUrl: './unita-operativa.component.html',
  styleUrls: ['./unita-operativa.component.scss']
})
export class UnitaOperativaComponent extends CrudInlineComponent<UnitaOperativa, UnitaOperativa, any> implements OnInit {
  idSerCliCon?: number;
  insertEnabled = false;
  eliminaEnabled = false;
  updateEnabled = false;
  updateClickable = false;
  eliminaClickable = false;
  editingEnabled = false;
  addClickable = false;
  exportPdfClickable = false;
  exportXlsClickable = false;
  refreshClickable = false;
  columnComboReadOnly = true;
  listaGruppiUnitaOperative: GruppoUnitaOperativa[] | undefined = [];
  gruppiUOService: GruppiUnitaOperativeControllerService;
  constructor(grantsService: GrantsService, dialogService: DialogService, editService: UnitaOperativaService, gruppiUOService: GruppiUnitaOperativeControllerService) {
    super(grantsService, dialogService, editService);
    this.gruppiUOService = gruppiUOService;
  }

  public getFunctionCode(): string {
    return 'UOP';
  }

  public ngOnInit(): void {
    if (!this.idSerCliCon){
      this.dataSource.clear();
    }
    super.ngOnInit();
  }

  protected postInit(): void {
    this.insertEnabled = this.userGrants.insert;
    this.eliminaEnabled = this.userGrants.delete;
    this.updateEnabled = this.userGrants.update;
  }

  editRecord(): void {
    super.editRecord();
    this.columnComboReadOnly = false;
  }

  cancelEditing(): void {
    super.cancelEditing();
    this.columnComboReadOnly = true;
  }

  onChangeGruppo(event: any, dataItem: any): void {
    dataItem.idGruppoUo = event;
  }

  saveRecord(): void {
    super.saveRecord();
    this.columnComboReadOnly = true;
  }

  addNewRecord(): void{
    super.addNewRecord();
    this.columnComboReadOnly = false;
  }

  handleSearch(idSerCliCon: number): void{
    this.idSerCliCon = idSerCliCon;
    if (idSerCliCon > 0) {
      this.addClickable = true;
      this.exportPdfClickable = true;
      this.exportXlsClickable = true;
      this.refreshClickable = true;
      const request: GetListaGruppiUORequest = {
        parametriRicerca: {
          idSerCliCon: this.idSerCliCon
        }
      };
      this.gruppiUOService.getListaGruppiUnitaOperativeUsingPOST(request).subscribe(value => {
        this.listaGruppiUnitaOperative = value.lista;
        const search: UnitaOperativeSearch = {
          idSerCliCon: this.idSerCliCon
        };
        this.dataSource.read(search);
      }, error => {
      });
    } else {
      this.addClickable = false;
      this.exportPdfClickable = false;
      this.exportXlsClickable = false;
      this.refreshClickable = false;
      this.dataSource.clear();
    }
  }

  fillNewRecord(newRecord: UnitaOperativa): void {
    newRecord.idSerCliCon = this.idSerCliCon;
  }

  onRowSelected(event: number[]): void{
    if (event && event.length !== 0){
      this.eliminaClickable = true;
      this.updateClickable = true;
    } else {
      this.eliminaClickable = false;
      this.updateClickable = false;
    }
  }

  public getDescrGruppoUo(id: number): any {
    return this.listaGruppiUnitaOperative?.find((x) => x.idGruppoUnitaOp === id);
  }
}
