import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {
  GetListaStatSegnalazioneRequest,
  SegnalazioneSearch,
  StatisticheSegnalazioniControllerService
} from '../../../api';
import {Observable} from 'rxjs';
import {GridComponent, GridDataResult} from '@progress/kendo-angular-grid';
import {process, State} from '@progress/kendo-data-query';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {StatSegnSegnalazioneService} from './stat-segn-segnalazione.service';
import {map} from "rxjs/operators";
import {Group} from "@progress/kendo-drawing";

@Component({
  selector: 'app-stat-segnalazioni',
  templateUrl: './stat-segnalazioni.component.html',
  styleUrls: ['./stat-segnalazioni.component.scss']
})
export class StatSegnalazioniComponent implements OnInit {

  public view!: Observable<GridDataResult>;
  @ViewChildren(GridComponent) public grids: QueryList<GridComponent> | undefined;

  public  tebSelected: string | undefined;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100,
  };

  idSerCliCon?: number;
  exportPdfClickable = false;
  exportXlsClickable = false;
  ricerca = {};
  disableStatusFilter = false;
  constructor(public dataSource: StatSegnSegnalazioneService) { }

  ngOnInit(): void {
    this.dataSource.clear();
    this.tebSelected = 'Segnalazioni';
    this.dataSource.setTabSelected(this.tebSelected);
    this.view = this.dataSource.pipe(
      map((data) => process(data || [], this.gridState))
    );
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.dataSource.read();
  }

  refresh(): void {

  }

  exportExcel(): void {
    // @ts-ignore
    this.grids?.forEach((elemento, index) => {
      switch (this.tebSelected) {
        case 'Segnalazioni':
          if (index === 0) {
            const pageSizeOld = elemento.pageSize;
            if (this.dataSource.data){
              elemento.pageSize = this.dataSource.data.length;
            }
            elemento.saveAsExcel();
            elemento.pageSize = pageSizeOld;
          }
          break;
        case 'Analisi Segnalazione':
          if (index === 1) {
            const pageSizeOld = elemento.pageSize;
            if (this.dataSource.data){
              elemento.pageSize = this.dataSource.data.length;
            }
            elemento.saveAsExcel();
            elemento.pageSize = pageSizeOld;
          }
          break;
        case 'Analisi Segnalatori':
          if (index === 2) {
            const pageSizeOld = elemento.pageSize;
            if (this.dataSource.data){
              elemento.pageSize = this.dataSource.data.length;
            }
            elemento.saveAsExcel();
            elemento.pageSize = pageSizeOld;
          }
          break;
        default:
          if (index === 0) {
            const pageSizeOld = elemento.pageSize;
            if (this.dataSource.data){
              elemento.pageSize = this.dataSource.data.length;
            }
            elemento.saveAsExcel();
            elemento.pageSize = pageSizeOld;
          }
      }
    });
  }

  exportPdf(): void {
    this.grids?.forEach((elemento, index) => {
      switch (this.tebSelected) {
        case 'Segnalazioni':
          if (index === 0) {
            elemento.saveAsPDF();
          }
          break;
        case 'Analisi Segnalazione':
          if (index === 1) {
            elemento.saveAsPDF();
          }
          break;
        case 'Analisi Segnalatori':
          if (index === 2) {
            elemento.saveAsPDF();
          }
          break;
        default:
          if (index === 0) {
            elemento.saveAsPDF();
          }
      }
    });
  }

  handleSearch(searchParams: any): void {
    this.idSerCliCon = searchParams.idSerCliCon;
    this.ricerca = {idSerCliCon: searchParams.idSerCliCon
        , listStati: ((searchParams.selectedStates && searchParams.selectedStates.length !== 0 ) ? searchParams.selectedStates : [])
        , listIdGroup: (searchParams.idGruppoUO ? [searchParams.idGruppoUO] : [])
        , dataAperturaDa: searchParams.fromDate
        , dataAperturaA: searchParams.toDate};
    this.dataSource.read(this.ricerca);
    this.exportPdfClickable = true;
    this.exportXlsClickable = true;
  }

  tabSelectionChanged(e: any): void {
    this.dataSource.setTabSelected(e.heading);
    this.tebSelected = e.heading;
    this.dataSource.clear();
    if (this.idSerCliCon){
      this.dataSource.read(this.ricerca);
    }
    if ( this.tebSelected !== 'Segnalazioni'){
      this.disableStatusFilter = true;
    } else {
      this.disableStatusFilter = false;
    }
  }
}
