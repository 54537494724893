import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SafeUrlPipe} from './core/safeurl.pipe';
import {NAV_DROPDOWN_DIRECTIVES} from './nav-dropdown.directive';
import {BreadcrumbsComponent} from './breadcrumb.component';
import {SidemenuComponent} from './sidemenu.component';
import {SIDEBAR_TOGGLE_DIRECTIVES} from './sidebar.directive';
import {AsideToggleDirective} from './aside.directive';
import {RightNavItemsComponent} from './right-nav-items.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {RouterModule} from '@angular/router';
import {MenuModule} from '@progress/kendo-angular-menu';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ToolbarGridComponent} from './toolbar-grid/toolbar-grid.component';
import {TranslateModule} from '@ngx-translate/core';
import {ToolbarDetailComponent} from './toolbar-detail/toolbar-detail.component';
import {SercliconSearchComponent} from './serclicon-search/serclicon-search.component';
import {ComboBoxModule} from '@progress/kendo-angular-dropdowns';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {ContrattiSearchComponent} from './contratti-search/contratti-search.component';
import {ImageSelectorComponent} from './image-selector/image-selector.component';

@NgModule({
  declarations: [
    SafeUrlPipe,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SidemenuComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    RightNavItemsComponent,
    ConfirmDialogComponent,
    ToolbarGridComponent,
    ToolbarDetailComponent,
    // ModifyPasswordModalComponent
    SercliconSearchComponent,
    ContrattiSearchComponent,
    ImageSelectorComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MenuModule,
    BsDropdownModule,
    TranslateModule,
    ComboBoxModule,
    ButtonModule,
  ],
  exports: [
    SafeUrlPipe,
    BreadcrumbsComponent,
    RightNavItemsComponent,
    SidemenuComponent,
    ToolbarGridComponent,
    ToolbarDetailComponent,
    SercliconSearchComponent,
    ContrattiSearchComponent,
    ImageSelectorComponent
  ]
})
export class SharedModule {
}
