<div class="no-print">
  <header class="app-header navbar">

    <div class="left-nav-container">

      <!--      TOGGLE MENU BUTTON-->
      <a class="nav-link navbar-toggler" (click)="toggleMenu()" style="display: inline-block">&#9776;</a>

      <!--      LOGO ESCAPE-->
      <a class="navbar-brand" href="#">
        <img class="img-logo">
      </a>

      <!-- BREADCRUMBS-->
      <div class="d-inline-block">
        <ul class="nav navbar-nav">
          <li class="nav-item">
            <app-breadcrumbs></app-breadcrumbs>
          </li>
        </ul>
      </div>
    </div>

    <div class="center-nav-container">
      <!-- LINK EMSERVICES -->
      <div class="emservices-link-container">
        <a href="https://emservices.it" target="_blank">www.emservices.it</a>
      </div>
    </div>

    <div class="right-nav-container pr-3">
      <!--      DROPDOWN-->
      <app-right-nav-items></app-right-nav-items>
    </div>


  </header>
  <div class="app-body">
    <!-- Left menu-->
    <div class="sidebar" [ngClass]="menuVisible ? '' : 'sidebar-hidden'">
      <app-sidemenu></app-sidemenu>
      <div class="copyright">Version {{version}}<br>Copyright (C) E&amp;S s.r.l. - All Rights Reserved</div>
    </div>
    <!-- Main content -->
    <main class="main">
      <div class="main-container" [hidden]="printLoading">
        <div class="modal" tabindex="-1" role="dialog" id="mainDivLoader">
          <div id="loader"></div>
        </div>
        <router-outlet (activate)="routerActivated($event)"></router-outlet>
        <app-iframe [path]="iframeState.url" [params]="iframeState.params" [url]="iframeState.url"
                    [hidden]="!showIframe"></app-iframe>
        <!--      <ngx-json-viewer [json]="iframeState" [expanded]="false"></ngx-json-viewer>-->
      </div>

      <div class="print-loader" [hidden]="!printLoading">
        <p>Preparazione stampa in corso</p>
      </div>
    </main>
  </div>
  <div kendoDialogContainer></div>
  <div class="initial-loading" [hidden]="!showLoading">
    <img [src]="'assets/img/logo_simple.png'">
    <h1>Loading....</h1>
  </div>
</div>

<app-segnalazione-print
  class="print-container"
  *ngIf="segnalazionePrint != null"
  [segnalazioneDetail]="segnalazionePrint"
  (printReady)="afterPrint()"
></app-segnalazione-print>
