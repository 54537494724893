import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {SegnalazioneDetail} from '../../main/segnalazioni/segnalazione/segnalazione.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public $segnalazionePrint: Subject<SegnalazioneDetail | null> = new Subject<SegnalazioneDetail | null>();
}
