import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GrantsService, UserSubFunctionGrants} from '../core/grants.service';
import {CrudDatasource} from './crud-datasource';
import {GridComponent, GridDataResult, PagerSettings} from '@progress/kendo-angular-grid';
import {Observable} from 'rxjs';
import {process, State} from '@progress/kendo-data-query';
import {map} from 'rxjs/operators';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {CrudRecordVersioning} from './crud-detail.component';

@Component({
  template: ''
})
export abstract class CrudTabbedComponent<T, L, S> implements OnInit {
  public view!: Observable<GridDataResult>;
  public selectedRows: number[] = [];

  public pageable: PagerSettings | boolean = {};

  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100,
  };

  @ViewChild(GridComponent, {static: false}) dataGrid!: GridComponent;

  editingEnabled = false;
  userGrants!: UserSubFunctionGrants;
  oldRoute = '';
  public tabs: {
    key?: any,
    title: string,
    disabled: boolean,
    removable: boolean,
    active: boolean,
    record?: T
  }[] = [{
    title: 'Search',
    disabled: false,
    removable: false,
    active: true
  }];

  constructor(public router: Router, public activatedRoute: ActivatedRoute, private dialogService: DialogService,
              public grantsService: GrantsService, public dataSource: CrudDatasource<T, L, S>) {
    this.activatedRoute.params.subscribe(params => {
      if (params) {
        if (params.k) {
          const openedKeys = params.k.split(',');
          this.tabs.forEach(t => {
            if (t.key && !openedKeys.find((key: string) => (String(t.key) === String(key)))) {
              this.removeTab(t.key);
            }
          });

          openedKeys.forEach((key: any) => {
            if (+key >= 0) {
              const tabIndex = this.findTabIndexFromKey(key);
              if (!tabIndex) {
                this.addTabFromKey(key);
              }
            }
          });
        } else {
          this.tabs.forEach(t => {
            if (t.key) {
              this.removeTab(t.key);
            }
          });
        }
        if (params.t != null) {
          const selectedTab = Math.min(+params.t, this.tabs.length - 1);
          for (let i = 0; i < this.tabs.length; i++) {
            this.tabs[i].active = (selectedTab === i);
          }
        }
      }
    });
  }

  ngOnInit(): void {
    this.loadUserGrants(this.getFunctionCode());
    this.view = this.dataSource.pipe(
      map((data) => process(data || [], this.gridState))
    );
  }

  loadUserGrants(fnCode: string): void {
    this.grantsService.getFunctionGrants(fnCode).subscribe(value => {
      this.userGrants = value;
      this.postInit();
    }, err => {

    });
  }

  protected postInit(): void {
    this.dataSource.read();
  }

  public addTabFromKey(recordKey: string): void {
    // const newTabIndex = this.tabs.length + 1;

    const record: any = {};
    record[this.dataSource.getKeyField()] = recordKey;

    this.addTabFromRecord(record);
  }

  public addTabFromRecord(record: T): void {
    // const newTabIndex = this.tabs.length + 1;

    const recordId = this.dataSource.getRecordId(record);
    this.tabs.push({
      title: recordId < 0 ? '*NEW' : 'Loading...',
      key: recordId,
      disabled: false,
      removable: true,
      active: false,
      record
    });
  }

  removeTab(itemKey: string): void {
    const tabIndex = this.findTabIndexFromKey(itemKey);
    if (tabIndex) {
      this.tabs.splice(tabIndex, 1);
    }
  }

  findTabIndexFromKey(itemKey: string): number | undefined {
    let tabIndex;
    for (let i = 0; i < this.tabs.length; i++) {
      if (String(this.tabs[i].key) === String(itemKey)) {
        tabIndex = i;
        break;
      }
    }
    return tabIndex;
  }

  navigateOpenTab(keyToOpen?: string, keyToClose?: string): void {
    let newOpenedKeys = '';
    let newTabSelectedIndex = 0;
    let i = 0;
    this.tabs.forEach((tab) => {
      if (tab.key) {
        i++;
        const key = String(tab.key);
        if (key !== String(keyToClose)) {
          newOpenedKeys += (newOpenedKeys !== '' ? ',' + key : key);
        }
        if (key === String(keyToOpen)) {
          newTabSelectedIndex = i;
        }
      }
    });

    if (keyToOpen && newTabSelectedIndex === 0) {
      newOpenedKeys += (newOpenedKeys !== '' ? ',' + keyToOpen : keyToOpen);
      newTabSelectedIndex = keyToClose && +keyToClose < 0 ? this.tabs.length - 1 : this.tabs.length;
    }

    this.router.navigate(['./', {t: newTabSelectedIndex, k: newOpenedKeys}], {relativeTo: this.activatedRoute, queryParamsHandling: 'merge'});
  }

  public tabSelectionChanged(tab: any): void {
    const key = tab.key;
    this.navigateOpenTab(key ? String(key) : key, undefined);
    // setTimeout(() => {
    //   this.dataGrid.resizeGrid();
    // }, 10);
  }

  public updateTabData(recordVersioning: CrudRecordVersioning<T>): void {
    this.tabs.forEach(t => {
      if (t.key != null && String(t.key) === String(this.dataSource.getRecordId(recordVersioning.original))) {
        const newRec: T = recordVersioning.updated || recordVersioning.original;
        t.title = this.getTitle(newRec);
        t.key = this.dataSource.getRecordId(newRec);
        t.record = newRec;
      }
    });
  }

  removeTabHandler(tab: any): void {
    let toOpen!: string;
    let toClose!: string;
    this.tabs.forEach((value, index, array) => {
      if (String(value.key) === String(tab.key)) {
        toClose = tab.key;
        toOpen = array[index - 1].key;
      }
    });
    this.navigateOpenTab(toOpen, toClose);
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.dataSource.read();
  }

  public closeDetail(itemKey: any): void {
    this.navigateOpenTab(undefined, itemKey ? String(itemKey) : itemKey);
    // setTimeout(() => {
    //   this.dataGrid.resizeGrid();
    //   this.dataGrid.refresh();
    // }, 10);
  }

  public detailLoaded(rec: CrudRecordVersioning<T>): void {
    this.updateTabData(rec);
  }

  public detailSaved(rec: CrudRecordVersioning<T>): void {
    this.updateTabData(rec);
    this.refresh();
  }

  public detailDeleted(rec: T): void {
    this.refresh();
  }

  viewSelectedRecord(): void {
    this.oldRoute = this.router.url;
    if (this.selectedRows && this.selectedRows.length > 0) {
      this.navigateOpenTab(String(this.selectedRows[this.selectedRows.length - 1]), undefined);
    }
  }

  addNewRecord(): void {
    if (this.dataSource) {
      const newRecord = this.dataSource.newRecord();
      this.fillNewRecord(newRecord);
      this.addTabFromRecord(newRecord);
      this.navigateOpenTab(String(this.dataSource.getRecordId(newRecord)), undefined);
    }
  }

  fillNewRecord(newRecord: T): void {
  }

  deleteRecord(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Si prega di confermare',
      content: 'Sei sicuro?',
      actions: [{text: 'No'}, {text: 'Si', primary: true}],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log('close');
      } else {
        console.log('action', result);
        const selectedRow = this.selectedRows[this.selectedRows.length - 1];
        this.dataSource.deleteByKey(selectedRow).subscribe(
          () => {
            this.selectedRows = this.selectedRows.filter(s => s !== selectedRow);
          }
        );
      }
    });
  }

  copyRecord(): void {
    if (this.dataSource) {
      this.dataSource.cloneRecordByKey(this.selectedRows[this.selectedRows.length - 1]).subscribe((newRecord) => {
        this.addTabFromRecord(newRecord);
        this.navigateOpenTab(String(this.dataSource.getRecordId(newRecord)), undefined);
      });
    }
  }

  exportExcel(): void {
    // this.pageable = false;
    const gridStateTemp = {...this.gridState};
    this.gridState.take = this.dataSource.data?.length;
    this.gridState.skip = 0;
    this.dataSource.read();
    setTimeout(() => {
      this.dataGrid.saveAsExcel();
      this.gridState = gridStateTemp;
      this.dataSource.read();
      // this.pageable = {};
    }, 0);
  }

  exportPdf(): void {
    this.dataGrid.saveAsPDF();
  }

  refresh(): void {
    this.dataSource.getList().subscribe();
  }

  public abstract getTitle(record: T): string;

  public abstract getFunctionCode(): string;

}
