import {Injectable} from '@angular/core';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {
  CreaSegnalazioneRequest,
  Criticita,
  EliminaSegnalazioneRequest,
  EmptyResponse,
  GetListaSegnalazioniRequest,
  GetSegnalazioneRequest,
  Segnalazione,
  SegnalazioneSearch,
  SegnalazioniControllerService,
  StoricoSegnalazione,
  UploadAllegatoSegnalazioneResponse,
  UtenteGruppoUO
} from '../../../api';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {map, mergeMap} from 'rxjs/operators';
import {StatoSgn} from './stato-sgn';
import {ErrorService} from '../../../shared/core/error.service';

@Injectable({
  providedIn: 'root'
})
export class SegnalazioneService extends CrudDatasource<SegnalazioneDetail, Segnalazione, SegnalazioneSearch> {

  authSub: Subject<UtenteGruppoUO[]> = new Subject<UtenteGruppoUO[]>();
  autorizzazioni: Array<UtenteGruppoUO> | undefined = new Array();

  constructor(private service: SegnalazioniControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  getKeyField(): string {
    return 'id';
  }

  callGetByKey(key: any): Observable<SegnalazioneDetail | undefined> {
    const idReq: GetSegnalazioneRequest = {
      idSegnalazione: key
    };
    return this.service.getSegnalazioneUsingPOST(idReq)
      .pipe(
        map(resp => {
          const ret = {
            id: resp.segnalazione?.id || -1,
            segnalazione: resp.segnalazione || {} as Segnalazione,
            storicoSegnalazione: resp.listaStoricoSgn || [],
            autorizzazioni: resp.autorizzazioni || [],
            listaCriticita: resp.listaCriticita || []
          };
          return ret;
        })
      );
  }

  callInsert(row: SegnalazioneDetail): Observable<SegnalazioneDetail | undefined> {

    return this.uploadAllegatiSegnalazione(row)
      .pipe(mergeMap(all => {

        const req: CreaSegnalazioneRequest = {
          segnalazione: {
            ...row.segnalazione,
            firma: all.firmaFileName || row.segnalazione.firma,
            foto: all.fotoFileName || row.segnalazione.foto
          },
          listaCriticita: row.listaCriticita
        };

        return this.service.creaSegnalazioneUsingPOST(req).pipe(
          map(resp => {
            return {
              id: resp.segnalazione?.id || -1,
              segnalazione: resp.segnalazione || {} as Segnalazione,
              listaCriticita: row.listaCriticita || [],
              storicoSegnalazione: resp.listaStoricoSgn || []
            }
          })
        );
      }));
  }

  callLoad(searchRequest?: SegnalazioneSearch): Observable<Segnalazione[] | undefined> {
    const req: GetListaSegnalazioniRequest = {
      parametriRicerca: searchRequest
    };
    return this.service.getListaSegnalazioneUsingPOST(req)
      .pipe(
        map(resp => {
          this.authSub.next(resp.autorizzazioni);
          this.autorizzazioni = resp.autorizzazioni;
          return resp.segnalazioni;
        })
      );
  }

  callDelete(row: SegnalazioneDetail): Observable<EmptyResponse> {
    const req: EliminaSegnalazioneRequest = {
      segnalazione: row.segnalazione
    };
    return this.service.eliminaSegnalazioneUsingPOST(req);
  }

  callTotalCount(loadOptions: any): Observable<number> {
    return this.service.getListaSegnalazioneUsingPOST({})
      .pipe(
        map((resp) => {
          return resp.segnalazioni?.length || 0; // FIXME aggiungere metodo server per il count se necessario o eliminare
        })
      );
  }

  callUpdate(key: any, row: SegnalazioneDetail): Observable<SegnalazioneDetail | undefined> {

    return this.uploadAllegatiSegnalazione(row)
      .pipe(mergeMap(all => {
        const req: CreaSegnalazioneRequest = {
          segnalazione: {
            ...row.segnalazione,
            firma: all.firmaFileName || row.segnalazione.firma,
            foto: all.fotoFileName || row.segnalazione.foto
          },
          listaCriticita: row.listaCriticita
        };

        return this.service.modificaSegnalazioneUsingPOST(req).pipe(
          map(resp => {
            const ret: SegnalazioneDetail = {
              id: resp.segnalazione?.id || -1,
              segnalazione: resp.segnalazione || {} as Segnalazione,
              storicoSegnalazione: resp.listaStoricoSgn || [],
              listaCriticita: resp.listaCriticita || [],
            };
            return ret;
          })
        )
      }));
  }

  newRecordWithKey(key?: any): SegnalazioneDetail {
    const ret = super.newRecordWithKey(key);
    ret.segnalazione = {
      stato: StatoSgn.APER,
      dataApertura: this.getDateStringForNewSegn(),
      flagUrgenza: false
    } as Segnalazione;
    ret.autorizzazioni = this.autorizzazioni;
    ret.storicoSegnalazione = [];
    return ret;
  }

  private getDateStringForNewSegn(): string {
    const data = new Date();
    return data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate() + 'T' + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds() + '.' + data.getMilliseconds() + 'Z';
  }

  uploadAllegatiSegnalazione(row: SegnalazioneDetail): Observable<AllegatiSegnalazione> {

    // FOTO
    let foto$: Observable<UploadAllegatoSegnalazioneResponse> = row.foto ? this.service.uploadAllegatoSegnalazioneUsingPOST(row.foto) : of({fileName: undefined});

    // FIRMA
    let firma$: Observable<UploadAllegatoSegnalazioneResponse> = row.firma ? this.service.uploadAllegatoSegnalazioneUsingPOST(row.firma) : of({fileName: undefined});

    return forkJoin([foto$, firma$])
      .pipe(
        map(([foto, firma]) => {
          const ret: AllegatiSegnalazione = {
            fotoFileName: foto.fileName,
            firmaFileName: firma.fileName
          }
          return ret;
        })
      );
  }
}

export interface SegnalazioneDetail {
  id: number;
  segnalazione: Segnalazione;
  listaCriticita: Criticita[];
  storicoSegnalazione: StoricoSegnalazione[];
  autorizzazioni?: Array<UtenteGruppoUO>;
  nota?: string;
  foto?: Blob;
  firma?: Blob;
}

interface AllegatiSegnalazione {
  fotoFileName?: string;
  firmaFileName?: string;
}

