import {Component, OnInit} from '@angular/core';
import {CrudTabbedComponent} from '../../../shared/crud/crud-tabbed.component';
import {Segnalazione, SegnalazioneSearch, SegnalazioniControllerService, UtenteGruppoUO} from '../../../api';
import {ActivatedRoute, Router} from '@angular/router';
import {GrantsService} from '../../../shared/core/grants.service';
import {SegnalazioneDetail, SegnalazioneService} from './segnalazione.service';
import {DialogService} from '@progress/kendo-angular-dialog';
import {StatoSgn} from './stato-sgn';

@Component({
  selector: 'app-segnalazione',
  templateUrl: './segnalazione.component.html',
  styleUrls: ['./segnalazione.component.scss']
})
export class SegnalazioneComponent extends CrudTabbedComponent<SegnalazioneDetail, Segnalazione, SegnalazioneSearch> implements OnInit {
  permissionSet = new Set<string>();
  insertEnabled = false;
  eliminaEnabled = false;
  eliminaClickable = false;
  openClickable = false;
  addClickable = false;
  exportPdfClickable = false;
  exportXlsClickable = false;
  refreshClickable = false;
  idSerCliCon?: number;
  selectedRows: number[] = [];

  constructor(public router: Router, public activatedRoute: ActivatedRoute, dialogService: DialogService,
              public grantsService: GrantsService, public api: SegnalazioniControllerService, dataSource: SegnalazioneService) {
    super(router, activatedRoute, dialogService, grantsService, dataSource);
  }

  public ngOnInit(): void {
    if (!this.idSerCliCon){
      this.dataSource.clear();
    }
    super.ngOnInit();
  }

  protected postInit(): void {
    (this.dataSource as SegnalazioneService).authSub.subscribe(value => {
      this.createPermissionSet(value);
      this.gestisciAbilitazioniPerSegnalazione();
    });
  }

  getFunctionCode(): string {
    return 'SGN';
  }

  getTitle(record: SegnalazioneDetail): string {
    return String(record.segnalazione?.codiceSegnalazione);
  }

  private createPermissionSet(autorizzazioni: Array<UtenteGruppoUO> | undefined): void {
    if (autorizzazioni) {
      autorizzazioni.forEach(value => {
        if (value.flag_attivazione) {
          this.permissionSet.add(value.cod_stato + '#' + value.id_gruppoUnitaOp);
        }
      });
    }
  }

  private canDoFromState(stato: string): boolean {
    let ret = false;
    this.permissionSet.forEach(t => {
      if (t.includes(stato)) {
        ret = true;
      }
    });
    return ret;
  }

  private gestisciAbilitazioniPerSegnalazione(): void {
    // MODIFICA
    this.insertEnabled = this.userGrants.insert && this.canDoFromState(StatoSgn.APER);

    // ELIMINA
    this.eliminaEnabled = this.userGrants.delete && this.canDoFromState(StatoSgn.APER);
  }

  onRowSelected(event: number[]): void {
    if (event && event.length !== 0) {
      this.eliminaClickable = true;
      this.openClickable = true;
    } else {
      this.eliminaClickable = false;
      this.openClickable = false;
    }
  }

  deleteRecord(): void {
    super.deleteRecord();
    this.eliminaClickable = false;
    this.openClickable = false;
  }

  fillNewRecord(newRecord: SegnalazioneDetail): void {
    newRecord.segnalazione.id_serCliCon = this.idSerCliCon;
  }

  handleSearch(idSerCliCon: number): void {
    this.idSerCliCon = idSerCliCon;
    if (idSerCliCon > 0) {
      this.addClickable = true;
      this.exportPdfClickable = true;
      this.exportXlsClickable = true;
      this.refreshClickable = true;

      const search: SegnalazioneSearch = {} as SegnalazioneSearch;
      search.id_serCliCon = this.idSerCliCon;
      this.dataSource.read(search);
    } else {
      this.addClickable = false;
      this.exportPdfClickable = false;
      this.exportXlsClickable = false;
      this.refreshClickable = false;
      this.dataSource.clear();
    }
  }

  gridDbclick(clicked: any): void{
    super.viewSelectedRecord();
  }


}
