import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  CreaGruppoUnitaOperativaRequest,
  EliminaGruppoUnitaOperativaRequest,
  GetGruppoUnitaOperativaRequest,
  GetListaGruppiUORequest,
  GruppiUnitaOperativeControllerService,
  GruppoUnitaOperativa,
  GruppoUnitaOperativaSearch,
  ModificaGruppoUnitaOperativaRequest
} from '../../../api';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {ErrorService} from '../../../shared/core/error.service';

@Injectable({ providedIn: 'root' })
export class GruppoUnitaOperativaService extends CrudDatasource<GruppoUnitaOperativa, GruppoUnitaOperativa, GruppoUnitaOperativaSearch> {

  constructor(private service: GruppiUnitaOperativeControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  callLoad(searchRequest: GruppoUnitaOperativaSearch): Observable<GruppoUnitaOperativa[] | undefined> {
    if (searchRequest) {
      const req: GetListaGruppiUORequest = {
        parametriRicerca: searchRequest
      };
      return this.service.getListaGruppiUnitaOperativeUsingPOST(req).pipe(
        map(res => res.lista)
      );
    } else {
      return of([]);
    }
  }


  callGetByKey(key: any): Observable<GruppoUnitaOperativa | undefined> {
    const req: GetGruppoUnitaOperativaRequest = {
      idGruppo: key
    };
    return this.service.getGruppoUnitaOperativaUsingPOST(req).pipe(
      map(res => res.guo)
    );
  }

  callInsert(values: GruppoUnitaOperativa): Observable<GruppoUnitaOperativa | undefined> {
    const req: CreaGruppoUnitaOperativaRequest = {
      guo: values
    };
    return this.service.creaGruppoUnitaOperativeUsingPOST(req).pipe(
      map(res => res.guo)
    );
  }

  callDelete(values: GruppoUnitaOperativa): Observable<any> {
    const req: EliminaGruppoUnitaOperativaRequest = {
      guo: values
    };
    return this.service.eliminaGruppoUnitaOperativaUsingPOST(req);
  }

  callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaGruppiUORequest = {};
    return this.service.getListaGruppiUnitaOperativeUsingPOST(req).pipe(
      map(res => res.lista?.length || 0)
    );
  }

  callUpdate(key: any, values: GruppoUnitaOperativa): Observable<GruppoUnitaOperativa | undefined> {
    // FIXME gestire update PK
    const req: ModificaGruppoUnitaOperativaRequest = {
      guo: values
    };
    return this.service.modificaGruppoUnitaOperativaUsingPOST(req).pipe(
      map(res => res.guo)
    );
  }

  getKeyField(): string {
    return 'idGruppoUnitaOp';
  }
}
