import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {concatMap, map, tap} from 'rxjs/operators';
import {QLikSenseJwtControllerService} from 'src/app/api';
import {fromPromise} from 'rxjs/internal-compatibility';


@Injectable()
export class QlikService {

  constructor(private qlikApi: QLikSenseJwtControllerService) {
  }

  public openQlikConsole(): Observable<any> {
    // 1. Richiesta token JWT
    // 2. Richiesta 'Cookie' a QLikSense
    // 3. Apertura pagina

    return this.qlikApi.getIframeUsingPOST1()
      .pipe(
        concatMap(response => {
          let qlikInfo = response?.jwtToken

          if (qlikInfo?.qlikCloudUrl != null &&
            qlikInfo?.loginPath != null &&
            qlikInfo.token != null &&
            qlikInfo.qlikIntegrationID != null) {

            const options = {
              method: 'POST',
              headers: {
                'qlik-web-integration-id': qlikInfo.qlikIntegrationID,
                'content-type': 'application/json',
                Authorization: 'Bearer ' + qlikInfo.token
              },

              body: '',
              credentials: "include",
              mode: "cors",
              rejectunAuthorized: false
            };

            return fromPromise(fetch("https://" + qlikInfo.qlikCloudUrl + "/" + qlikInfo.loginPath + "?qlik-web-integration-id=" + qlikInfo.qlikIntegrationID, options as any))
              .pipe(
                map(value => value.text()),
                tap(x => {
                  // Apri la pagina
                  window.open("https://" + qlikInfo?.qlikCloudUrl, '_blank');
                }),
              )
          } else {
            throw Error("Errore apertura Qlik - Parametri SSO mancanti");
          }
        })
      )
  }
}
