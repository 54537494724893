import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {GrantsService, UserSubFunctionGrants} from '../core/grants.service';
import {NgForm} from '@angular/forms';
import {CrudDatasource} from './crud-datasource';
import {Subject} from 'rxjs';

@Component({
  template: '',
  encapsulation: ViewEncapsulation.None
})
export abstract class CrudDetailComponent<T, L, S> implements OnInit, AfterViewInit {

  @ViewChild(NgForm, {static: false}) myform!: NgForm;

  @Input() itemKey!: number;
  @Input() readOnly = true;
  @Input() dataSource!: CrudDatasource<T, L, S>;
  @Input() initialData?: T;

  @Output() saved: EventEmitter<CrudRecordVersioning<T>> = new EventEmitter<CrudRecordVersioning<T>>();
  @Output() deleted: EventEmitter<T> = new EventEmitter<T>();
  @Output() closed: EventEmitter<number> = new EventEmitter<number>();
  @Output() loaded: EventEmitter<CrudRecordVersioning<T>> = new EventEmitter<CrudRecordVersioning<T>>();

  userGrants!: UserSubFunctionGrants;

  loadObservable: Subject<T> = new Subject<T>();
  isLoading!: boolean;
  deleting = false;

  insertedData!: boolean;

  data: T = {} as T;
  clonedData?: T;

  autonumericOptionsNumber: any = {
    digitGroupSeparator: '.',
    decimalCharacter: ',',
    decimalCharacterAlternative: '.',
    minimumValue: '0',
    emptyInputBehavior: 'null'
  };

  autonumericOptionsCurrency: any = {
    digitGroupSeparator: '.',
    decimalCharacter: ',',
    decimalCharacterAlternative: '.',
    currencySymbol: '\u00a0€',
    currencySymbolPlacement: 's',
    roundingMethod: 'U',
    minimumValue: '0',
    emptyInputBehavior: 'null'
  };

  constructor(public grantsService: GrantsService) {
  }

  ngOnInit(): void {
    this.loadUserGrants(this.getFunctionCode());
    if (this.itemKey && this.itemKey >= 0) {
      this.loadRecord(this.itemKey);
    } else {
      this.newRecord();
      this.editDetail();
    }
  }

  loadUserGrants(fnCode: string): void {
    this.grantsService.getFunctionGrants(fnCode).subscribe(value => {
      this.userGrants = value;
    }, err => {

    });
  }

  ngAfterViewInit(): void {
  }

  public editDetail(): void {
    this.readOnly = false;
    this.deleting = false;
  }

  public deleteDetail(): void {
    this.readOnly = false;
    this.deleting = true;
  }

  public deleteOk(): void {
    this.readOnly = true;
    this.deleteRecord();
  }

  public deleteCancel(): void {
    this.readOnly = true;
    this.deleting = false;
  }

  public closeTab(): void {
    this.closed.emit(this.dataSource.getRecordId(this.data));
  }

  public saveDetail(): void {
    if (this.isValid()) {
      this.readOnly = true;
      this.saveRecord();
    }
  }

  public isValid(): boolean {
    Object.keys(this.myform.controls).forEach(key => {
      this.myform.controls[key].markAsDirty();
    });
    if (this.myform) {
      return !this.myform.invalid;
    } else {
      return true;
    }
  }

  public revertDetail(): void {
    this.readOnly = true;

    if (this.insertedData) {
      this.insertedData = false;
      this.closeTab();
    } else {
      this.data = this.dataSource.cloneRecord(this.clonedData || {} as T);
      this.afterRecordLoaded(this.data);
    }
  }

  public abstract getFunctionCode(): string;

  public saveRecord(): void {
    this.isLoading = true;
    this.beforeSaveRecord();
    if (this.data) {
      if (this.insertedData) {
        this.dataSource.save(undefined, this.data, true).subscribe(value => {
          this.data = value as T;
          this.afterRecordLoaded(this.data);
          this.insertedData = false;
          const orig: any = {...value};
          orig[this.dataSource.getKeyField()] = this.itemKey;
          const toEmit: CrudRecordVersioning<T> = new CrudRecordVersioning(orig, value);
          this.clonedData = this.dataSource.cloneRecord(value as T);
          this.saved.emit(toEmit);
          this.readOnly = true;
          // this.closeTab();
        }, error => {
          this.readOnly = false;
        }, () => {
          this.isLoading = false;
          this.deleting = false;
        });
      } else {
        this.dataSource.save(this.itemKey, this.data, false).subscribe(value => {
          this.data = value as T;
          this.afterRecordLoaded(this.data);
          const toEmit: CrudRecordVersioning<T> = new CrudRecordVersioning<T>(this.clonedData as T, value);
          this.saved.emit(toEmit);
          delete this.clonedData;
          this.clonedData = this.dataSource.cloneRecord(value as T);
          this.readOnly = true;
        }, error => {
          this.readOnly = false;
        }, () => {
          this.isLoading = false;
          this.deleting = false;
        });
      }
    }
  }

  public deleteRecord(): void {
    if (this.data) {
      this.isLoading = true;
      this.dataSource.remove(this.data).subscribe(value => {
        this.deleted.emit(value);
        this.closeTab();
      }, error => {
      }, () => {
        this.isLoading = false;
        this.readOnly = true;
        this.deleting = false;
      });
    }
  }

  public newRecord(): void {
    this.insertedData = true;
    if (this.initialData) {
      this.data = this.initialData;
    } else {
      this.data = this.dataSource.newRecord();
    }
    this.afterRecordLoaded(this.data);
  }

  public loadRecord(key: any): void {
    this.isLoading = true;
    this.dataSource.getByKey(key).subscribe(value => {
      this.itemKey = key;
      this.data = value as T;
      this.clonedData = this.dataSource.cloneRecord(value as T);
      const toEmit: CrudRecordVersioning<T> = new CrudRecordVersioning<T>(value as T);
      this.loaded.emit(toEmit);
      this.afterRecordLoaded(value as T);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  private afterRecordLoaded(value: T): void {
    this.loadObservable.next(value);
  }

  protected beforeSaveRecord(): void {
  }
}

export class CrudRecordVersioning<T> {
  constructor(original: T, updated?: T) {
    this.original = original;
    this.updated = updated;
  }

  original: T;
  updated?: T;
}
