<tabset>
  <ng-template ngFor let-item [ngForOf]="tabs" let-i="index">
    <div *ngIf="item == tabs[0]; then search; else detail"></div>

    <!--TAB RICERCA-->
    <ng-template #search>
      <tab heading="Segnalazioni"
           [active]="item.active"
           (selectTab)="tabSelectionChanged(item)"
           class="search-tab"
           [disabled]="item.disabled">
        <div>
          <app-serclicon-search (search)="handleSearch($event)"></app-serclicon-search>
          <app-toolbar-grid
            [insertEnabled]="insertEnabled"
            [deleteEnabled]="eliminaEnabled"
            [deleteClickable]="eliminaClickable"
            [openClickable]="openClickable"
            [addClickable]="addClickable"
            [refreshClickable]="refreshClickable"
            [exportPdfClickable]="exportPdfClickable"
            [exportXlsClickable]="exportXlsClickable"
            [updateEnabled]="true"
            (addRow)="addNewRecord()"
            (openRow)="viewSelectedRecord()"
            (deleteRow)="deleteRecord()"
            (exportExcel)="exportExcel()"
            (exportPdf)="exportPdf()"
            (refreshData)="refresh()"
          ></app-toolbar-grid>
          <kendo-grid
            [data]="(view | async) || []"
            [pageSize]="gridState.take || 100"
            [skip]="gridState.skip || 0"
            [sort]="gridState.sort || []"
            [pageable]="pageable"
            [sortable]="true"
            [resizable]="true"
            (dataStateChange)="onStateChange($event)"
            [navigable]="true"
            [selectable]="{enabled: true}"
            [kendoGridSelectBy]="dataSource.getKeyField()"
            [selectedKeys]="selectedRows"
            (selectionChange)="onRowSelected(selectedRows)"
            (dblclick)="gridDbclick($event)"
            [columnMenu]="{ filter: true }"
          >
            <kendo-grid-column field="codiceSegnalazione" title="{{'SEGNALAZIONI.CODICE' | translate}}" filter="numeric" [width]="100"></kendo-grid-column>
            <kendo-grid-column field="id" title="{{'SEGNALAZIONI.ID' | translate}}" [hidden]="true" [width]="50"></kendo-grid-column>
            <kendo-grid-column field="centroCosto" title="{{'SEGNALAZIONI.CENTRO_DI_COSTO' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="descrizione_unitaOperativa" title="{{'SEGNALAZIONI.UNITA_OPERATIVA' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="oggettoSegnalazione" title="{{'SEGNALAZIONI.OGGETTO' | translate}}"></kendo-grid-column>
<!--            <kendo-grid-column field="articolo.descrizione" title="{{'SEGNALAZIONI.NOTE' | translate}}"></kendo-grid-column>-->
            <kendo-grid-column field="dataApertura" title="{{'SEGNALAZIONI.DATA_APERTURA' | translate}}" [width]="130" format="dd/MM/yyyy HH:mm" filter="date"></kendo-grid-column>
            <kendo-grid-column field="dataChiusura" title="{{'SEGNALAZIONI.DATA_CHIUSURA' | translate}}" [width]="130"
                               format="dd/MM/yyyy HH:mm" filter="date"></kendo-grid-column>
            <kendo-grid-column field="flagUrgenza" title="{{'SEGNALAZIONI.URGENTE' | translate}}" filter="boolean" [width]="100">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-block text-center">
                  <input type="checkbox" id="terms" [(ngModel)]="dataItem.flagUrgenza" [disabled]="true" kendoCheckBox/>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="descrizione_stato" [width]="130" title="{{'SEGNALAZIONI.STATO' | translate}}"></kendo-grid-column>
            <kendo-grid-column field="esito" title="{{'SEGNALAZIONI.ESITO' | translate}}" [filterable]="false" [width]="90">
              <ng-template kendoGridCellTemplate let-dataItem>
                <div class="d-block text-center">
                  <i class="fa fa-thumbs-down esito esito-error" *ngIf="dataItem.esito == 'KO'"></i>
                  <i class="fa fa-thumbs-up esito esito-success" *ngIf="dataItem.esito == 'OK'"></i>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-excel fileName="Segnalazioni.xlsx"></kendo-grid-excel>
            <kendo-grid-pdf fileName="Segnalazioni.pdf" [allPages]="true"></kendo-grid-pdf>
          </kendo-grid>
        </div>
      </tab>
    </ng-template>

    <!--TAB DETTAGLIO-->
    <ng-template #detail>
      <tab class="detail-tab h-100"
           [active]="item.active"
           (selectTab)="tabSelectionChanged(item)"
           [disabled]="item.disabled">

        <ng-template tabHeading>
          <span>{{item.title}}</span>
          <span class="close-tab-icon fa fa-times ml-3" (click)="removeTabHandler(item)"></span>
        </ng-template>

        <app-segnalazione-detail [itemKey]="item.key"
                                 style="height: 200px"
                                 [dataSource]="dataSource"
                                 [initialData]="item.record"
                                 (closed)="closeDetail($event)"
                                 (loaded)="detailLoaded($event)"
                                 (saved)="detailSaved($event)"
                                 (deleted)="detailDeleted($event)"
                                 [idSerCliCon]="idSerCliCon"
        ></app-segnalazione-detail>
      </tab>
    </ng-template>
  </ng-template>
</tabset>

