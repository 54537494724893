import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {SercliconSearchComponent} from '../../../shared/serclicon-search/serclicon-search.component';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ClientiContrattiControllerService,
  ContrattiControllerService,
  GetListaGruppiUORequest,
  GruppiUnitaOperativeControllerService,
  GruppoUnitaOperativa,
  ServiziClientiContrattiControllerService,
  StatisticheSegnalazioniControllerService
} from '../../../api';
import {StatoSgn} from '../segnalazione/stato-sgn';

@Component({
  selector: 'app-stat-segnalazioni-search',
  templateUrl: './stat-segnalazioni-search.component.html',
  styleUrls: ['./stat-segnalazioni-search.component.scss']
})
export class StatSegnalazioniSearchComponent extends SercliconSearchComponent implements OnInit {

  public buttonTypes = [
    StatoSgn.APER,
    StatoSgn.PCAR,
    StatoSgn.CFOR,
    StatoSgn.CCLI,
  ];

  public buttonsAndDescr: any = {};

  @Input() selectedStates: any[] = [];
  @Input() idGruppoUO?: number;
  @Input() fromDate!: Date;
  @Input() toDate!: Date;
  @Input() disabled = false;

  listaGruppiUO?: GruppoUnitaOperativa[] = [];
  listaGruppiUOFiltered?: GruppoUnitaOperativa[] = [];

  public format = 'dd/MM/yyyy';

  constructor(router: Router, activatedRoute: ActivatedRoute,
              contrattiService: ContrattiControllerService,
              clientiService: ClientiContrattiControllerService,
              serviziService: ServiziClientiContrattiControllerService,
              protected gruppiUoService: GruppiUnitaOperativeControllerService,
              protected statiSegnalazioniServices: StatisticheSegnalazioniControllerService) {
    super(router, activatedRoute, contrattiService, clientiService, serviziService);
  }

  ngOnInit(): void {
    this.getListaStatiSegnalazioni();
  }

  public statoChange(isTrue: any, state: any): void {
    if (isTrue) {
      this.selectedStates.push(state);
    } else {
      const index = this.selectedStates.indexOf(state);
      this.selectedStates.splice(index, 1);
    }
  }

  protected getSearchParams(): any {
    if (!this.disabled){
      return {
        idSerCliCon: this.idSerCliCon,
        selectedStates: this.selectedStates,
        idGruppoUO: this.idGruppoUO,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
    } else {
      return {
        idSerCliCon: this.idSerCliCon,
        selectedStates: null,
        idGruppoUO: this.idGruppoUO,
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
    }

  }

  public searchGruppoUO(idContratto?: number, idCliente?: number, idSerCliCon?: number, idGruppoUO?: number): void {
    if (this.idSerCliCon) {
      const req: GetListaGruppiUORequest = {
        parametriRicerca: {
          idSerCliCon
        }
      };
      this.gruppiUoService.getListaGruppiUnitaOperativeUsingPOST(req).subscribe((ls) => {
        this.listaGruppiUO = ls.lista;
        if (this.listaGruppiUO?.length === 1) {
          this.idGruppoUO = this.listaGruppiUO[0].idGruppoUnitaOp;
        } else {
          this.idGruppoUO = idGruppoUO;
        }
        this.handleSearch();
      });
    } else {
      this.listaGruppiUO = [];
      this.idGruppoUO = undefined;
    }
  }

  public getListaStatiSegnalazioni(): void {
    this.statiSegnalazioniServices.getHashStatiSegnalazioniUsingPOST({}).subscribe((ls) => {
      this.buttonsAndDescr = ls.hashStatiSegnalazioni;
    });
  }

  protected continueSearch(idContratto?: number, idCliente?: number, idSerCliCon?: number): void {
    this.searchGruppoUO(idContratto, idCliente, idSerCliCon);
  }

  public handleGruppoUoChange(e: any): void {
    this.updateQueryParams();
  }

  public handleGruppiUOFilter(value: any): void {
    this.listaGruppiUOFiltered = this.listaGruppiUO?.filter(
      (s) => s.codiceGruppo?.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
                                s.descrizioneGruppo?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
