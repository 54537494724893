import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContrattiControllerService, ContrattoLookUp} from '../../api';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-contratti-search',
  templateUrl: './contratti-search.component.html',
  styleUrls: ['./contratti-search.component.scss']
})
export class ContrattiSearchComponent implements OnInit {

  @Input() idContratto?: number;

  listaContratti?: ContrattoLookUp[] = [];
  listaContrattiFiltered?: ContrattoLookUp[] = [];

  @Output() search = new EventEmitter<any>();

  constructor(
    public router: Router, public activatedRoute: ActivatedRoute,
    private contrattiService: ContrattiControllerService) {
    this.activatedRoute.queryParams.subscribe(params => {
      const idContratto = params?.ic && +params?.ic;
      this.searchContratto(idContratto);
    });
  }

  ngOnInit(): void {
  }

  public searchContratto(idContratto?: number): void {
    this.contrattiService.getListaLookUpContrattiUsingPOST().subscribe((lc) => {
      this.listaContratti = lc.contrattiLookUp;
      if (this.listaContratti?.length === 1) {
        this.idContratto = this.listaContratti[0].id;
      } else {
        this.idContratto = idContratto;
      }
      this.listaContrattiFiltered = this.listaContratti;
      this.search.emit(this.getSearchParams());
    });
  }

  protected getSearchParams(): any {
    return this.idContratto;
  }

  protected updateQueryParams(): void {
    const queryParams: Params = {
      ic: this.idContratto || ''
    };
    this.router.navigate(['./'], {relativeTo: this.activatedRoute, queryParams, queryParamsHandling: 'merge'});
  }

  public handleContrattoChange(e: any): void {
    // this.search.emit(this.getSearchParams());
    this.updateQueryParams();
  }

  public handleContrattiFilter(value: any): void {
    this.listaContrattiFiltered = this.listaContratti?.filter(
      (s) => s.numero?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
