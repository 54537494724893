<div class="nav-right-itmes-container">

  <!-- HELP -->
  <button kendoButton look="flat" class="help-button">
    <i class="fas fa-question-circle fa-2x" (click)="showHelp()"></i> Help
  </button>

  <div class="btn-group" dropdown>
    <button id="button-animated" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-animated">
      <span class="fa fa-user"></span>
      <span class="d-none d-md-inline-block pl-3">{{username}}</span>
      <span class="caret"></span>
    </button>
    <ul id="dropdown-animated" *dropdownMenu class="dropdown-menu dropdown-menu-right"
        role="menu" aria-labelledby="button-animated">
      <li role="menuitem"><a class="dropdown-item" (click)="logout()">
        <i class="fa fa-sign-out-alt"></i>
        Logout</a>
      </li>
      <li role="menuitem">
        <a class="dropdown-item" href="mailto:escape@emservices.it">
          <i class="fa fa-envelope"></i>
          Contattaci
        </a>
      </li>
      <li role="menuitem">
        <span class="dropdown-item">Version: {{version}}</span>
      </li>
    </ul>
  </div>
</div>
