<!-- Toolbar Grid START -->
<div class="toolbar-grid">

  <!-- Reading mode -->
  <div [hidden]="editing">

    <!-- Refresh -->
    <div class="toolbar-btn-container" *ngIf="showRefreshData" [ngStyle]="{'pointer-events': !refreshClickable?'none':'', 'opacity':!refreshClickable?'0.33':'1'}">
      <a class="k-button" (click)="refreshData.emit()">
        <span class="fa fa-sync"></span>
      </a>
    </div>
    <!-- Export Pdf -->
    <div class="toolbar-btn-container">
      <a class="k-button" (click)="exportPdf.emit()" *ngIf="showExportPdf" [ngStyle]="{'pointer-events': !exportPdfClickable?'none':'', 'opacity':!exportPdfClickable?'0.33':'1'}">
        <i class="fa fa-file-pdf" aria-hidden="true"></i>
        <span>{{'TOOLBAR_GRID.EXPORT_PDF' | translate}}</span>
      </a>
    </div>
    <!-- Export Excel -->
    <div class="toolbar-btn-container">
      <a class="k-button" (click)="exportExcel.emit()" *ngIf="showExportExcel" [ngStyle]="{'pointer-events': !exportXlsClickable?'none':'', 'opacity':!exportXlsClickable?'0.33':'1'}">
        <i class="fa fa-file-excel" aria-hidden="true"></i>
        <span>{{'TOOLBAR_GRID.EXPORT_EXCEL' | translate}}</span>
      </a>
    </div>

    <!-- Add Record -->
    <div class="toolbar-btn-container float-left" *ngIf="insertEnabled && showAddRow" [ngStyle]="{'pointer-events': !addClickable?'none':'', 'opacity':!addClickable?'0.33':'1'}">
      <a class="k-button" (click)="addRow.emit()">
        <span class="fa fa-plus"></span> <span>{{'TOOLBAR_GRID.ADD_RECORD' | translate}}</span>
      </a>
    </div>
    <!-- Open Record -->
    <div class="toolbar-btn-container float-left" *ngIf="showOpenRow" [ngStyle]="{'pointer-events': !openClickable?'none':'', 'opacity':!openClickable?'0.33':'1' }">
      <a class="k-button" (click)="openRow.emit()">
        <span class="fa fa-folder-open"></span> <span>{{'TOOLBAR_GRID.OPEN_RECORD' | translate}}</span>
      </a>
    </div>
    <!-- Edit Record -->
    <div class="toolbar-btn-container float-left" *ngIf="updateEnabled && showEditRow" [ngStyle]="{'pointer-events': !updateClickable?'none':'', 'opacity':!updateClickable?'0.33':'1'}">
      <a class="k-button" (click)="editRow.emit()">
        <span class="fa fa-edit"></span> <span>{{'TOOLBAR_GRID.EDIT_RECORD' | translate}}</span>
      </a>
    </div>
    <!-- Custom Button 1 -->
    <div class="toolbar-btn-container float-left" *ngIf="function1Enabled && showBtn1">
      <a class="k-button" (click)="function1.emit()">
        <span class="fa" ng-class="iconBtn1"></span> <span>{{ textBtn1 | translate}}</span>
      </a>
    </div>
    <!-- Custom Button 2 -->
    <div class="toolbar-btn-container float-left" *ngIf="function2Enabled && showBtn2">
      <a class="k-button" (click)="function2.emit()">
        <span class="fa" ng-class="iconBtn2"></span> <span>{{ textBtn2 | translate}}</span>
      </a>
    </div>
    <!-- Custom Button 3 -->
    <div class="toolbar-btn-container float-left" *ngIf="function3Enabled && showBtn3">
      <a class="k-button" (click)="function3.emit()">
        <span class="fa" ng-class="iconBtn3"></span> <span>{{ textBtn3 | translate}}</span>
      </a>
    </div>
    <!-- Clone Record -->
    <div class="toolbar-btn-container float-left" *ngIf="insertEnabled && showCloneRow">
      <a class="k-button" (click)="cloneRow.emit()">
        <span class="fa fa-clone" aria-hidden="true"></span> <span>{{'TOOLBAR_GRID.CLONE_RECORD' | translate}}</span>
      </a>
    </div>
    <!-- Delete Record -->
    <div class="toolbar-btn-container float-left" *ngIf="deleteEnabled && showDeleteRow" [ngStyle]="{'pointer-events': !deleteClickable?'none':'', 'opacity':!deleteClickable?'0.33':'1'}">
      <a class="k-button" (click)="deleteRow.emit()">
        <span class="fa fa-trash"></span> <span>{{'TOOLBAR_GRID.DELETE_RECORD' | translate}}</span>
      </a>
    </div>
  </div>

  <!-- Editing mode -->

  <div [hidden]="!editing">
    <!-- Update -->
    <div class="toolbar-btn-container float-left" *ngIf="updateEnabled">
      <a class="k-button save" (click)="save.emit()">
        <span class="fa fa-check"></span> <span>{{'TOOLBAR_GRID.UPDATE' | translate}}</span>
      </a>
    </div>
    <!-- Undo -->
    <div class="toolbar-btn-container float-left" *ngIf="updateEnabled">
      <a class="k-button undo" (click)="cancel.emit()">
        <span class="fa fa-undo"></span> <span>{{'TOOLBAR_GRID.UNDO' | translate}}</span>
      </a>
    </div>
  </div>
</div>
<!-- Toolbar Grid END -->
