import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndicatoriContrattiComponent} from './indicatori-contratti/indicatori-contratti.component';
import {ElemcontrolloContrattiComponent} from './elemcontrollo-contratti/elemcontrollo-contratti.component';
import {ArticoliContrattiComponent} from './articoli-contratti/articoli-contratti.component';
import {CriticitaContrattiComponent} from './criticita-contratti/criticita-contratti.component';

const routes: Routes = [
  {
    path: 'main.indicatori-contratti',
    component: IndicatoriContrattiComponent
  }, {
    path: 'main.elecontr-contratti',
    component: ElemcontrolloContrattiComponent
  }, {
    path: 'main.articoli-contratti',
    component: ArticoliContrattiComponent
  }, {
    path: 'main.criticita-contratti',
    component: CriticitaContrattiComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TabelleRoutingModule {
}
