import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {MenuItem} from '../api';
import {AuthService} from './core/auth.service';
import {MenuService} from './core/menu.service';
import {combineLatest, forkJoin} from 'rxjs';


@Component({
  selector: 'app-breadcrumbs',
  template: `
    <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last=last>
      <li class="breadcrumb-item">
        <span>{{breadcrumb.label}}</span>
      </li>
    </ng-template>`
})
export class BreadcrumbsComponent {
  breadcrumbs!: Array<any>;

  menuItems!: MenuItem[];

  constructor(public router: Router,
              public route: ActivatedRoute,
              public auth: AuthService,
              public menuService: MenuService) {

    this.menuService.getMenuItems().subscribe((menu: MenuItem[]) => {
      this.menuItems = menu;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      if (this.menuItems) {
        this.breadcrumbs = [];
        this.createBreadcrumbs(event.url.replace('/main/', ''), this.menuItems, this.breadcrumbs);
      }
    });
  }

  createBreadcrumbs(url: string, items: MenuItem[], breadcrumbs: any[]): boolean {
    for (const item of items) {
      if (item.url != null && url.startsWith(item.url)) {
        breadcrumbs.push({
          label: item.descrizione,
          url: item.url
        });
        return true;
      } else if (item.sottovoci && item.sottovoci.length > 0) {
        const found = this.createBreadcrumbs(url, item.sottovoci, breadcrumbs);
        if (found) {
          breadcrumbs.unshift({
            label: item.descrizione,
            url: item.url
          });
          return true;
        }
      }
    }
    return false;
  }
}
