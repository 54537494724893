import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from './auth.service';
import {NavStateService} from './nav-state.service';
import {MessageBusService} from './message-bus.service';

@Injectable({
  providedIn: 'root'
})
export class IframeGuard implements CanActivate {

  constructor(private router: Router, private msgBus: MessageBusService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // TODO aggiungere controllo che URL sia una delle voci previste nel menu
    const pathSplit = state.url.split('?')[0].split('/');
    const path = pathSplit[pathSplit.length - 1];
    this.msgBus.navigateIframeTo({
      path,
      params: {}
    });
    return true;
  }

}
