import {Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss']
})
export class ImageSelectorComponent implements OnInit{
  @Input() imageUrl?: string = undefined;
  @Input() selectFileLabel: string = "Scegli immagine";
  @Input() removeFileLabel: string = "Rimuovi immagine";
  @Input() readOnly: boolean = false;
  @Input() isLoading: boolean = false;

  @Output() fileChanged: EventEmitter<Blob> = new EventEmitter<Blob>();
  @Output() fileRemoved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('target')
  imageRef?: ElementRef;

  imageData?: string | ArrayBuffer | null;
  modalRef?: BsModalRef;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit(): void {
  }

  handleFileInput($event: any) {
    if ($event.target.files && $event.target.files[0]) {

      const imageFile = $event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        this.imageData = reader.result;
      };

      this.fileChanged.emit(imageFile);
    }
  }

  showPreviewInDialog(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-lg', backdrop: false});
  }

  scrollToImage() {
    setTimeout(() => {
      this.imageRef?.nativeElement.scrollIntoView({behavior: 'smooth'});
    }, 100);
  }

  removeFile() {
    this.imageData = null;
    this.imageUrl = undefined;
    this.fileRemoved.emit(null);
  }

  public setImage(fileName: string | undefined) {
    this.imageUrl = fileName;
    this.imageData = null;
  }
}
