import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {TabsModule} from 'ngx-bootstrap/tabs';

// Routing Module
import {AppRoutingModule} from './app-routing.module';

import {PublicModule} from './public/public.module';
import {MainModule} from './main/main.module';
import {BaseComponent} from './shared/core/base-component';
import {SharedModule} from './shared/shared.module';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {InputsModule} from '@progress/kendo-angular-inputs';
import {ApiModule, BASE_PATH} from './api';
import {AuthService} from './shared/core/auth.service';
import {environment} from '../environments/environment';
import {ModalModule} from 'ngx-bootstrap/modal';
import {MenuService} from './shared/core/menu.service';
import {GridModule} from '@progress/kendo-angular-grid';
import {HttpResponseInterceptor} from './shared/core/http-response-interceptor';
import {GrantsService} from './shared/core/grants.service';
import {DialogModule} from '@progress/kendo-angular-dialog';
import {TranslateLoader, TranslateModule, TranslatePipe} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MenuModule} from '@progress/kendo-angular-menu';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import '@angular/common/locales/global/it';
// Load all required data for the IT locale
import '@progress/kendo-angular-intl/locales/it/all';

// Load the required calendar data for the IT locale
import '@progress/kendo-angular-intl/locales/it/calendar';
import {IntlModule} from '@progress/kendo-angular-intl';
import {ErrorService} from './shared/core/error.service';
import {PrintService} from './shared/core/print.service';
import {QlikService} from './shared/core/qlik.service';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    ApiModule,
    AppRoutingModule,
    NgxJsonViewerModule,
    SharedModule,
    MainModule,
    PublicModule,
    InputsModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    ButtonsModule.forRoot(),
    TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient]
        }
      }
    ),
    GridModule,
    DialogModule,
    MenuModule,
    // Import the internationalization module
    IntlModule,
  ],
  declarations: [
    AppComponent,
    BaseComponent,
  ],
  providers: [
    AuthService,
    GrantsService,
    MenuService,
    ErrorService,
    TranslatePipe,
    PrintService,
    QlikService,
    {provide: BASE_PATH, useValue: environment.API_BASE_PATH},
    {provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true},
    {
      // Set default locale to bg-BG
      provide: LOCALE_ID,
      useValue: 'it-IT',
    },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
