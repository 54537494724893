import {Component, OnInit} from '@angular/core';
import {GruppoUnitaOperativa, GruppoUnitaOperativaSearch} from '../../../api';
import {GruppoUnitaOperativaService} from './gruppo-unita-operativa.service';
import {CrudInlineComponent} from '../../../shared/crud/crud-inline.component';
import {GrantsService} from '../../../shared/core/grants.service';
import {DialogService} from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-gruppo-unita-operativa',
  templateUrl: './gruppo-unita-operativa.component.html',
  styleUrls: ['./gruppo-unita-operativa.component.scss']
})
export class GruppoUnitaOperativaComponent extends CrudInlineComponent<GruppoUnitaOperativa, GruppoUnitaOperativa, GruppoUnitaOperativaSearch> implements OnInit {

  idSerCliCon?: number;
  insertEnabled = false;
  eliminaEnabled = false;
  eliminaClickable = false;
  openClickable = false;
  addClickable = false;
  exportPdfClickable = false;
  exportXlsClickable = false;
  refreshClickable = false;
  updateEnabled = false;
  updateClickable = false;
  private newRow: any;
  constructor(grantsService: GrantsService,
              dialogService: DialogService,
              editService: GruppoUnitaOperativaService) {
    super(grantsService, dialogService, editService);
  }

  public getFunctionCode(): string {
    return 'GUO';
  }

  public ngOnInit(): void {
    if (!this.idSerCliCon){
      this.dataSource.clear();
      this.dataSource.searchParams = undefined;
    }
    super.ngOnInit();
  }

  protected postInit(): void {
    this.insertEnabled = this.userGrants.insert;
    this.eliminaEnabled = this.userGrants.delete;
    this.updateEnabled = this.userGrants.update;
    this.exportPdfClickable = true;
    this.exportXlsClickable = true;
    this.refreshClickable = true;
    super.postInit();
  }

  fillNewRecord(newRecord: GruppoUnitaOperativa): void {
    newRecord.idSerCliCon = this.idSerCliCon;
  }

  onRowSelected(event: number[]): void{
    if (event && event.length !== 0){
      this.eliminaClickable = true;
      this.openClickable = true;
      this.updateClickable = true;
    } else {
      this.eliminaClickable = false;
      this.openClickable = false;
      this.updateClickable = false;
    }
  }

  deleteRecord(): void{
    super.deleteRecord();
    if (!this.selectedRows || this.selectedRows.length === 0){
      this.eliminaClickable = false;
      this.openClickable = false;
    }
  }

  handleSearch(idSerCliCon: number): void{
    this.idSerCliCon = idSerCliCon;
    if (idSerCliCon > 0){
      this.addClickable = true;
      this.exportPdfClickable = true;
      this.exportXlsClickable = true;
      this.refreshClickable = true;
      this.dataSource.read({
        idSerCliCon: this.idSerCliCon
      });
    } else {
      this.addClickable = false;
      this.exportPdfClickable = false;
      this.exportXlsClickable = false;
      this.refreshClickable = false;
      this.dataSource.clear();
    }
  }

  exportExcel(): void {
    this.pageable = false;
    super.exportExcel();
    this.pageable = {};
  }
}
