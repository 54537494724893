import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from './auth.service';
import {ErrorCode, Result, StatusCode} from 'src/app/api';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {

  // private regexIso8601 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})Z$/;
  private regexIso8601 = /^\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?(\.\d+)?(([+-]\d\d:\d\d)|Z)?)?$/;
  private requests: HttpRequest<any>[] = [];

  constructor(public auth: AuthService,
              public toastrService: ToastrService) {
  }

  // tslint:disable-next-line:typedef
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    if (this.requests.length === 0) {
      if (document && document.getElementById('mainDivLoader')) {
        // @ts-ignore
        document.getElementById('mainDivLoader').style.display = 'none';
      }
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    if (document && document.getElementById('mainDivLoader')) {
      // @ts-ignore
      document.getElementById('mainDivLoader').style.display = 'block';
    }
    return next.handle(req).pipe(
      map((value: any) => {
        if (value instanceof HttpResponse) {
          this.removeRequest(req);
        }
        if (value && value.body && value.body.result) {
          if (value.body.result.status !== StatusCode.Ok) {
            throw value.body;
          } else {
            this.convertDateStringsToDates(value.body);
          }
        }
        return value;
      }),
      catchError((err, caught) => {
        if (err && err.result) {
          const msg = this.handleApplicationError(err.result);
          return throwError(msg);
        } else {
          const msg = this.handleError(err.message);
          return throwError(msg);
          // return of(this.handleError(err));
        }
      })
    );
  }

  private isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  private isString(value: any): boolean {
    return typeof value === 'string';
  }

  private convertDateStringsToDates(input: any): void {
    // Ignore things that aren't objects.
    if (!this.isObject(input)) {
      return;
    }

    for (const key in input) {
      if (!input.hasOwnProperty(key)) {
        continue;
      }

      const value = input[key];
      let match;
      // Check for string properties which look like dates.
      // tslint:disable-next-line:no-conditional-assignment
      if (this.isString(value) && (match = value.match(this.regexIso8601))) {
        const milliseconds = Date.parse(match[0]);
        if (!isNaN(milliseconds)) {
          input[key] = new Date(milliseconds);
        }
      } else if (this.isObject(value)) {
        // Recurse into object
        this.convertDateStringsToDates(value);
      }
    }
  }

  private handleApplicationError(result: Result): string {

    switch (result.code) {
      case ErrorCode.ForeignKey:
      case ErrorCode.UniqueKey:
      case ErrorCode.Concurrency:
        /***** Handled by crud-datasource *****/
        return JSON.stringify(result);

      default:
        return result.code + ' - ' + result.message;
        this.toastrService.error(result.message, 'APPLICATION ERROR: ' + result.code);
        break;
    }
  }

  private anauthorized(): void {
    this.auth.setUser(null);
    this.auth.goToLogin();
  }

  private handleError(error: Response | any): string | undefined {
    let errMsg: string;
    if (error instanceof Response) {
      let body: any = null;
      try {
        body = error.json() || '';
      } catch (e) {
      }

      if (body && body.result) {
        errMsg = this.handleApplicationError(body.result);
      } else if (body) {
        const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        this.toastrService.error(errMsg, 'GENERIC ERROR');
        console.error(errMsg);
      } else {
        errMsg = `${error.status} - ${error.statusText || ''}`;
        this.toastrService.error(errMsg, 'GENERIC ERROR');
        console.error(errMsg);
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
      if (error.status === 401) {
        this.anauthorized();
      }
    }

    return errMsg;
  }
}
