import {Component, OnInit, ViewChild} from '@angular/core';
import {GrantsService, UserSubFunctionGrants} from '../core/grants.service';
import {CrudDatasource} from './crud-datasource';
import {GridComponent, GridDataResult, PagerSettings} from '@progress/kendo-angular-grid';
import {Observable} from 'rxjs';
import {process, State} from '@progress/kendo-data-query';
import {map} from 'rxjs/operators';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {NgForm} from '@angular/forms';

@Component({
  template: ''
})
export abstract class CrudInlineComponent<T, L, S> implements OnInit {
  @ViewChild(GridComponent, {static: false}) dataGrid!: GridComponent;
  @ViewChild(NgForm, {static: false}) formInstance!: NgForm;

  selectedRows: number[] = [];
  editingEnabled = false;
  userGrants!: UserSubFunctionGrants;

  public view!: Observable<GridDataResult>;

  public pageable: PagerSettings | boolean = {};
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 100,
  };

  private editedRowIndex?: number;
  private editedRecord?: T;
  private isNew = false;

  constructor(public grantsService: GrantsService, private dialogService: DialogService, public dataSource: CrudDatasource<T, L, S>) {
  }

  ngOnInit(): void {
    this.loadUserGrants(this.getFunctionCode());
    this.view = this.dataSource.pipe(
      map((data) => process(data || [], this.gridState))
    );
  }

  loadUserGrants(fnCode: string): void {
    this.grantsService.getFunctionGrants(fnCode).subscribe(value => {
      this.userGrants = value;
      this.postInit();
    }, err => {

    });
  }

  protected postInit(): void {
    this.dataSource.read();
  }

  public onStateChange(state: State): void {
    this.gridState = state;

    this.dataSource.read();
  }

  // public addHandler({ sender }: { sender: GridComponent }, formInstance: any): void {
  //   formInstance.reset();
  //   this.closeEditor();
  //
  //   sender.addRow({} as GruppoUnitaOperativa);
  // }

  // public editHandler({ sender, rowIndex, dataItem }: { sender: GridComponent, rowIndex: number, dataItem: T }): void {
  //   this.closeEditor();
  //
  //   this.editedRowIndex = rowIndex;
  //   this.editedRecord = Object.assign({}, dataItem);
  //
  //   sender.editRow(rowIndex);
  // }

  // public cancelHandler({ sender, rowIndex }: { sender: GridComponent, rowIndex: number }): void {
  //   this.closeEditor(rowIndex);
  // }

  // public saveHandler({ sender, rowIndex, dataItem, isNew }: { sender: GridComponent, rowIndex: number, dataItem: T, isNew: boolean }): void {
  //   this.dataSource.save(this.dataSource.getRecordId(this.editedRecord), dataItem, isNew).subscribe();
  //
  //   sender.closeRow(rowIndex);
  //
  //   this.editedRowIndex = undefined;
  //   this.editedRecord = undefined;
  // }

  // public removeHandler({ dataItem }: any): void {
  //   this.dataSource.remove(dataItem).subscribe();
  // }

  private closeEditor(rowIndex = this.editedRowIndex): void {
    this.dataGrid.closeRow(rowIndex);
    this.dataSource.resetItem(this.editedRecord);
    this.editedRowIndex = undefined;
    this.editedRecord = undefined;
    this.editingEnabled = false;
  }


  addNewRecord(): void {
    if (this.dataSource) {
      this.formInstance.reset();
      this.closeEditor();
      this.editedRecord = this.dataSource.newRecord();
      this.fillNewRecord(this.editedRecord);
      this.dataGrid.addRow(this.editedRecord);
      this.editingEnabled = true;
      this.isNew = true;
    }
  }

  fillNewRecord(newRecord: T): void {
  }

  editRecord(): void {
    if (this.dataSource) {
      this.closeEditor();

      this.editingEnabled = true;
      this.isNew = false;
      const editedRowKey = this.selectedRows[this.selectedRows.length - 1];

      for (let i = 0; i < this.dataGrid.view.length; i++) {
        if (this.dataGrid.view.at(i)[this.dataSource.getKeyField()] === editedRowKey) {
          this.editedRowIndex = i;
          this.editedRecord = Object.assign({}, this.dataGrid.view.at(this.editedRowIndex));
          this.dataGrid.editRow(this.editedRowIndex);
          break;
        }
      }
    }
  }

  deleteRecord(): void {
    const dialog: DialogRef = this.dialogService.open({
      title: 'Per favore conferma',
      content: 'Sei sicuro?',
      actions: [{text: 'No'}, {text: 'Si', primary: true}],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult || result.text === 'No') {
        console.log('close');
      } else {
        console.log('action', result);
        const selectedRow = this.selectedRows[this.selectedRows.length - 1];
        this.dataSource.deleteByKey(selectedRow).subscribe(
          () => {
            this.selectedRows = this.selectedRows.filter(s => s !== selectedRow);
          }
        );
      }
    });
  }

  copyRecord(): void {
    if (this.dataSource) {
      const newRecord = Object.assign({}, this.dataGrid.view.at(this.selectedRows[this.selectedRows.length - 1]));
      this.formInstance.reset();
      this.closeEditor();
      this.dataGrid.addRow(newRecord);
      this.editingEnabled = true;
      this.isNew = true;
    }
  }

  saveRecord(): void {
    this.formInstance.form.updateValueAndValidity();
    // tslint:disable-next-line:forin
    for (const controlsKey in this.formInstance.form.controls) {
      const control = this.formInstance.form.controls[controlsKey];
      control.markAsTouched();
    }
    if (this.formInstance.form.valid) {
      if (this.isNew) {
        const newRecord = Object.assign(this.editedRecord, this.formInstance.form.value);
        this.dataSource.save(undefined, newRecord, true).subscribe();
      } else {
        const updatedRecord = Object.assign(this.editedRecord, this.formInstance.form.value);
        this.dataSource.save(this.dataSource.getRecordId(this.editedRecord), updatedRecord, false).subscribe();
      }
      this.dataGrid.closeRow(this.editedRowIndex);
      this.editedRowIndex = undefined;
      this.editedRecord = undefined;
      this.editingEnabled = false;
    }
  }

  cancelEditing(): void {
    this.closeEditor();
  }

  exportExcel(): void {
    // this.pageable = false;
    const gridStateTemp = {...this.gridState};
    this.gridState.take = this.dataSource.data?.length;
    this.gridState.skip = 0;
    this.dataSource.read();
    setTimeout(() => {
      this.dataGrid.saveAsExcel();
      this.gridState = gridStateTemp;
      this.dataSource.read();
      // this.pageable = {};
    }, 0);
  }

  exportPdf(): void {
    this.dataGrid.saveAsPDF();
  }

  refresh(): void {
    this.dataSource.getList().subscribe();
  }

  public abstract getFunctionCode(): string;
}
