<div class="inline-crud-container">
  <app-serclicon-search (search)="handleSearch($event)" ></app-serclicon-search>
  <form novalidate #myForm="ngForm">
    <app-toolbar-grid
      [editing]="editingEnabled"
      [insertEnabled]="insertEnabled"
      [deleteEnabled]="eliminaEnabled"
      [deleteClickable]="eliminaClickable"
      [openClickable]="openClickable"
      [addClickable]="addClickable"
      [updateClickable]="updateClickable"
      [refreshClickable]="refreshClickable"
      [exportPdfClickable]="exportPdfClickable"
      [exportXlsClickable]="exportXlsClickable"
      [updateEnabled]="updateEnabled"
      (addRow)="addNewRecord()"
      (editRow)="editRecord()"
      (deleteRow)="deleteRecord()"
      (exportExcel)="exportExcel()"
      (exportPdf)="exportPdf()"
      (refreshData)="refresh()"
      (save)="saveRecord()"
      (cancel)="cancelEditing()"
    ></app-toolbar-grid>
    <kendo-grid
      [data]="(view | async) || []"
      [pageSize]="gridState.take || 100"
      [skip]="gridState.skip || 0"
      [sort]="gridState.sort || []"
      [pageable]="pageable"
      [sortable]="true"
      [resizable]="true"
      (dataStateChange)="onStateChange($event)"
      [selectedKeys]="selectedRows"
      [kendoGridSelectBy]="dataSource.getKeyField()"
      [navigable]="true"
      (selectionChange)="onRowSelected(selectedRows)"
    >



      <kendo-grid-excel fileName="gruppo_unita_operative.xlsx"></kendo-grid-excel>
      <kendo-grid-pdf fileName="gruppo_unita_operative.pdf" [allPages]="true"></kendo-grid-pdf>
      <kendo-grid-column field="codiceGruppo" title="Codice">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.codiceGruppo"
            kendoGridFocusable
            name="codiceGruppo"
            class="k-textbox"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="descrizioneGruppo" title="Descrizione">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.descrizioneGruppo"
            kendoGridFocusable
            name="descrizioneGruppo"
            class="k-textbox"
            required
          />
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
