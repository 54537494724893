import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-grid',
  templateUrl: './toolbar-grid.component.html',
  styleUrls: ['./toolbar-grid.component.scss']
})
export class ToolbarGridComponent implements OnInit {

  @Input() editing = false;
  @Input() insertEnabled = false;
  @Input() deleteEnabled = false;
  @Input() updateEnabled = false;
  @Input() function1Enabled = false;
  @Input() function2Enabled = false;
  @Input() function3Enabled = false;

  @Input() deleteClickable = false;
  @Input() openClickable = false;
  @Input() addClickable = false;
  @Input() updateClickable = false;
  @Input() exportPdfClickable = false;
  @Input() exportXlsClickable = false;
  @Input() refreshClickable = false;
  @Input() textBtn1 = '';
  @Input() textBtn2 = '';
  @Input() textBtn3 = '';
  @Input() iconBtn1 = '';
  @Input() iconBtn2 = '';
  @Input() iconBtn3 = '';

  @Output() exportPdf: EventEmitter<any> = new EventEmitter<any>();
  @Output() exportExcel: EventEmitter<any> = new EventEmitter<any>();
  @Output() addRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() editRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() openRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  @Output() function1: EventEmitter<any> = new EventEmitter<any>();
  @Output() function2: EventEmitter<any> = new EventEmitter<any>();
  @Output() function3: EventEmitter<any> = new EventEmitter<any>();

  showExportPdf = false;
  showExportExcel = false;
  showAddRow = false;
  showEditRow = false;
  showCloneRow = false;
  showOpenRow = false;
  showDeleteRow = false;
  showSave = false;
  showCancel = false;
  showRefreshData = false;
  showBtn1 = false;
  showBtn2 = false;
  showBtn3 = false;

  constructor() { }

  ngOnInit(): void {
    this.showExportPdf = this.exportPdf.observers.length > 0;
    this.showExportExcel = this.exportExcel.observers.length > 0;
    this.showAddRow = this.addRow.observers.length > 0;
    this.showEditRow = this.editRow.observers.length > 0;
    this.showCloneRow = this.cloneRow.observers.length > 0;
    this.showOpenRow = this.openRow.observers.length > 0;
    this.showDeleteRow = this.deleteRow.observers.length > 0;
    this.showSave = this.save.observers.length > 0;
    this.showCancel = this.cancel.observers.length > 0;
    this.showRefreshData = this.refreshData.observers.length > 0;
    this.showBtn1 = this.function1.observers.length > 0;
    this.showBtn2 = this.function2.observers.length > 0;
    this.showBtn3 = this.function3.observers.length > 0;
  }

}
