import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PublicContainerComponent} from './public/public-container.component';
import {LoggedInGuard} from './shared/core/logged-in.guard';
import {MainContainerComponent} from './main/main-container.component';

/*
 *  App routes
 *
 *  - / -> /public
 *
 *  - /public
 *    (managed by public-routing.module.ts)
 *
 *  - /main
 *    (managed by main-routing.module.ts)
 *
 *  - * -> /main
 *
 */
const routes: Routes = [
  {
    path: '',
    redirectTo: 'public',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'public/login',
    pathMatch: 'full',
  },
  {
    path: 'public',
    component: PublicContainerComponent,
    data: {
      title: 'Pages'
    },
    children: [
      {
        path: '',
        loadChildren: './public/public.module#PublicModule',
      }
    ]
  },
  {
    path: 'main',
    data: {
      title: 'Home'
    },
    canActivate: [
      LoggedInGuard
    ],
    component: MainContainerComponent,
    children: [
      {
        path: '',
        loadChildren: './main/main.module#MainModule'
      },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
