<app-stat-segnalazioni-search (search)="handleSearch($event)" [disabled]="disableStatusFilter"></app-stat-segnalazioni-search>
<tabset>
  <tab heading="Segnalazioni"
       (selectTab)="tabSelectionChanged($event)"
       class="search-tab">
    <div>
      <app-toolbar-grid
        [insertEnabled]="false"
        [deleteEnabled]="false"
        [deleteClickable]="false"
        [openClickable]="false"
        [addClickable]="false"
        [refreshClickable]="true"
        [exportPdfClickable]="exportPdfClickable"
        [exportXlsClickable]="exportXlsClickable"
        [updateEnabled]="true"
        (exportExcel)="exportExcel()"
        (exportPdf)="exportPdf()"
        (refreshData)="refresh()"
      ></app-toolbar-grid>
      <kendo-grid
        [data]="(view | async) || []"
        [pageSize]="gridState.take || 100"
        [skip]="gridState.skip || 0"
        [sort]="gridState.sort || []"
        [pageable]="true"
        [sortable]="true"
        [resizable]="true"
        (dataStateChange)="onStateChange($event)"
        [navigable]="true"
        [columnMenu]="{ filter: true }"
      >
        <kendo-grid-column field="codiceSegnalazione" title="Codice"></kendo-grid-column>
        <kendo-grid-column field="contratto" title="Contratto"></kendo-grid-column>
        <kendo-grid-column field="servizio" title="Servizio"></kendo-grid-column>
        <kendo-grid-column field="dataApertura" title="Apertura" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <kendo-grid-column field="dataChiusura" title="Chiusura" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <kendo-grid-column field="oggettoSegnalazione" title="Oggetto"></kendo-grid-column>
        <kendo-grid-column field="descGruppoUO" title="Gruppo"></kendo-grid-column>
        <kendo-grid-column field="flagUrgenza" title="Urgente"></kendo-grid-column>
        <kendo-grid-column field="aperUtente" title="Utente Apertura"></kendo-grid-column>
        <kendo-grid-column field="aperData" title="Aperta il" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <kendo-grid-column field="pcarData" title="Presa in carico" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <kendo-grid-column field="cforData" title="Chiusa Fornitore" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <kendo-grid-column field="ccliData" title="Chiusa Cliente" format="dd/MM/yyyy HH:mm"></kendo-grid-column>
        <!--      <kendo-grid-column field="descrizione" title="descrizione"></kendo-grid-column>-->


        <!--      <kendo-grid-column field="descrizione_stato" title="stato"></kendo-grid-column>-->

        <!--      <kendo-grid-column field="aperDesc" title="aperDesc"></kendo-grid-column>-->
        <!--      <kendo-grid-column field="aperDelta" title="aperDelta"></kendo-grid-column>-->

        <!--      <kendo-grid-column field="pcarDesc" title="pcarDesc"></kendo-grid-column>-->
        <!--      <kendo-grid-column field="pcarDelta" title="pcarDelta"></kendo-grid-column>-->

        <!--      <kendo-grid-column field="cforDesc" title="cforDesc"></kendo-grid-column>-->
        <!--      <kendo-grid-column field="cforDelta" title="cforDelta"></kendo-grid-column>-->

        <!--      <kendo-grid-column field="ccliDesc" title="ccliDesc"></kendo-grid-column>-->
        <!--      <kendo-grid-column field="ccliDelta" title="ccliDelta"></kendo-grid-column>-->

        <kendo-grid-excel fileName="Stats-Segnalazioni.xlsx"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Stats-Segnalazioni.pdf" [allPages]="true"></kendo-grid-pdf>
      </kendo-grid>
    </div>
  </tab>
  <tab heading="Analisi Segnalazione" class="detail-tab h-100"
       (selectTab)="tabSelectionChanged($event)">

    <ng-template tabHeading>
      <span></span>
    </ng-template>
    <div>
      <app-toolbar-grid
        [insertEnabled]="false"
        [deleteEnabled]="false"
        [deleteClickable]="false"
        [openClickable]="false"
        [addClickable]="false"
        [refreshClickable]="true"
        [exportPdfClickable]="exportPdfClickable"
        [exportXlsClickable]="exportXlsClickable"
        [updateEnabled]="true"
        (exportExcel)="exportExcel()"
        (exportPdf)="exportPdf()"
        (refreshData)="refresh()"
      ></app-toolbar-grid>
      <kendo-grid
        [data]="(view | async) || []"
        [pageSize]="gridState.take || 100"
        [skip]="gridState.skip || 0"
        [sort]="gridState.sort || []"
        [pageable]="true"
        [sortable]="true"
        [resizable]="true"
        (dataStateChange)="onStateChange($event)"
        [navigable]="true"
      >
        <kendo-grid-column field="contratto" title="Contratto"></kendo-grid-column>
        <kendo-grid-column field="servizio" title="Servizio"></kendo-grid-column>
        <kendo-grid-column field="totSegn" title="Tot.Segn."></kendo-grid-column>
        <kendo-grid-column field="totSegnAper" title="Tot. Aperte"></kendo-grid-column>
        <kendo-grid-column field="totSegnPcar" title="Tot. Prese in carico"></kendo-grid-column>
        <kendo-grid-column field="totSegnCfor" title="Tot. Chiuse Fornitore"></kendo-grid-column>
        <kendo-grid-column field="totSegnCcli" title="Tot. Chiuse Cliente"></kendo-grid-column>
        <kendo-grid-column field="mediaAperToPcarOre" title="Media Aper. -> P.Car."></kendo-grid-column>
        <kendo-grid-column field="mediaPcarToCforOre" title="Media P.Car. -> Chiusa Forn."></kendo-grid-column>
        <kendo-grid-column field="mediaCforToCcliOre" title="Media Chiusa Forn. -> Chiusa Cli."></kendo-grid-column>
        <kendo-grid-excel fileName="Stats-Segnalazioni.xlsx"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Stats-Segnalazioni.pdf" [allPages]="true"></kendo-grid-pdf>
      </kendo-grid>
    </div>
  </tab>
  <tab heading="Analisi Segnalatori" class="detail-tab h-100"
       (selectTab)="tabSelectionChanged($event)">

    <ng-template tabHeading>
      <span></span>
    </ng-template>
    <div>
      <app-toolbar-grid
        [insertEnabled]="false"
        [deleteEnabled]="false"
        [deleteClickable]="false"
        [openClickable]="false"
        [addClickable]="false"
        [refreshClickable]="true"
        [exportPdfClickable]="exportPdfClickable"
        [exportXlsClickable]="exportXlsClickable"
        [updateEnabled]="true"
        (exportExcel)="exportExcel()"
        (exportPdf)="exportPdf()"
        (refreshData)="refresh()"
      ></app-toolbar-grid>
      <kendo-grid
        [data]="(view | async) || []"
        [pageSize]="gridState.take || 100"
        [skip]="gridState.skip || 0"
        [sort]="gridState.sort || []"
        [pageable]="true"
        [sortable]="true"
        [resizable]="true"
        (dataStateChange)="onStateChange($event)"
        [navigable]="true"
      >
        <kendo-grid-column field="contratto" title="Contratto"></kendo-grid-column>
        <kendo-grid-column field="servizio" title="Servizio"></kendo-grid-column>
        <kendo-grid-column field="descUser" title="Utente"></kendo-grid-column>
        <kendo-grid-column field="segnTot" title="Tot.Segn."></kendo-grid-column>
        <kendo-grid-column field="segnAper" title="Tot. Aperte"></kendo-grid-column>
        <kendo-grid-column field="segnPcar" title="Tot. in Carico"></kendo-grid-column>
        <kendo-grid-column field="segnCfor" title="Tot. Chiuse Forn."></kendo-grid-column>
        <kendo-grid-column field="segnCcli" title="Tot. Chiuse Cli."></kendo-grid-column>
        <kendo-grid-column field="mediaAper" title="Media Aper."></kendo-grid-column>
        <kendo-grid-column field="mediaPcar" title="Media in Carico"></kendo-grid-column>
        <kendo-grid-column field="mediaCfor" title="Media Chiusura Forn."></kendo-grid-column>
        <!--<kendo-grid-column field="mediaCCli" title="Media Chiusura Cli."></kendo-grid-column>-->
        <kendo-grid-excel fileName="Segnalazioni.xlsx"></kendo-grid-excel>
        <kendo-grid-pdf fileName="Segnalazioni.pdf" [allPages]="true"></kendo-grid-pdf>
      </kendo-grid>
    </div>
  </tab>
</tabset>

