<div class="tab-container">
  <app-toolbar-detail
    [updateEnabled]="userGrants.update && modificaEnabled"
    [deleteEnabled]="userGrants.delete && eliminaEnabled"
    [readOnly]="readOnly"
    [deleting]="deleting"
    (cancel)="revertDetail()"
    (save)="saveDetail()"
    (editRow)="editDetail()"
    (deleteRow)="deleteDetail()"
    (confirmDelete)="deleteRecord()"
    (closeDetail)="closeTab()"
    (print)="print()"
    (function1)="avanzaStato()"
    [function1Enabled]="avanzaStatoEnabled"
    [textBtn1]="avanzaStatoLabel"
    iconBtn1="fas fa-hand-point-right"
    class="pb-3"
    (function2)="aggiungiNotaStato()"
    [function2Enabled]="aggiungiNotaEnabled"
    textBtn2="{{'SEGNALAZIONE_DETAIL.AGGIUNGI_NOTA_STATO' | translate}}"
    iconBtn2="fas fa-sticky-note"></app-toolbar-detail>

  <!--  DETAIL -->
  <div class="detail-content h-100">
    <div class="row m-0 h-100">
      <div class="col-8 detail-column">
        <h5 class="column-title">
          {{'SEGNALAZIONE_DETAIL.DETTAGLIO' | translate}}
        </h5>
        <ng-form #myForm="ngForm" class="detail-data-container">
          <!--<div class="form-group">
            <label for="id">ID</label>
            <input id="id" type="text" size="30" [(ngModel)]="data.segnalazione.id" name="id" readonly
                   [disabled]="true">
          </div>-->
          <ng-container *ngIf="data.segnalazione">
            <div class="d-flex">
              <div class="form-group field-codice" *ngIf="data.segnalazione?.codiceSegnalazione">
                <label for="code">{{'SEGNALAZIONE_DETAIL.CODICE' | translate}}</label>
                <input class="form-control" id="code" type="text" size="30"
                       [(ngModel)]="data.segnalazione.codiceSegnalazione" name="code"
                       [disabled]="true">
              </div>

              <div class="form-group field-unita-operativa">
                <label>{{'SEGNALAZIONE_DETAIL.UNITA_OPERATIVA' | translate}}*</label>
                <kendo-combobox [data]="unitaOperativeLookupFiltered"
                                [allowCustom]="false"
                                [(ngModel)]="unitaSelected"
                                textField="descrizioneUnita"
                                valueField="id"
                                required
                                (valueChange)="unitaChange($event)"
                                class="w-100"
                                placeholder="{{'SEGNALAZIONE_DETAIL.UNITA_OPERATIVA_PLACEHOLDER' | translate}}"
                                [disabled]="readOnly || !modificaEnabled"
                                name="unitaOperativa"
                                #idUoCmb
                                (focusin)="idUoCmb.toggle(true)">
                </kendo-combobox>
              </div>
            </div>

            <div class="d-flex">
              <div class="form-group field-centro-costo">
                <label for="centroCosto">{{'SEGNALAZIONE_DETAIL.CENTRO_DI_COSTO' | translate}}*</label>
                <input id="centroCosto"
                       type="text"
                       class="form-control"
                       maxlength="150"
                       required
                       [(ngModel)]="data.segnalazione.centroCosto"
                       name="centroCosto"
                       [disabled]="readOnly || !modificaEnabled">
              </div>

              <div class="form-group field-telefono">
                <label for="telefono">{{'SEGNALAZIONE_DETAIL.TELEFONO' | translate}}*</label>
                <input id="telefono"
                       type="text"
                       maxlength="20"
                       required
                       class="form-control"
                       [(ngModel)]="data.segnalazione.telefono"
                       name="telefono"
                       [disabled]="readOnly || !modificaEnabled">
              </div>
            </div>

            <div class="form-group">
              <label for="oggetto">{{'SEGNALAZIONE_DETAIL.OGGETTO' | translate}}</label>
              <input id="oggetto" type="text" maxlength="150" [(ngModel)]="data.segnalazione.oggettoSegnalazione"
                     name="oggetto"
                     class="form-control"
                     [disabled]="readOnly || !modificaEnabled">
            </div>

            <div class="d-flex">
              <div class="form-group field-articolo">
                <label>{{'SEGNALAZIONE_DETAIL.ARTICOLO' | translate}}*</label>
                <kendo-combobox [data]="articoliLookupList"
                                [allowCustom]="false"
                                [(ngModel)]="data.segnalazione.articolo"
                                textField="descrizione"
                                valueField="idUnitaControllo"
                                required
                                class="w-100"
                                placeholder="{{'SEGNALAZIONE_DETAIL.ARTICOLO_PLACEHOLDER' | translate}}"
                                [disabled]="readOnly || !modificaEnabled"
                                name="articolo"
                                #idArticoloCmb
                                (focusin)="idArticoloCmb.toggle(true)">
                </kendo-combobox>
              </div>

              <div class="form-group field-num-unita-coinvolte">
                <label for="numUnitaCoinvolte">{{'SEGNALAZIONE_DETAIL.NUM_UNITA_COINVOLTE' | translate}}</label>
                <input id="numUnitaCoinvolte"
                       type="text"
                       maxlength="100"
                       class="form-control"
                       [(ngModel)]="data.segnalazione.numUnitaCoinvolte"
                       name="numUnitaCoinvolte"
                       [disabled]="readOnly || !modificaEnabled">
              </div>
            </div>

            <!--          LISTA DINAMICA CRITICITA -->
            <div class="criticita-container">
              <div class="criticita-header">
                <div>{{'SEGNALAZIONE_DETAIL.CRITICITA_RISCONTRATE' | translate}}</div>
              </div>
              <div class="criticita-list">
                <div *ngFor="let crit of data.listaCriticita; let i = index" class="criticita-element">
                  <div class="d-flex flex-grow-1">
                    <!--              CRITICITA -->
                    <div class="form-group k-flex-basis-0" [style.flex-grow]="2">
                      <label>{{'SEGNALAZIONE_DETAIL.CRITICITA' | translate}}*</label>
                      <kendo-combobox [data]="criticitaLookupList"
                                      [allowCustom]="false"
                                      [(ngModel)]="crit.criticitaContratto"
                                      textField="descrizione"
                                      valueField="idIndicatore"
                                      required
                                      class="w-100"
                                      placeholder="{{'SEGNALAZIONE_DETAIL.CRITICITA_PLACEHOLDER' | translate}}"
                                      [disabled]="readOnly || !modificaEnabled"
                                      #critCmb
                                      [name]="'criticitaContratto_' + i"
                                      (focusin)="critCmb.toggle(true)">
                      </kendo-combobox>
                    </div>

                    <!--              VALORE 1-->
                    <div class="form-group k-flex-basis-0 flex-grow-1">
                      <label [for]="'valore1_' + i">{{'SEGNALAZIONE_DETAIL.VALORE_1' | translate}} </label>
                      <input id="valore1"
                             type="text"
                             maxlength="150"
                             [placeholder]="'SEGNALAZIONE_DETAIL.NUM_CRITICITA_O_DATA_CONSEGNA' | translate"
                             [(ngModel)]="crit.valore1"
                             [name]="'valore1_' + i"
                             class="form-control"
                             [disabled]="readOnly || !modificaEnabled">
                    </div>
                    <!--              VALORE 2-->
                    <div class="form-group k-flex-basis-0 flex-grow-1">
                      <label [for]="'valore2_' + i">{{'SEGNALAZIONE_DETAIL.VALORE_2' | translate}} </label>
                      <input id="valore2"
                             type="text"
                             maxlength="50"
                             [placeholder]="'SEGNALAZIONE_DETAIL.DIMENSIONE_CRITICITA' | translate"
                             [(ngModel)]="crit.valore2"
                             [name]="'valore2_' + i"
                             class="form-control"
                             [disabled]="readOnly || !modificaEnabled">
                    </div>
                  </div>

                  <div class="delete-container"
                       [hidden]="readOnly || !modificaEnabled || data.listaCriticita.length == 1">
                    <button kendoButton (click)="rimuoviCriticita(crit)">
                      <i class="pi pi-trash"></i>
                    </button>
                  </div>

                </div>

                <div class="text-center pt-2" [hidden]="readOnly || !modificaEnabled">
                  <button kendoButton [primary]="false"
                          (click)="aggiungiCriticita()">{{'SEGNALAZIONE_DETAIL.AGGIUNGI_CRITICITA' | translate}}</button>
                </div>

              </div>
            </div>

            <div class="form-group">
              <label for="descr">{{'SEGNALAZIONE_DETAIL.NOTE' | translate}}</label>
              <textarea id="descr" type="text" maxlength="500" rows="6" class="w-100"
                        [(ngModel)]="data.segnalazione.descrizione" name="descr"
                        class="form-control"
                        [disabled]="readOnly || !modificaEnabled"></textarea>
            </div>

            <div class="form-group">
              <label for="urgente">{{'SEGNALAZIONE_DETAIL.URGENTE' | translate}}</label>
              <input type="checkbox" id="urgente" checked="{{data.segnalazione.flagUrgenza}}"
                     [(ngModel)]="data.segnalazione.flagUrgenza"
                     name="urgente"
                     [disabled]="readOnly || !modificaEnabled">
            </div>
          </ng-container>

          <div class="d-flex">
            <div class="text-center flex-grow-1 k-flex-basis-0">
              <app-image-selector #fotoComp
                                  [readOnly]="readOnly || !modificaEnabled"
                                  selectFileLabel="{{'SEGNALAZIONE_DETAIL.AGGIUNGI_FOTO' | translate}}"
                                  removeFileLabel="{{'SEGNALAZIONE_DETAIL.RIMUOVI_FOTO' | translate}}"
                                  (fileChanged)="fotoChanged($event)"
                                  (fileRemoved)="fotoRemoved()"></app-image-selector>
            </div>

            <div class="text-center flex-grow-1 k-flex-basis-0">
              <app-image-selector #firmaComp
                                  [readOnly]="readOnly || !modificaEnabled"
                                  selectFileLabel="{{'SEGNALAZIONE_DETAIL.AGGIUNGI_FIRMA' | translate}}"
                                  removeFileLabel="{{'SEGNALAZIONE_DETAIL.RIMUOVI_FIRMA' | translate}}"
                                  (fileChanged)="firmaChanged($event)"
                                  (fileRemoved)="firmaRemoved()"></app-image-selector>
            </div>

          </div>
        </ng-form>
      </div>

      <div class="col-4 detail-column">
        <h5 class="column-title">
          {{'SEGNALAZIONE_DETAIL.STORIA' | translate}}
        </h5>
        <div class="placeholder-storia" *ngIf="data.storicoSegnalazione != null && data.storicoSegnalazione.length < 1">
          {{'SEGNALAZIONE_DETAIL.STORIA_PLACEHOLDER' | translate}}
        </div>

        <div *ngIf="this.data?.segnalazione?.esito != null" class="esito-container">
          <label>{{'SEGNALAZIONE_DETAIL.ESITO' | translate}}</label>
          <div *ngIf="this.data.segnalazione.esito == 'OK'" class="esito esito-success">
            <i class="fa fa-thumbs-up"></i>
          </div>
          <div *ngIf="this.data.segnalazione.esito == 'KO'" class="esito esito-error">
            <i class="fa fa-thumbs-down"></i>
          </div>
        </div>

        <div class="detail-data-container">
          <div *ngFor="let stato of data.storicoSegnalazione">
            <div class="stato-card">
              <div class="d-flex">

                <div class="stato-color-band" [ngClass]="'stato-color-band-' + stato.codStato"></div>

                <div class="stato-data-section">
                  <div class="stato-title">
                    {{stato.descrStato}}
                  </div>
                  <div class="data-row" *ngFor="let nota of stato.listaNote">
                    <div class="d-flex">
                      <div class="user">
                        {{nota.userLabel}}
                      </div>
                      <div class="date">
                        {{nota.data | date: 'dd/MM/yyyy - HH:mm:ss'}}
                      </div>
                    </div>
                    <div class="nota">
                      {{nota.nota}}
                    </div>
                  </div>

                  <div class="data-row"
                       *ngIf="stato.listaNote == null || stato.listaNote.length < 1">
                    <div class="d-flex">
                      <div class="user">
                        {{stato.userLabel}}
                      </div>
                      <div class="date">
                        {{stato.data | date: 'dd/MM/yyyy - HH:mm:ss'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <pre>{{ data | json }}</pre>-->
</div>

<div class="modal" tabindex="-1" role="dialog" id="noteModal-{{data.id}}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Nota facoltativa</h5>
        <!--        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(true)">-->
        <!--          <span aria-hidden="true">&times;</span>-->
        <!--        </button>-->
      </div>
      <div class="modal-body">
        <textarea maxlength="500" rows="5" cols="40" name="nota" id="nota" [(ngModel)]="data.nota"
                  class="w-100"></textarea>

        <div *ngIf="this.data?.segnalazione?.stato === 'CCLI' && this.data?.segnalazione?.esito == null">
          <div>
            {{'SEGNALAZIONE_DETAIL.SCEGLI_ESITO' | translate}}*
          </div>
          <div class="btn-group">
            <label class="btn btn-outline-danger " btnRadio="KO" [(ngModel)]="esito"
                   tabindex="0" role="button"><i class="fa fa-thumbs-down"></i></label>
            <label class="btn btn-outline-success " btnRadio="OK" [(ngModel)]="esito"
                   tabindex="0" role="button"><i class="fa fa-thumbs-up"></i></label>
          </div>

        </div>
      </div>

      <div class="modal-footer">
        <button kendoButton [primary]="false" (click)="closeModal(true)">{{'COMMON.Cancel' | translate}}</button>
        <button kendoButton [primary]="true" (click)="closeModal(false)"
                [disabled]="data?.segnalazione?.stato === 'CCLI' && this.data?.segnalazione?.esito == null && esito == null">{{'COMMON.Confirm' | translate}}
        </button>
      </div>
    </div>
  </div>
</div>


