<div class="inline-crud-container">
  <app-serclicon-search (search)="handleSearch($event)"></app-serclicon-search>
  <form novalidate #myForm="ngForm">
    <app-toolbar-grid
      [editing]="editingEnabled"
      [insertEnabled]="insertEnabled"
      [deleteEnabled]="eliminaEnabled"
      [updateEnabled]="updateEnabled"
      [updateClickable]="updateClickable"
      [deleteClickable]="eliminaClickable"
      [addClickable]="addClickable"
      [refreshClickable]="refreshClickable"
      [exportPdfClickable]="exportPdfClickable"
      [exportXlsClickable]="exportXlsClickable"
      (addRow)="addNewRecord()"
      (editRow)="editRecord()"
      (deleteRow)="deleteRecord()"
      (exportExcel)="exportExcel()"
      (exportPdf)="exportPdf()"
      (refreshData)="refresh()"
      (save)="saveRecord()"
      (cancel)="cancelEditing()"
    ></app-toolbar-grid>
    <kendo-grid
      [data]="(view | async) || []"
      [pageSize]="gridState.take || 100"
      [skip]="gridState.skip || 0"
      [sort]="gridState.sort || []"
      [pageable]="true"
      [sortable]="true"
      [resizable]="true"
      (dataStateChange)="onStateChange($event)"
      [kendoGridSelectBy]="dataSource.getKeyField()"
      [selectedKeys]="selectedRows"
      [navigable]="true"
      (selectionChange)="onRowSelected(selectedRows)"
      [columnMenu]="{ filter: true }"
    >
      <kendo-grid-excel fileName="unita_operative.xlsx"></kendo-grid-excel>
      <kendo-grid-pdf fileName="unita_operative.pdf" [allPages]="true"></kendo-grid-pdf>
      <kendo-grid-column field="codiceUnita" title="Codice">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.codiceUnita"
            kendoGridFocusable
            name="codiceUnita"
            class="k-textbox"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="descrizioneUnita" title="Descrizione">
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.descrizioneUnita"
            kendoGridFocusable
            name="descrizioneUnita"
            class="k-textbox"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="idGruppoUo" title="Gruppo">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ getDescrGruppoUo(dataItem.idGruppoUo)?.descrizioneGruppo }}
        </ng-template>
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <kendo-dropdownlist
            style="width: 100%"
            name="idGruppoUo"
            [defaultItem]="{descrizioneGruppo: 'Seleziona un gruppo....'}"
            [data]="listaGruppiUnitaOperative"
            textField="descrizioneGruppo"
            valueField="idGruppoUnitaOp"
            [valuePrimitive]="true"
            [(ngModel)]="dataItem.idGruppoUo"
            required>
          </kendo-dropdownlist>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
