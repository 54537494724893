<div class="login">
<!--  LOADER-->
  <div *ngIf="isLoading" class="middle-box text-center animated fadeInDown">
    <div class="logging-text">Logging in...</div>
    <div><i class="logging-spinner pi pi-spin pi-spinner"></i></div>
  </div>
<!--  LOGIN FORM-->
  <div *ngIf="!isLoading" class="middle-box text-center animated fadeInDown">
    <div style="padding: 10px;">
      <form id="loginForm" name="loginForm">
        <div class="row" style="margin-bottom: 15px;">
          <div>
            <img id="escape-login-logo" src="assets/img/logo_complete.png" class="logo-size" alt="">
          </div>
        </div>
        <div class="form-group">
          <input type="text" [(ngModel)]="username" name="username" class="form-control" placeholder="Username" required="required">
        </div>
        <div class="form-group">
          <input type="password" [(ngModel)]="password" name="password" class="form-control" placeholder="Password" required="required">
        </div>

        <button class="btn btn-primary block" (click)="tryLogIn(false)">Login</button>
      </form>
      <div style="margin-top: 25px;">
        <span>Copyright (C) E&S s.r.l. - All Rights Reserved</span>
      </div>
    </div>
  </div>

</div>
