import {Injectable} from '@angular/core';
import {fromEvent, Observable} from 'rxjs';
import {BusMessage, BusMessageData, BusMessageDataNavigateTo, BusMessageDataUrlUpdated, BusMessageType} from './message';
import {filter, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessageBusService {

  private incomingMessages: Observable<BusMessage>;
  private iframeWindow?: Window;

  constructor() {
    this.incomingMessages = fromEvent<MessageEvent>(window, 'message').pipe(
      // Validate messages
      filter((event) => this.validateMessage(event)),
      // MessageEvent => BusMessage
      map((event) => {
        return {type: event.data.type as BusMessageType, data: event.data.data as BusMessageData};
      }),
      // tap((msg) => console.log(msg))
    );
  }

  /**
   * Register iframe's window
   */
  public registerIframe(window: Window): void {
    this.iframeWindow = window;
  }

  /**
   * Observe iframe's url changes
   */
  public onIframeInternalNavigation(): Observable<BusMessageDataUrlUpdated> {
    return this.incomingMessages.pipe(
      // Filtering by MessageType
      filter((msg: BusMessage) => msg.type === BusMessageType.URL_UPDATED),
      // BusMessage => BusMessageDataUrlUpdated
      map((msg: BusMessage) => msg.data as BusMessageDataUrlUpdated),
    );
  }

  /**
   * Send a message to request a navigation
   */
  public navigateIframeTo(data: BusMessageDataNavigateTo): void {
    this.sendMessageToIframe({type: BusMessageType.NAVIGATE_TO, data});
  }

  /*
   * Private methods
   */

  private sendMessageToIframe(msg: any): void {
    if (this.iframeWindow) {
      this.iframeWindow.postMessage(msg, location.origin);
    }
  }

  private validateMessage(event: MessageEvent): boolean {
    // Ensure message arrives from the iframe
    return (event.origin === location.origin);
  }
}
