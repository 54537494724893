import {Injectable} from '@angular/core';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {
  CreaIndicatoreContrattoRequest,
  EliminaIndicatoreContrattoRequest,
  GetIndicatoreContrattoRequest,
  GetListaIndicatoreContrattoRequest,
  IndicatoreContratto,
  IndicatoreContrattoControllerService,
  IndicatoreContrattoSearch,
  ModificaIndicatoreContrattoRequest,
  SovrascriviListaIndicatoreContrattoRequest
} from '../../../api';
import {Observable, of} from 'rxjs';
import {ErrorService} from '../../../shared/core/error.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IndicatoriContrattiService extends CrudDatasource<IndicatoreContratto, IndicatoreContratto, IndicatoreContrattoSearch> {

  constructor(private service: IndicatoreContrattoControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  protected callLoad(searchRequest: IndicatoreContrattoSearch | undefined): Observable<IndicatoreContratto[] | undefined> {
    if (searchRequest) {
      const req: GetListaIndicatoreContrattoRequest = {
        parametriRicerca: searchRequest
      };
      return this.service.getListaIndicatoreContrattoUsingPOST(req).pipe(
        map(res => res.indicatoreContratto)
      );
    } else {
      return of([]);
    }
  }

  protected callGetByKey(key: any): Observable<IndicatoreContratto | undefined> {
    const req: GetIndicatoreContrattoRequest = {
      idIndicatoreContratto: key
    };
    return this.service.getIndicatoreContrattoUsingPOST(req).pipe(
      map(res => res.indicatoreContratto)
    );
  }

  protected callInsert(values: IndicatoreContratto): Observable<IndicatoreContratto | undefined> {
    const req: CreaIndicatoreContrattoRequest = {
      indicatoreContratto: values
    };
    return this.service.creaIndicatoreContrattoUsingPOST(req).pipe(
      map(res => res.indicatoreContratto)
    );
  }

  protected callDelete(values: IndicatoreContratto): Observable<any> {
    const req: EliminaIndicatoreContrattoRequest = {
      indicatoreContratto: values
    };
    return this.service.eliminaIndicatoreContrattoUsingPOST(req);
  }

  protected callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaIndicatoreContrattoRequest = {};
    return this.service.getListaIndicatoreContrattoUsingPOST(req).pipe(
      map(res => res.indicatoreContratto?.length || 0)
    );
  }

  protected callUpdate(key: any, values: IndicatoreContratto): Observable<IndicatoreContratto | undefined> {
    // FIXME gestire update PK
    const req: ModificaIndicatoreContrattoRequest = {
      indicatoreContratto: values
    };
    return this.service.modificaIndicatoreContrattoUsingPOST(req).pipe(
      map(res => res.indicatoreContratto)
    );
  }

  getKeyField(): string {
    return 'id';
  }

  public overwriteAll(values: IndicatoreContratto[], idContratto: number): Observable<IndicatoreContratto[] | undefined> {
    this.reset();
    return this.callOverwriteAll(values, idContratto)
      .pipe(
        this.refreshAndErrorHandling()
      );
  }

  callOverwriteAll(values: IndicatoreContratto[], idContratto: number): Observable<IndicatoreContratto[] | undefined> {
    const req: SovrascriviListaIndicatoreContrattoRequest = {
      listaIndicatoreContratto: values,
      idContratto
    };
    return this.service.sovrascriviListaIndicatoreContrattoUsingPOST(req).pipe(
      map(res => res.listaIndicatoreContratto)
    );
  }

}
