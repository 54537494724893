import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {NavigationExtras, ParamMap, Router} from '@angular/router';
import {MessageBusService} from './message-bus.service';
import {BusMessageDataUrlUpdated} from './message';
import {AuthService} from './auth.service';
import * as LZString from 'lz-string';

export interface IframeState {
  name: string;
  params: {};
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class NavStateService {

  private iframeStateSub = new BehaviorSubject<IframeState | null>(null);

  private firstNavigationReceived = false;
  public firstNavigation = new BehaviorSubject<boolean>(false);

  constructor(private router: Router, private authService: AuthService, private msgBus: MessageBusService) {
  }

  /**
   * Initialize <b>once</b> in the main container
   */
  public init(): void {
    this.msgBus.onIframeInternalNavigation().subscribe((msg) => {
      // Navigation comes from the iframe
      this.navigationFromIframe(msg);
      if (!this.firstNavigationReceived) {
        this.firstNavigation.next(true);
        this.firstNavigationReceived = true;
      }
    });
  }

  /**
   * Manage navigation from browser.
   */
  public navigationFromBrowser(params: ParamMap): void {
    const msg: BusMessageDataUrlUpdated = JSON.parse(LZString.decompressFromEncodedURIComponent(params.get('f') || '') || '{}');
    const newState: IframeState = {
      name: msg.path,
      params: msg.params,
      url: msg.url,
    };
    // Detect state changes
    if (!this.iframeStateSub.getValue() || newState.url !== this.iframeStateSub.getValue()?.url) {
      // Communicate state changes
      this.iframeStateSub.next(newState);
    }
  }

  /**
   * Observe state changes
   */
  public onStateChanged(): Observable<IframeState | null> {
    return this.iframeStateSub;
  }

  /*
   * Private methods
   */

  /**
   * Manage navigation from iframe.
   */
  private navigationFromIframe(msg: BusMessageDataUrlUpdated): void {
    // Apply state changes
    const newState = {
      name: msg.path,
      params: msg.params,
      url: msg.url,
    };
    this.iframeStateSub.next(newState);

    const lzPar = LZString.compressToEncodedURIComponent(JSON.stringify(msg));

    // Update global URL
    const extras: NavigationExtras = {
      queryParams: {
        f: lzPar
      },
      queryParamsHandling: 'merge'
    };
    this.router.navigate([], extras);
  }
}
