import {Component, OnInit} from '@angular/core';
import {
  CreaUtenteGruppoUoRequest,
  EliminaUtenteGruppoUoRequest,
  GetListaUtentiGruppiUORequest,
  GruppiUnitaOperativeControllerService,
  ModificaUtenteGruppoUORequest, ServizioClienteContrattoLookUp,
  UtenteGruppoUO,
  UtentiControllerService,
  UtentiGruppiUoControllerService
} from '../../../api';
import {deepCopy} from '../../../shared/crud/deep-copy';
import {DialogCloseResult, DialogRef, DialogService} from '@progress/kendo-angular-dialog';
import {StatoSgn} from '../segnalazione/stato-sgn';


@Component({
  selector: 'app-abilitazione-segnalazioni',
  templateUrl: './abilitazione-segnalazioni.component.html',
  styleUrls: ['./abilitazione-segnalazioni.component.scss']
})
export class AbilitazioneSegnalazioniComponent implements OnInit {
  abilitazioni: AbilitazioniSegnalazione[];
  treeUtenti = new Map<number, { desc: string, map: Map<number, AbilitazioniSegnalazione[]> }>();
  treeGruppi = new Map<number, { desc: string, map: Map<number, AbilitazioniSegnalazione[]> }>();
  viewMode: string;
  idSerCliCon?: number;
  idCliCon?: number;
  selctedItems?: AbilitazioniSegnalazione[];
  idGruppoCombo?: number;
  idUtenteCombo?: number;
  listaUtenti?: object[];
  listaGruppi?: object[];
  readonlyComboGruppo = true;
  readonlyComboUtente = true;
  buttonNuovaAbilitazioneClickable = false;

  constructor(private abilService: UtentiGruppiUoControllerService, private utentiService: UtentiControllerService, private gruppiService: GruppiUnitaOperativeControllerService,
              private dialogService: DialogService) {
    this.abilitazioni = [];
    this.viewMode = 'utente';
  }

  ngOnInit(): void {
    this.loadUtenti();
    this.loadAbilitazioni();
  }

  servizioChanged(servizio: ServizioClienteContrattoLookUp): void {
    this.idSerCliCon = servizio.id;
    this.idCliCon = servizio.idCliCon;
    this.loadGruppi();
    this.loadUtenti();
  }

  editGroup(b: AbilitazioniSegnalazione[]): void {
    // alert('idUtente:' + idUtente + ' idGruppo:' + idGruppo);
    this.selctedItems = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < b.length; i++) {
      this.selctedItems.push(deepCopy(b[i]));
      this.idGruppoCombo = b[i].idGruppo;
      this.idUtenteCombo = b[i].idUtente;
    }
    const modal = document.getElementById('noteModal-' + this.idUtenteCombo + '-' + this.idGruppoCombo);
    if (modal) {
      modal.style.display = 'block';
    }
  }

  handleSearch(idSerCliCon: number): void {
    this.idSerCliCon = idSerCliCon;
    if (idSerCliCon > 0) {
      this.buttonNuovaAbilitazioneClickable = true;
      this.doSearch();
    } else {
      this.buttonNuovaAbilitazioneClickable = false;
      this.clear();
    }
  }

  removeGroup(b: AbilitazioniSegnalazione[]): void {

    const dialog: DialogRef = this.dialogService.open({
      title: 'Si prega di confermare',
      content: 'Sei sicuro?',
      actions: [{text: 'No'}, {text: 'Si', primary: true}],
      width: 450,
      height: 200,
      minWidth: 250,
    });
    dialog.result.subscribe((result) => {
      if (result instanceof DialogCloseResult) {
        console.log('close');
      } else {
        if (result.text !== 'No') {
          // alert('idUtente:' + idUtente + ' idGruppo:' + idGruppo);
          this.selctedItems = [];
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < b.length; i++) {
            this.selctedItems.push(deepCopy(b[i]));
            this.idGruppoCombo = b[i].idGruppo;
            this.idUtenteCombo = b[i].idUtente;
          }
          const request: EliminaUtenteGruppoUoRequest = {uguo: []};
          // id_gruppoUnitaOp: this.idGruppoCombo, id_utente: this.idUtenteCombo, id_SerCliCon: this.idSerCliCon
          this.selctedItems?.forEach(ug => {
            const temp: UtenteGruppoUO = {
              id_gruppoUnitaOp: this.idGruppoCombo,
              id_SerCliCon: this.idSerCliCon,
              id_utente: this.idUtenteCombo,
              cod_stato: ug.codiceStato,
              flag_attivazione: ug.attivazione ? ug.attivazione : false,
              flag_email: ug.email ? ug.email : false,
              flag_notifica_push: ug.push ? ug.push : false,
              timeStamp: ug.timestamp
            };
            request.uguo?.push(temp);
          });
          if (request.uguo && request.uguo.length !== 0) {
            this.abilService.eliminaUtenteGruppoUnitaOperativaUsingPOST(request).subscribe(value => {
              this.doSearch();
            });
          }
        }
      }
    });
  }

  nuovaAbilitazione(): void {
    this.selctedItems = [];
    this.idGruppoCombo = undefined;
    this.idUtenteCombo = undefined;
    this.readonlyComboGruppo = false;
    this.readonlyComboUtente = false;
    const modal = document.getElementById('noteModal-nuova');
    if (modal) {
      modal.style.display = 'block';
    }
  }

  chiudiModalNuovaAbilitazione(): void {
    const modal = document.getElementById('noteModal-nuova');
    if (modal) {
      modal.style.display = 'none';
    }
    this.readonlyComboGruppo = true;
    this.readonlyComboUtente = true;
  }

  inserisciAbilitazioni(): void {
    this.chiudiModalNuovaAbilitazione();
    const request: CreaUtenteGruppoUoRequest = {uguo: []};
    // id_gruppoUnitaOp: this.idGruppoCombo, id_utente: this.idUtenteCombo, id_SerCliCon: this.idSerCliCon
    this.selctedItems?.forEach(ug => {
      const temp: UtenteGruppoUO = {
        id_gruppoUnitaOp: this.idGruppoCombo,
        id_SerCliCon: this.idSerCliCon,
        id_utente: this.idUtenteCombo,
        cod_stato: ug.codiceStato,
        flag_attivazione: ug.attivazione ? ug.attivazione : false,
        flag_email: ug.email ? ug.email : false,
        flag_notifica_push: ug.push ? ug.push : false,
        timeStamp: ug.timestamp
      };
      request.uguo?.push(temp);
    });
    if (request.uguo && request.uguo.length !== 0) {
      this.abilService.creaUtenteGruppoUnitaOperativeUsingPOST(request).subscribe(value => {
        this.doSearch();
      });
    }
  }

  private loadAbilitazioni(idSerCliCon?: number): void {
    const req: GetListaUtentiGruppiUORequest = {
      parametriRicerca: {
        id_SerCliCon: idSerCliCon
      }
    };

    this.abilService.getListaUtentiGruppiUnitaOperativeUsingPOST(req).subscribe(value => {
      value.listaUtentiGruppiUO?.forEach(ug => {
        if (ug.id_utente && ug.id_gruppoUnitaOp) {
          const a: AbilitazioniSegnalazione = {
            idUtente: ug.id_utente,
            descUtente: ug.utenteLogin || 'Utente ' + ug.id_utente,
            idGruppo: ug.id_gruppoUnitaOp,
            descGruppo: ug.descrizioneGruppoUO || 'Gruppo ' + ug.id_gruppoUnitaOp,
            descStato: ug.statoDescrizione || ug.cod_stato || 'unknown',
            attivazione: ug.flag_attivazione,
            email: ug.flag_email,
            push: ug.flag_notifica_push,
            codiceStato: ug.cod_stato,
            timestamp: ug.timeStamp
          };
          this.abilitazioni.push(a);
        }
      });
      this.createPermissionTree();
    });
  }


  private loadUtenti(): void {
    this.listaUtenti = [];
    if (this.idCliCon) {
      this.abilService.getListaLookUpUtentiSegnalazioniUsingPOST({
        idClienteContratto: this.idCliCon
      }).subscribe(value => {
        value.listaUtenti?.forEach(ug => {
          this.listaUtenti?.push(ug);
        });
      });
    }
  }

  private loadGruppi(): void {
    this.listaGruppi = [];
    this.gruppiService.getListaGruppiUnitaOperativeUsingPOST({parametriRicerca: {idSerCliCon: this.idSerCliCon}})
      .subscribe(value => {
        value.lista?.forEach(ug => {
          this.listaGruppi?.push(ug);
        });
      });
  }

  private createPermissionTree(): void {

    this.abilitazioni.forEach(value => {
      if (!this.treeUtenti.has(value.idUtente)) {
        this.treeUtenti.set(value.idUtente, {
          desc: value.descUtente,
          map: new Map<number, AbilitazioniSegnalazione[]>()
        });
      }

      if (!this.treeGruppi.has(value.idGruppo)) {
        this.treeGruppi.set(value.idGruppo, {
          desc: value.descGruppo,
          map: new Map<number, AbilitazioniSegnalazione[]>()
        });
      }

      // @ts-ignore
      if (!this.treeUtenti.get(value.idUtente).map.has(value.idGruppo)) {
        // @ts-ignore
        this.treeUtenti.get(value.idUtente).map.set(value.idGruppo, []);
      }
      // @ts-ignore
      this.treeUtenti.get(value.idUtente).map.get(value.idGruppo).push(value);

      // @ts-ignore
      if (!this.treeGruppi.get(value.idGruppo).map.has(value.idUtente)) {
        // @ts-ignore
        this.treeGruppi.get(value.idGruppo).map.set(value.idUtente, []);
      }
      // @ts-ignore
      this.treeGruppi.get(value.idGruppo).map.get(value.idUtente).push(value);
    });
  }

  doSearch(): void {
    this.clear();
    this.loadAbilitazioni(this.idSerCliCon);
  }

  private clear(): void {
    this.treeUtenti = new Map<number, { desc: string, map: Map<number, AbilitazioniSegnalazione[]> }>();
    this.treeGruppi = new Map<number, { desc: string, map: Map<number, AbilitazioniSegnalazione[]> }>();
    this.abilitazioni = [];
  }

  closeModal(cancellaNota ?: boolean): void {
    // @ts-ignore
    const modal = document.getElementById('noteModal-' + this.selctedItems[0].idUtente + '-' + this.selctedItems[0].idGruppo);
    if (modal) {
      modal.style.display = 'none';
    }
  }

  saveModal(): void {
    // devo inviare i record modificati
    // this.selctedItems
    this.closeModal();
    const request: ModificaUtenteGruppoUORequest = {uguoList: []};
    // id_gruppoUnitaOp: this.idGruppoCombo, id_utente: this.idUtenteCombo, id_SerCliCon: this.idSerCliCon
    this.selctedItems?.forEach(ug => {
      const temp: UtenteGruppoUO = {
        id_gruppoUnitaOp: this.idGruppoCombo,
        id_SerCliCon: this.idSerCliCon,
        id_utente: this.idUtenteCombo,
        cod_stato: ug.codiceStato,
        flag_attivazione: ug.attivazione ? ug.attivazione : false,
        flag_email: ug.email ? ug.email : false,
        flag_notifica_push: ug.push ? ug.push : false,
        timeStamp: ug.timestamp
      };
      request.uguoList?.push(temp);
    });
    if (request.uguoList && request.uguoList.length !== 0) {
      this.abilService.modificaUtenteGruppoUnitaOperativaUsingPOST(request).subscribe(value => {
        this.doSearch();
      });
    }
  }

  handleUtenteChange(event: any): void {
    if (event) {
      this.idUtenteCombo = event;
    }
    if (!this.selctedItems || this.selctedItems.length === 0) {
      this.creaNuovaStrutturaDatiAbilitazioni();
    }
  }

  handleGruppoChange(event: any): void {
    if (event) {
      this.idGruppoCombo = event;
    }
    if (!this.selctedItems || this.selctedItems.length === 0) {
      this.creaNuovaStrutturaDatiAbilitazioni();
    }
  }

  creaNuovaStrutturaDatiAbilitazioni(): void {
    if (this.idUtenteCombo && this.idUtenteCombo > 0 && this.idGruppoCombo && this.idGruppoCombo > 0) {
      for (let i = 0; i < 4; i++) {
        const temp: AbilitazioniSegnalazione = {
          idGruppo: this.idGruppoCombo ? this.idGruppoCombo : 0,
          idUtente: this.idUtenteCombo ? this.idUtenteCombo : 0,
          codiceStato: this.getStatoFromIndex(i),
          attivazione: false,
          email: false,
          push: false,
          descUtente: '',
          descGruppo: '',
          descStato: this.getDescStatoFromIndex(i)
        };
        this.selctedItems?.push(temp);
      }
    }
  }

  getStatoFromIndex(i: number): string {
    if (i === 0) {
      return StatoSgn.APER;
    } else if (i === 1) {
      return StatoSgn.PCAR;
    } else if (i === 2) {
      return StatoSgn.CFOR;
    } else if (i === 3) {
      return StatoSgn.CCLI;
    } else {
      return '';
    }
  }

  getDescStatoFromIndex(i: number): string {
    if (i === 0) {
      return 'Aperta';
    } else if (i === 1) {
      return 'Presa in carico';
    } else if (i === 2) {
      return 'Chiusa fornitore';
    } else if (i === 3) {
      return 'Chiusa cliente';
    } else {
      return '';
    }
  }

}


interface AbilitazioniSegnalazione {
  id?: number;
  idUtente: number;
  descUtente: string;
  idGruppo: number;
  descGruppo: string;
  codiceStato?: string;
  descStato: string;
  attivazione?: boolean;
  email?: boolean;
  push?: boolean;
  timestamp?: string;
}



