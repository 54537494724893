import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndicatoriContrattiComponent} from './indicatori-contratti/indicatori-contratti.component';
import {ElemcontrolloContrattiComponent} from './elemcontrollo-contratti/elemcontrollo-contratti.component';
import {TabelleRoutingModule} from './tabelle-routing.module';
import {SharedModule} from '../../shared/shared.module';
import {ComboBoxModule} from '@progress/kendo-angular-dropdowns';
import {ButtonModule} from '@progress/kendo-angular-buttons';
import {FormsModule} from '@angular/forms';
import {PickListModule} from 'primeng/picklist';
import {TranslateModule} from '@ngx-translate/core';
import {ArticoliContrattiComponent} from './articoli-contratti/articoli-contratti.component';
import {CriticitaContrattiComponent} from './criticita-contratti/criticita-contratti.component';


@NgModule({
  declarations: [
    IndicatoriContrattiComponent,
    ElemcontrolloContrattiComponent,
    ArticoliContrattiComponent,
    CriticitaContrattiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    TabelleRoutingModule,
    ComboBoxModule,
    ButtonModule,
    PickListModule,
    TranslateModule
  ]
})
export class TabelleModule { }
