import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AuthService} from './auth.service';
import {map, tap} from 'rxjs/operators';
import {MenuService} from './menu.service';
import {MenuItem} from '../../api';
import {valueReferenceToExpression} from '@angular/compiler-cli/src/ngtsc/annotations/src/util';

@Injectable()
export class GrantsService {
  userGrants!: UserSubFunctionGrants;

  constructor(/*public jfApi: BaseappApiService, */public authService: AuthService, private menuService: MenuService) {
  }

  public getFunctionGrants(functionCode: string): Observable<UserSubFunctionGrants> {
    const menuItems = this.menuService.getMenuItems();

    return menuItems.pipe(
      map(value => {
        return this.getAbilitazioni(value, functionCode);
      }),
      map(value => {
        const ret: UserSubFunctionGrants = {
          select: value.includes('SELECT'),
          insert: value.includes('INSERT'),
          update: value.includes('UPDATE'),
          delete: value.includes('DELETE')
        };
        return ret;
      }));
  }

  private getAbilitazioni(menu: MenuItem[], codFunzione: any): string[] {
    function trovaVoceMenuRicorsiva(voci: any): any {
      if (voci) {
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < voci.length; i++) {
          const voce: any = voci[i];

          if (voce.codiceFunzionePrincipale === codFunzione) {
            return voce;
          } else {
            const sottoVoce = trovaVoceMenuRicorsiva(voce.sottovoci);
            if (sottoVoce != null) {
              return sottoVoce;
            }
          }
        }
      }
      return null;
    }

    if (menu) {
      const voceMenu: any = trovaVoceMenuRicorsiva(menu);
      if (voceMenu) {
        return voceMenu.abilitazioni;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }
}

export type UserSubFunctionGrants = {
  select: boolean,
  insert: boolean,
  update: boolean,
  delete: boolean
};

