import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {GetMenuRequestBean, MenuItem, ProfileControllerService} from 'src/app/api';


@Injectable()
export class MenuService {
  static MENU_KEY = 'menu';

  constructor(public jfApi: ProfileControllerService) {
  }

  public getMenuItems(): Observable<MenuItem[]> {

    const jsonMenuItems: any = localStorage.getItem(MenuService.MENU_KEY);
    const items = JSON.parse(jsonMenuItems);

    if (jsonMenuItems && items && items.voci) {
      return of(items.voci);
    } else {
      const req: GetMenuRequestBean = {idMenu: 1};
      return this.jfApi.getMenuUsingPOST(req).pipe(
        map(menu => {
          localStorage.setItem(MenuService.MENU_KEY, JSON.stringify(menu));
          return menu.voci || [];
        }));
    }
  }
}
