import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  CreaUnitaOperativaRequest,
  EliminaUnitaOperativaRequest,
  GetListaGruppiUORequest,
  GetListaUnitaOperativeRequest,
  GetUnitaOperativaRequest,
  GruppoUnitaOperativaSearch,
  ModificaUnitaOperativaRequest,
  UnitaOperativa,
  UnitaOperativeControllerService
} from '../../../api';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {ErrorService} from '../../../shared/core/error.service';

@Injectable({providedIn: 'root'})
export class UnitaOperativaService extends CrudDatasource<UnitaOperativa, UnitaOperativa, GruppoUnitaOperativaSearch> {

  constructor(private service: UnitaOperativeControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  callLoad(searchRequest: GruppoUnitaOperativaSearch): Observable<UnitaOperativa[] | undefined> {
    const req: GetListaGruppiUORequest = {
      parametriRicerca: searchRequest
    };
    return this.service.getListaUnitaOperativeUsingPOST(req).pipe(
      map(res => res.listaUnitaOperativa)
    );
  }


  callGetByKey(key: any): Observable<UnitaOperativa | undefined> {
    const req: GetUnitaOperativaRequest = {
      idUO: key
    };
    return this.service.getUnitaOperativaUsingPOST(req).pipe(
      map(res => res.unitaOperativa)
    );
  }

  callInsert(values: UnitaOperativa): Observable<UnitaOperativa | undefined> {
    const req: CreaUnitaOperativaRequest = {
      unitaOperativa: values
    };
    return this.service.creaUnitaOperativaUsingPOST(req).pipe(
      map(res => res.unitaOperativa)
    );
  }

  callDelete(values: UnitaOperativa): Observable<any> {
    const req: EliminaUnitaOperativaRequest = {
      unitaOperativa: values
    };
    return this.service.eliminaUnitaOperativaUsingPOST(req);
  }

  callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaUnitaOperativeRequest = {};
    return this.service.getListaUnitaOperativeUsingPOST(req).pipe(
      map(res => res.listaUnitaOperativa?.length || 0)
    );
  }

  callUpdate(key: any, values: UnitaOperativa): Observable<UnitaOperativa | undefined> {
    // FIXME gestire update PK
    const req: ModificaUnitaOperativaRequest = {
      unitaOperativa: values
    };
    return this.service.modificaUnitaOperativaUsingPOST(req).pipe(
      map(res => res.unitaOperativa)
    );
  }

  getKeyField(): string {
    return 'id';
  }
}
