import {AfterViewInit, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {SegnalazioneDetail} from '../segnalazione/segnalazione.service';
import {forkJoin, Subject} from 'rxjs';

@Component({
  selector: 'app-segnalazione-print',
  templateUrl: './segnalazione-print.component.html',
  styleUrls: ['./segnalazione-print.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SegnalazionePrintComponent implements AfterViewInit {
  @Input() segnalazioneDetail: SegnalazioneDetail | null = null;
  @Output() printReady: EventEmitter<boolean> = new EventEmitter<boolean>();

  private $fotoLoaded: Subject<boolean> = new Subject<boolean>();
  private $firmaLoaded: Subject<boolean> = new Subject<boolean>();

  constructor() {
  }

  firmaLoaded() {
    this.$firmaLoaded.next(true);
    this.$firmaLoaded.complete();
  }

  fotoLoaded() {
    this.$fotoLoaded.next(true);
    this.$fotoLoaded.complete();
  }

  ngAfterViewInit(): void {
    forkJoin([this.$fotoLoaded, this.$firmaLoaded]).subscribe(value => {
      window.print();
      setTimeout(() => {
          this.printReady.emit(true);
        }, 100
      )

    });

    if (this.segnalazioneDetail?.segnalazione.foto == null) {
      this.$fotoLoaded.next(false);
      this.$fotoLoaded.complete();
    }

    if (this.segnalazioneDetail?.segnalazione.firma == null) {
      this.$firmaLoaded.next(false);
      this.$firmaLoaded.complete();
    }
  }

}

