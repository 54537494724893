import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-toolbar-detail',
  templateUrl: './toolbar-detail.component.html',
  styleUrls: ['./toolbar-detail.component.scss']
})
export class ToolbarDetailComponent implements OnInit {

  @Input() readOnly = false;
  @Input() deleting = false;
  @Input() locked = false;
  @Input() insertEnabled = false;
  @Input() deleteEnabled = false;
  @Input() updateEnabled = false;
  @Input() resetEnabled = false;
  @Input() validateEnabled = false;
  @Input() function1Enabled = false;
  @Input() function2Enabled = false;
  @Input() customEditFunctionEnabled = false;
  @Input() textBtn1 = '';
  @Input() textBtn2 = '';
  @Input() textCustomEditBtn = '';
  @Input() iconBtn1 = '';
  @Input() iconBtn2 = '';
  @Input() iconCustomEditBtn = '';

  @Output() addRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() editRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() openRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() cloneRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteRow: EventEmitter<any> = new EventEmitter<any>();
  @Output() save: EventEmitter<any> = new EventEmitter<any>();
  @Output() confirmDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() cancel: EventEmitter<any> = new EventEmitter<any>();
  @Output() print: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() saveDraft: EventEmitter<any> = new EventEmitter<any>();
  @Output() resetDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() validateDetail: EventEmitter<any> = new EventEmitter<any>();
  @Output() function1: EventEmitter<any> = new EventEmitter<any>();
  @Output() function2: EventEmitter<any> = new EventEmitter<any>();
  @Output() customEditFunction: EventEmitter<any> = new EventEmitter<any>();

  showAddRow = false;
  showEditRow = false;
  showOpenRow = false;
  showCloneRow = false;
  showDeleteRow = false;
  showConfirmDeleteRow = false;
  showSave = false;
  showCancel = false;
  showClose = false;
  showPrint = false;
  showSaveDraft = false;
  showReset = false;
  showValidate = false;
  showBtn1 = false;
  showBtn2 = false;
  showCustomEditBtn = false;

  constructor() { }

  ngOnInit(): void {
    this.showAddRow = this.addRow.observers.length > 0;
    this.showEditRow = this.editRow.observers.length > 0;
    this.showOpenRow = this.openRow.observers.length > 0;
    this.showCloneRow = this.cloneRow.observers.length > 0;
    this.showDeleteRow = this.deleteRow.observers.length > 0;
    this.showConfirmDeleteRow = this.confirmDelete.observers.length > 0;
    this.showSave = this.save.observers.length > 0;
    this.showCancel = this.cancel.observers.length > 0;
    this.showClose = this.closeDetail.observers.length > 0;
    this.showPrint = this.print.observers.length > 0;
    this.showSaveDraft = this.saveDraft.observers.length > 0;
    this.showReset = this.resetDetail.observers.length > 0;
    this.showValidate = this.validateDetail.observers.length > 0;
    this.showBtn1 = this.function1.observers.length > 0;
    this.showBtn2 = this.function2.observers.length > 0;
    this.showCustomEditBtn = this.customEditFunction.observers.length > 0;
  }

}
