import {Component, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {MenuItem} from '../api';
import {MenuService} from './core/menu.service';
import {QlikService} from './core/qlik.service';
import {ToastrService} from 'ngx-toastr';
import {TranslatePipe} from '@ngx-translate/core';


@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {

  menuItems: MenuItem[] = [];

  constructor(public menu: MenuService,
              private qlikService: QlikService,
              private toastrService: ToastrService,
              private translate: TranslatePipe
  ) {
  }

  ngOnInit(): void {
    this.menu.getMenuItems().pipe(
      map((menu) => {
        this.menuItems = menu;
      })
    ).subscribe();
  }

  public onAccediQLikSense(): void {
    this.qlikService.openQlikConsole()
      .subscribe(value => {
          console.log("Apertura Qlik console");
          this.toastrService.info(this.translate.transform("QLIK.OPEN_SUCCESS"));
      }, error => {
        console.error(error);
        this.toastrService.error(this.translate.transform("QLIK.OPEN_FAILED"));
      });
  }
}
