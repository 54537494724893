import {NgModule} from '@angular/core';
import {PublicRoutingModule} from './public-routing.module';
import {LoginComponent} from './login/login.component';
import {PublicContainerComponent} from './public-container.component';
import {SharedModule} from '../shared/shared.module';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

@NgModule({
    imports: [
        PublicRoutingModule,
        SharedModule,
        FormsModule,
        CommonModule
    ],
    exports: [
        PublicContainerComponent
    ],
    declarations: [
        PublicContainerComponent,
        LoginComponent,
    ]
})
export class PublicModule {
}
