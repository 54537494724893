<div class="home-container">
  <div class="bg-container">
    <img class="escape-bg">
  </div>

  <!-- LINK PLAY STORE APP ANDROID -->
  <div class="app-container">
    <a href="https://play.google.com/store/apps/details?id=it.es.escs" target="_blank">
      <img class="play-store-button">
    </a>
  </div>
</div>
