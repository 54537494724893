import {Injectable} from '@angular/core';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {
  ElementoControlloContratto,
  ElementoControlloContrattoControllerService,
  ElementoControlloContrattoRequest,
  ElementoControlloContrattoSearch,
  EliminaElementoControlloContrattoRequest,
  GetElementoControlloContrattoRequest,
  GetListaElementoControlloContrattoRequest,
  ModificaElementoControlloContrattoRequest, SovrascriviListaElementoControlloContrattoRequest,
} from '../../../api';
import {ErrorService} from '../../../shared/core/error.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ElemcontrolloContrattiService extends CrudDatasource<ElementoControlloContratto, ElementoControlloContratto, ElementoControlloContrattoSearch> {

  constructor(private service: ElementoControlloContrattoControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  callLoad(searchRequest: ElementoControlloContrattoSearch): Observable<ElementoControlloContratto[] | undefined> {
    if (searchRequest) {
      const req: GetListaElementoControlloContrattoRequest = {
        parametriRicerca: searchRequest
      };
      return this.service.getListaElementoControlloContrattoUsingPOST(req).pipe(
        map(res => res.elementoControlloContratto)
      );
    } else {
      return of([]);
    }
  }


  callGetByKey(key: any): Observable<ElementoControlloContratto | undefined> {
    const req: GetElementoControlloContrattoRequest = {
      idElementoControlloContratto: key
    };
    return this.service.getElementoControlloContrattoUsingPOST(req).pipe(
      map(res => res.elementoControlloContratto)
    );
  }

  callInsert(values: ElementoControlloContratto): Observable<ElementoControlloContratto | undefined> {
    const req: ElementoControlloContrattoRequest = {
      elementoControlloContratto: values
    };
    return this.service.creaElementoControlloContrattoUsingPOST(req).pipe(
      map(res => res.elementoControlloContratto)
    );
  }

  callDelete(values: ElementoControlloContratto): Observable<any> {
    const req: EliminaElementoControlloContrattoRequest = {
      elementoControlloContratto: values
    };
    return this.service.eliminaElementoControlloContrattoUsingPOST(req);
  }

  callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaElementoControlloContrattoRequest = {};
    return this.service.getListaElementoControlloContrattoUsingPOST(req).pipe(
      map(res => res.elementoControlloContratto?.length || 0)
    );
  }

  callUpdate(key: any, values: ElementoControlloContratto): Observable<ElementoControlloContratto | undefined> {
    // FIXME gestire update PK
    const req: ModificaElementoControlloContrattoRequest = {
      elementoControlloContratto: values
    };
    return this.service.modificaElementoControlloContrattoUsingPOST(req).pipe(
      map(res => res.elementoControlloContratto)
    );
  }

  getKeyField(): string {
    return 'id';
  }

  public overwriteAll(values: ElementoControlloContratto[], idContratto: number): Observable<ElementoControlloContratto[] | undefined> {
    this.reset();
    return this.callOverwriteAll(values, idContratto)
      .pipe(
        this.refreshAndErrorHandling()
      );
  }

  callOverwriteAll(values: ElementoControlloContratto[], idContratto: number): Observable<ElementoControlloContratto[] | undefined> {
    const req: SovrascriviListaElementoControlloContrattoRequest = {
      listaElementoControlloContratto: values,
      idContratto
    };
    return this.service.sovrascriviListaElementoControlloContrattoUsingPOST(req).pipe(
      map(res => res.listaElementoControlloContratto)
    );
  }
}
