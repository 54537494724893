<div class="abilizationi-segnalazione-container">
  <app-serclicon-search (search)="handleSearch($event)" (servizioDetailChanged)="servizioChanged($event)"></app-serclicon-search>
  <div class="text-center">

    <kendo-buttongroup selection="single">
      <button kendoButton [toggleable]="true" [selected]="viewMode == 'utente'"
              (click)="viewMode = 'utente'">{{'ABILITAZIONI_SEGNALAZIONE.RAGGRUPPA_PER_UTENTE' | translate}}</button>
      <button kendoButton [toggleable]="true" [selected]="viewMode == 'unitaOperativa'"
              (click)="viewMode = 'unitaOperativa'">{{'ABILITAZIONI_SEGNALAZIONE.RAGGRUPPA_PER_UNITA_OPERATIVA' | translate}}</button>
    </kendo-buttongroup>

  </div>

  <div class="text-right">
    <button kendoButton [primary]="true"
            (click)="nuovaAbilitazione()">{{'ABILITAZIONI_SEGNALAZIONE.INSERISCI_ABILITAZIONE' | translate}}</button>
  </div>

  <!--  PLACEHOLDER-->
  <div *ngIf="treeUtenti.size == 0 && treeGruppi.size == 0" class="abilitazioni-placeholder-container">
    <div
      class="abilitazioni-placeholder-title">{{'ABILITAZIONI_SEGNALAZIONE.ABILITAZIONI_PLACEHOLDER_TITLE' | translate}}</div>
    <div
      class="abilitazioni-placeholder-subtitle">{{'ABILITAZIONI_SEGNALAZIONE.ABILITAZIONI_PLACEHOLDER_SUBTITLE' | translate}}</div>
  </div>
  <div *ngFor="let entry of ((viewMode && viewMode === 'utente') ? treeUtenti : treeGruppi) | keyvalue"
       class="first-level-card">
    <div class="first-level-title">{{entry.value.desc}}</div>

    <div *ngFor="let b of entry.value.map | keyvalue" class="second-level-card pl-5">
      <div>
        <div
          class="second-level-title">{{(viewMode && viewMode === 'utente') ? b.value[0].descGruppo : b.value[0].descUtente}}</div>
        <div class="action-buttons-container">
          <button kendoButton [primary]="true"
                  (click)="editGroup(b.value)">{{'ABILITAZIONI_SEGNALAZIONE.MODIFICA_ABILITAZIONE' | translate}}</button>
          <button kendoButton [primary]="true"
                  (click)="removeGroup(b.value)">{{'ABILITAZIONI_SEGNALAZIONE.CANCELLA_ABILITAZIONE' | translate}}</button>
        </div>
      </div>

      <!--MODIFICA ABILITAZIONE DIALOG -->
      <div class="modal" tabindex="-1" role="dialog" id="noteModal-{{b.value[0].idUtente}}-{{b.value[0].idGruppo}}" style="z-index: 10">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Modifica Utente {{entry.value.desc}}, Gruppo {{b.value[0].descGruppo}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal(true)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="permission-row pl-5"><label class="col-1">Utente</label>
                <kendo-combobox class="col-11"
                                [data]="listaUtenti"
                                [textField]="'description'"
                                [valueField]="'id'"
                                [valuePrimitive]="true"
                                [(ngModel)]="idUtenteCombo"
                                (valueChange)="handleUtenteChange($event)"
                                [readonly]="readonlyComboUtente"
                                #idUte1Cmb
                                (focusin)="idUte1Cmb.toggle(true)"
                >
                </kendo-combobox>
              </div>
              <div class="permission-row pl-5"><label class="col-1">Gruppo</label>
                <kendo-combobox class="col-11"
                                [data]="listaGruppi"
                                [textField]="'descrizioneGruppo'"
                                [valueField]="'idGruppoUnitaOp'"
                                [valuePrimitive]="true"
                                [(ngModel)]="idGruppoCombo"
                                (valueChange)="handleGruppoChange($event)"
                                [readonly]="readonlyComboGruppo"
                                #idGrp1Cmb
                                (focusin)="idGrp1Cmb.toggle(true)"
                >
                </kendo-combobox>
              </div>
              <div class="permission-row pl-5">
                <table>
                  <tr>
                    <th class="header-col header-col-stato">
                      Stato
                    </th>
                    <th class="header-col">
                      Attivazione
                    </th>
                    <th class="header-col">
                      Email
                    </th>
                    <th class="header-col">
                      Notifica push
                    </th>
                  </tr>
                  <tr *ngFor="let row of this.selctedItems">
                    <td class="data-col data-col-stato">
                      {{row.descStato}}
                    </td>
                    <td class="data-col">
                      <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.attivazione">
                    </td>
                    <td class="data-col">
                      <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.email">
                    </td>
                    <td class="data-col">
                      <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.push">
                    </td>
                  </tr>

                </table>

              </div>
            </div>
            <div class="modal-footer">
              <button type="button" id="btnCloseModal" class="btn btn-secondary" data-dismiss="modal"
                      (click)="closeModal(true)">Chiudi
              </button>
              <button type="button" id="btnOkModal" class="btn btn-primary" (click)="saveModal()">Salva</button>
            </div>
          </div>
        </div>
      </div>

      <div class="permission-row pl-5">
        <table>
          <tr>
            <th class="header-col header-col-stato">
              Stato
            </th>
            <th class="header-col">
              Attivazione
            </th>
            <th class="header-col">
              Email
            </th>
            <th class="header-col">
              Notifica push
            </th>
          </tr>
          <tr *ngFor="let row of b.value">
            <td class="data-col data-col-stato">
              {{row.descStato}}
            </td>
            <td class="data-col">
              <input class="form-check-input" type="checkbox" value="" [disabled]="true" [ngModel]="row.attivazione">
            </td>
            <td class="data-col">
              <input class="form-check-input" type="checkbox" value="" [disabled]="true" [ngModel]="row.email">
            </td>
            <td class="data-col">
              <input class="form-check-input" type="checkbox" value="" [disabled]="true" [ngModel]="row.push">
            </td>
          </tr>

        </table>

      </div>
    </div>

  </div>


  <!--NUOVA ABILITAZIONE DIALOG-->
  <div class="modal" tabindex="-1" role="dialog" id="noteModal-nuova" style="z-index: 10">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Nuova Abilitazione</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                  (click)="chiudiModalNuovaAbilitazione()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="permission-row pl-5">
            <label class="col-1" style="padding-left: 0px">Utente</label>
            <kendo-combobox class="col-11"
                            [data]="listaUtenti"
                            [textField]="'description'"
                            [valueField]="'id'"
                            [valuePrimitive]="true"
                            [(ngModel)]="idUtenteCombo"
                            (valueChange)="handleUtenteChange($event)"
                            [readonly]="readonlyComboUtente"
                            #idUte2Cmb
                            (focusin)="idUte2Cmb.toggle(true)"
            >
            </kendo-combobox>
          </div>
          <div class="permission-row pl-5"><label class="col-1" style="padding-left: 0px">Gruppo</label>
            <kendo-combobox class="col-11"
                            [data]="listaGruppi"
                            [textField]="'descrizioneGruppo'"
                            [valueField]="'idGruppoUnitaOp'"
                            [valuePrimitive]="true"
                            [(ngModel)]="idGruppoCombo"
                            (valueChange)="handleGruppoChange($event)"
                            [readonly]="readonlyComboGruppo"
                            #idGrp2Cmb
                            (focusin)="idGrp2Cmb.toggle(true)"
            >
            </kendo-combobox>
          </div>
          <div class="permission-row pl-5">
            <div>
              <table *ngIf="selctedItems != null && selctedItems.length > 0">
                <tr>
                  <th class="header-col header-col-stato">
                    Stato
                  </th>
                  <th class="header-col">
                    Attivazione
                  </th>
                  <th class="header-col">
                    Email
                  </th>
                  <th class="header-col">
                    Notifica push
                  </th>
                </tr>
                <tr *ngFor="let row of this.selctedItems">
                  <td class="data-col data-col-stato">
                    {{row.descStato}}
                  </td>
                  <td class="data-col">
                    <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.attivazione">
                  </td>
                  <td class="data-col">
                    <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.email">
                  </td>
                  <td class="data-col">
                    <input class="form-check-input" type="checkbox" value="" [(ngModel)]="row.push">
                  </td>
                </tr>

              </table>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" id="btnCloseModalNuova" class="btn btn-secondary" data-dismiss="modal"
                  (click)="chiudiModalNuovaAbilitazione()">Chiudi
          </button>
          <button type="button" id="btnOkModalNuova" class="btn btn-primary" (click)="inserisciAbilitazioni()">Salva
          </button>
        </div>

      </div>
    </div>
  </div>

</div>
