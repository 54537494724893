import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {IframeGuard} from '../shared/core/iframe.guard';
import {HomeComponent} from './home/home.component';
import {ShowIframeComponent} from './show-iframe/show-iframe.component';

/*
 *  Main routes
 *
 */
const routes: Routes = [
  {
    path: '',
    data: {
      title: 'Home'
    },
   component: HomeComponent
  }, {
    path: '',
    children: [
      {
        path: '',
        loadChildren: './tabelle/tabelle.module#TabelleModule'
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: '',
        loadChildren: './segnalazioni/segnalazioni.module#SegnalazioniModule'
      },
    ]
  }, {
    path: '**',
    canActivate: [
      IframeGuard
    ],
    component: ShowIframeComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule {
}
