import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BaseComponent} from './shared/core/base-component';
import {setTheme} from 'ngx-bootstrap/utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent extends BaseComponent implements OnInit {

  constructor(private translate: TranslateService) {
    super();

    const supportedLanguages = ['it'];

    translate.addLangs(supportedLanguages);
    translate.setDefaultLang(supportedLanguages[0]);

    setTheme('bs4'); // or 'bs4'
  }

  ngOnInit(): void {
  }

}
