<div>
  <ng-container *ngIf="!readOnly && !isLoading">
    <!--  ADD BUTTON-->
    <button kendoButton [primary]="false" *ngIf="!imageData && !imageUrl" iconClass="pi pi-plus"
            (click)="fileId.click();">{{selectFileLabel}}</button>
    <input #fileId type="file" accept=".png, .jpg, .jpeg" style="display:none;" name="file"
           (change)="handleFileInput($event)" (change)="scrollToImage()">

    <!--  REMOVE BUTTON-->
    <button kendoButton [primary]="false" *ngIf="imageData || imageUrl" iconClass="pi pi-trash"
            (click)="removeFile(); fileId.value = ''">{{removeFileLabel}}</button>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <i class="pi pi-spin pi-spinner"></i>
  </ng-container>

  <!--  IMAGE-->
  <div #target class="img-container" *ngIf="!imageData || !imageUrl">
    <img class="img img-small" [src]="imageData || imageUrl" (click)="showPreviewInDialog(template)">
  </div>
</div>

<!--MODAL-->
<ng-template #template>
  <div class="modal-header">
    <h5>Anteprima</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body img-container">
    <img class="img img-full" [src]="imageData || imageUrl">
  </div>
</ng-template>

