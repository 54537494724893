<div class="print-container w-100 h-100">

  <div class="row">

    <div class="col-12">
      <div class="header">
        <img class="escape-logo" src="assets/img/logo_complete.png">
        <div class="title">{{'SEGNALAZIONE_DETAIL.SEGNALAZIONE' | translate}}: {{segnalazioneDetail?.segnalazione?.codiceSegnalazione}}</div>
      </div>
    </div>

    <!--  DETAIL-->
    <div class="col-8">

      <h6>{{'SEGNALAZIONE_DETAIL.DETTAGLIO' | translate}}</h6>

      <ng-container *ngIf="segnalazioneDetail != null && segnalazioneDetail.segnalazione != null">
        <div class="row">
          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.UNITA_OPERATIVA' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.descrizione_unitaOperativa}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.CENTRO_DI_COSTO' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.centroCosto}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.TELEFONO' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.telefono}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.OGGETTO' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.oggettoSegnalazione}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.ARTICOLO' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.articolo?.descrizione}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.NUM_UNITA_COINVOLTE' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.numUnitaCoinvolte}}</div>
          </div>

          <div class="col-4">
            <label>{{'SEGNALAZIONE_DETAIL.URGENTE' | translate}}</label>
            <div class="text-value">{{segnalazioneDetail.segnalazione.flagUrgenza ? 'Sì' : 'No'}}</div>
          </div>

          <div class="col-8">
            <label>{{'SEGNALAZIONE_DETAIL.NOTE' | translate}}</label>
            <div class="">{{segnalazioneDetail.segnalazione.descrizione}}</div>
          </div>

          <div class="col-12">
            <h6>{{'SEGNALAZIONE_DETAIL.CRITICITA_RISCONTRATE' | translate}}</h6>
            <div *ngFor="let crit of segnalazioneDetail.listaCriticita; let i = index"
                 class="criticita-element"
                 [class.even]="i % 2 === 0"
                 [class.odd]="i % 2 !== 0">
              <div class="row">
                <div class="col-6">
                  <label>{{'SEGNALAZIONE_DETAIL.CRITICITA' | translate}}</label>
                  <div>{{crit.criticitaContratto?.descrizione}}</div>
                </div>
                <div class="col-3">
                  <label>{{'SEGNALAZIONE_DETAIL.VALORE_1' | translate}}</label>
                  <div>{{crit.valore1}}</div>
                </div>
                <div class="col-3">
                  <label>{{'SEGNALAZIONE_DETAIL.VALORE_2' | translate}}</label>
                  <div>{{crit.valore2}}</div>
                </div>
              </div>
            </div>
          </div>

          <!--          ALLEGATI-->
          <div class="col-12">
            <ng-container *ngIf="segnalazioneDetail.segnalazione.foto != null">
              <h6>{{'SEGNALAZIONE_DETAIL.FOTO_ALLEGATA' | translate}}</h6>
              <div class="img-container">
                <img class="img" [src]="segnalazioneDetail.segnalazione.foto" (load)="fotoLoaded()"
                     (error)="fotoLoaded()">
              </div>
            </ng-container>

            <ng-container *ngIf="segnalazioneDetail.segnalazione.firma != null">
              <h6>{{'SEGNALAZIONE_DETAIL.FIRMA_ALLEGATA' | translate}}</h6>
              <div class="img-container">
                <img class="img firma" [src]="segnalazioneDetail.segnalazione.firma" (load)="firmaLoaded()"
                     (error)="fotoLoaded()">
              </div>
            </ng-container>
          </div>

        </div>

      </ng-container>
    </div>

    <!--  STORIA-->
    <div class="col-4">
      <h6>{{'SEGNALAZIONE_DETAIL.STORIA' | translate}}</h6>

      <ng-container *ngIf="segnalazioneDetail != null && segnalazioneDetail.segnalazione != null">

        <div class="placeholder-storia" *ngIf="segnalazioneDetail.storicoSegnalazione.length < 1">
          {{'SEGNALAZIONE_DETAIL.STORIA_PLACEHOLDER' | translate}}
        </div>

        <div *ngIf="segnalazioneDetail.segnalazione.esito != null" class="esito-container">
          <label>{{'SEGNALAZIONE_DETAIL.ESITO' | translate}}</label>
          <div *ngIf="segnalazioneDetail.segnalazione.esito == 'OK'" class="esito esito-success">
            <i class="fa fa-thumbs-up"></i>
          </div>
          <div *ngIf="segnalazioneDetail.segnalazione.esito == 'KO'" class="esito esito-error">
            <i class="fa fa-thumbs-down"></i>
          </div>
        </div>

        <div class="detail-data-container">
          <div *ngFor="let stato of segnalazioneDetail.storicoSegnalazione">
            <div class="stato-card">
              <div class="d-flex">

                <div class="stato-color-band" [ngClass]="'stato-color-band-' + stato.codStato"></div>

                <div class="stato-data-section">
                  <div class="stato-title">
                    {{stato.descrStato}}
                  </div>
                  <div class="data-row" *ngFor="let nota of stato.listaNote">
                    <div class="d-flex">
                      <div class="user">
                        {{nota.userLabel}}
                      </div>
                      <div class="date">
                        {{nota.data | date: 'dd/MM/yyyy - HH:mm:ss'}}
                      </div>
                    </div>
                    <div class="nota">
                      {{nota.nota}}
                    </div>
                  </div>

                  <div class="data-row"
                       *ngIf="stato.listaNote == null || stato.listaNote.length < 1">
                    <div class="d-flex">
                      <div class="user">
                        {{stato.userLabel}}
                      </div>
                      <div class="date">
                        {{stato.data | date: 'dd/MM/yyyy - HH:mm:ss'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </ng-container>
    </div>

  </div>
</div>
