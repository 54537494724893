import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CrudDetailComponent, CrudRecordVersioning} from 'src/app/shared/crud/crud-detail.component';
import {
  ArticoloContratto,
  ArticoloContrattoControllerService,
  Criticita,
  CriticitaContratto,
  CriticitaContrattoControllerService,
  EsitoSegnalazione,
  GetListaArticoloContrattoRequest,
  GetListaArticoloContrattoResponse,
  GetListaCriticitaContrattoRequest,
  GetListaCriticitaContrattoResponse,
  GetListaLookUpUnitaOperativeResponse,
  GetListaUnitaOperativeRequest,
  Segnalazione,
  SegnalazioneSearch,
  SegnalazioniControllerService,
  UnitaOperativa,
  UnitaOperativeControllerService,
  UnitaOperativeSearch,
  UtenteGruppoUO
} from '../../../api';
import {GrantsService} from '../../../shared/core/grants.service';
import {SegnalazioneDetail, SegnalazioneService} from './segnalazione.service';
import {combineLatest, Observable, of} from 'rxjs';
import {StatoSgn} from './stato-sgn';
import {ImageSelectorComponent} from '../../../shared/image-selector/image-selector.component';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {PrintService} from '../../../shared/core/print.service';
import {deepCopy} from '../../../shared/crud/deep-copy';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-segnalazione-detail',
  templateUrl: './segnalazione-detail.component.html',
  styleUrls: ['./segnalazione-detail.component.scss']
})
export class SegnalazioneDetailComponent extends CrudDetailComponent<SegnalazioneDetail, Segnalazione, SegnalazioneSearch> implements OnInit, AfterViewInit {
  @Input() idSerCliCon: number | undefined;

  @ViewChild('fotoComp', {static: false}) fotoComp?: ImageSelectorComponent;
  @ViewChild('firmaComp', {static: false}) firmaComp?: ImageSelectorComponent;

  private IMAGES_PATH: string = '/api/downloadAllegatoSegnalazione/';

  unitaOperativeLookup?: UnitaOperativa[];
  unitaOperativeLookupFiltered?: UnitaOperativa[];
  unitaSelected?: UnitaOperativa;
  articoliLookupList?: ArticoloContratto[] = [];
  criticitaLookupList?: CriticitaContratto[] = [];

  modificaEnabled = false;
  eliminaEnabled = false;
  avanzaStatoEnabled = false;
  aggiungiNotaEnabled = false;
  permissionSet = new Set<string>();
  avanzaStatoLabel = '';
  isSegnalazioneModificata = false;
  isAvanzaStato = false;
  esito?: EsitoSegnalazione;

  bsModalRef?: BsModalRef;

  constructor(grantsService: GrantsService,
              private unitaOperativeService: UnitaOperativeControllerService,
              private segnalazioneService: SegnalazioneService,
              private segnalazioniControllerService: SegnalazioniControllerService,
              private articoliContrattControllerService: ArticoloContrattoControllerService,
              private criticitaContrattoControllerService: CriticitaContrattoControllerService,
              private printService: PrintService,
              private toastrService: ToastrService) {
    super(grantsService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    const search: GetListaUnitaOperativeRequest = {};
    const parametriRicerca: UnitaOperativeSearch = {};
    // @ts-ignore
    search.parametriRicerca = parametriRicerca;
    parametriRicerca.idSerCliCon = this.idSerCliCon;

    const segnalazione$ = this.data.id < 0 ? of({} as SegnalazioneDetail) : this.loadObservable;

    combineLatest([
      segnalazione$,
      this.loadUnitaOperative(search),
      this.loadArticoli({parametriRicerca: {idSerCliCon: this.idSerCliCon}}),
      this.loadCriticita({parametriRicerca: {idSerCliCon: this.idSerCliCon}}),
    ]).subscribe(([segn, uoList, art, crit]) => {
      this.unitaOperativeLookup = uoList.unitaOperativeLookUp;
      if (segn.segnalazione?.id_unitaOperativa != null) {
        this.unitaSelected = this.unitaOperativeLookup?.filter(value1 => value1.id === this.data.segnalazione.id_unitaOperativa)[0];
      }
      if (segn.autorizzazioni) {
        this.createPermissionSet(segn.autorizzazioni);
      } else if (this.data.autorizzazioni) {
        this.createPermissionSet(this.data.autorizzazioni);
      } else {
        this.createPermissionSet([]);
      }

      this.gestisciAbilitazioniPerSegnalazione();
      this.unitaOperativeLookupFiltered = this.filtraListaUnitaOperative();


      this.articoliLookupList = art.listaArticoloContratto;
      this.criticitaLookupList = crit.listaCriticitaContratto;
    }, error => {
      this.toastrService.error(error.toString());
      this.closeTab();
    });
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.loadObservable.subscribe(value => {
      // Immagini (timeout workaround)
      // Reset blob locali immagini, per evitare un doppio upload in caso di modifica della solo segnalazione
      this.data.foto = undefined;
      this.data.firma = undefined;

      // Se presenti nella segnalazione faccio caricare da server le immagini
      const basePath = this.segnalazioniControllerService.configuration.basePath;
      if (value.segnalazione.foto) {
        this.fotoComp?.setImage(basePath + this.IMAGES_PATH + value.segnalazione.foto);
      }
      if (value.segnalazione.firma) {
        this.firmaComp?.setImage(basePath + this.IMAGES_PATH + value.segnalazione.firma);
      }
    });
  }

  loadUnitaOperative(parametriRicerca: GetListaUnitaOperativeRequest): Observable<GetListaLookUpUnitaOperativeResponse> {
    return this.unitaOperativeService.getListaLookUpUnitaOperativeUsingPOST(parametriRicerca)
  }

  loadArticoli(parametriRicerca: GetListaArticoloContrattoRequest): Observable<GetListaArticoloContrattoResponse> {
    return this.articoliContrattControllerService.getListaArticoloContrattoLookupUsingPOST(parametriRicerca);
  }

  loadCriticita(parametriRicerca: GetListaCriticitaContrattoRequest): Observable<GetListaCriticitaContrattoResponse> {
    return this.criticitaContrattoControllerService.getListaCriticitaContrattoLookupUsingPOST(parametriRicerca);
  }

  getFunctionCode(): string {
    return 'SGN';
  }

  avanzaStato(): void {
    if (this.data.segnalazione.stato === StatoSgn.APER) {
      this.data.segnalazione.stato = StatoSgn.PCAR;
    } else if (this.data.segnalazione.stato === StatoSgn.PCAR) {
      this.data.segnalazione.stato = StatoSgn.CFOR;
    } else {
      this.data.segnalazione.stato = StatoSgn.CCLI;
    }
    this.isSegnalazioneModificata = true;
    this.aggiungiNotaStato(true);
  }

  aggiungiNotaStato(fromAvanzaStato = false): void {
    this.isAvanzaStato = fromAvanzaStato;
    const modal = document.getElementById('noteModal-' + this.data.id);
    if (modal) {
      modal.style.display = 'block';
    }
  }

  closeModal(annulla: boolean): void {
    if (annulla) {
      this.data.nota = '';
      this.data.segnalazione.stato = this.clonedData?.segnalazione.stato;
    }
    const modal = document.getElementById('noteModal-' + this.data.id);
    if (modal) {
      modal.style.display = 'none';
    }

    if (!annulla && (this.isSegnalazioneModificata && this.isAvanzaStato) || (!this.isAvanzaStato && this.data.nota && this.data.nota.trim().length !== 0)) {
      this.updateStatoOnota()
    } else {
      // Se si preme annulla sul dialogo della nota non si aggiorna la segnalazione
      this.isSegnalazioneModificata = false;
    }
    this.isAvanzaStato = false;
    this.esito = undefined;
  }

  private updateStatoOnota(): void {
    // Update SOLO stato e/o nota segnalazione
    this.isLoading = true;
    this.readOnly = true;

    this.segnalazioniControllerService.modificaStatoSegnalazioneUsingPOST({
      idSegnalazione: this.data.segnalazione.id,
      stato: this.data.segnalazione.stato,
      nota: this.data.nota,
      esito: this.esito
    }).subscribe(value => {
      if (value) {
        if (value.segnalazione) {
          this.data.segnalazione = value.segnalazione;
        }
        if (value.listaCriticita) {
          this.data.listaCriticita = value.listaCriticita;
        }
        if (value.listaStoricoSgn) {
          this.data.storicoSegnalazione = value.listaStoricoSgn;
        }
        this.clonedData = deepCopy(this.data);

        this.gestisciAbilitazioniPerSegnalazione();
        this.unitaOperativeLookupFiltered = this.filtraListaUnitaOperative();
        this.isSegnalazioneModificata = false;

        // Workaround per far refreshare la lista in caso di aggiornamento dello stato
        const toEmit: CrudRecordVersioning<SegnalazioneDetail> = new CrudRecordVersioning(this.data, this.data);
        this.saved.emit(toEmit);
      }
      this.readOnly = true;
    }, error => {
      this.readOnly = false;
      this.revertDetail();
      this.toastrService.error(error.toString());
    }, () => {
      this.data.nota = undefined;
      this.isLoading = false;
    });
  }

  private createPermissionSet(autorizzazioni: Array<UtenteGruppoUO>): void {
    autorizzazioni.forEach(value => {
      if (value.flag_attivazione) {
        this.permissionSet.add(this.getPreviousState(value.cod_stato) + '#' + value.id_gruppoUnitaOp);
      }
    });
  }

  private gestisciAbilitazioniPerSegnalazione(): void {
    // MODIFICA
    this.modificaEnabled = this.userGrants.update && this.data.segnalazione.stato === StatoSgn.APER

    // ELIMINA
    this.eliminaEnabled = this.userGrants.delete;

    // AVANZA STATO
    this.avanzaStatoEnabled = this.data.segnalazione.stato != null && this.data.segnalazione.stato !== StatoSgn.CCLI && this.permissionSet.has(this.getPermissionKey());

    // AGGIUNGI NOTA
    this.aggiungiNotaEnabled = this.data.segnalazione.stato != null && this.permissionSet.has(this.getPreviousState(this.data.segnalazione.stato) + '#' + (this.unitaSelected?.idGruppoUo));

    if (this.data.segnalazione.stato === StatoSgn.APER) {
      this.avanzaStatoLabel = 'Prendi in carico';
    } else if (this.data.segnalazione.stato === StatoSgn.PCAR) {
      this.avanzaStatoLabel = 'Segna completata';
    } else if (this.data.segnalazione.stato === StatoSgn.CFOR) {
      this.avanzaStatoLabel = 'Chiudi Cliente';
    }
  }

  private filtraListaUnitaOperative(): UnitaOperativa[] {
    let uoFilteredList: UnitaOperativa[];
    uoFilteredList = [];
    if (this.unitaOperativeLookup) {
      for (const uo of this.unitaOperativeLookup) {
        if (this.permissionSet.has(this.data.segnalazione.stato + '#' + uo.idGruppoUo)) {
          uoFilteredList.push(uo);
        }
      }
    }
    if (uoFilteredList.length !== 0) {
      return uoFilteredList;
    } else {
      if (this.unitaOperativeLookup) {
        return this.unitaOperativeLookup;
      } else {
        return uoFilteredList;
      }
    }
  }

  private getPermissionKey(): string {
    return this.data.segnalazione.stato + '#' + (this.unitaSelected?.idGruppoUo);
  }

  unitaChange($event: UnitaOperativa): void {
    if ($event && $event.id) {
      this.data.segnalazione.id_unitaOperativa = $event.id;
      this.data.segnalazione.id_serCliCon = $event.idSerCliCon;
    } else {
      this.data.segnalazione.id_unitaOperativa = undefined;
      this.data.segnalazione.id_serCliCon = undefined;
    }
    this.gestisciAbilitazioniPerSegnalazione();
  }

  editDetail(): void {
    super.editDetail();
    if (this.data.listaCriticita == null || this.data.listaCriticita.length < 1) {
      this.aggiungiCriticita();
    }
  }

  private getPreviousState(stato?: string): string | undefined {
    switch (stato) {
      case StatoSgn.CCLI:
        return StatoSgn.CFOR;
      case StatoSgn.PCAR:
        return StatoSgn.APER;
      case StatoSgn.CFOR:
        return StatoSgn.PCAR;
    }
    return '__';
  }

  aggiungiCriticita() {
    if (this.data.listaCriticita == null) {
      this.data.listaCriticita = [];
    }
    this.data.listaCriticita.push({});
  }

  rimuoviCriticita(criticita: Criticita) {
    this.data.listaCriticita = this.data.listaCriticita.filter(value => value != criticita);
  }

  isValid(): boolean {
    if (this.data.listaCriticita.length < 1) {
      return false;
    }

    return super.isValid();
  }

  protected beforeSaveRecord() {
    super.beforeSaveRecord();
    // Aggiungo l'oggetto completo criticitaContratto
    this.data.listaCriticita.forEach(value => {
      value.criticitaContratto = this.criticitaLookupList?.find(value1 => value1.idIndicatore === value.criticitaContratto?.idIndicatore);
    });
  }

  fotoChanged(file: Blob) {
    this.data.foto = file;
  }

  fotoRemoved() {
    this.data.foto = undefined;
    this.data.segnalazione.foto = undefined;
  }

  firmaChanged(file: Blob) {
    this.data.firma = file;
  }

  firmaRemoved() {
    this.data.firma = undefined;
    this.data.segnalazione.firma = undefined;
  }

  revertDetail() {
    const basePath = this.segnalazioniControllerService.configuration.basePath;
    this.fotoComp?.setImage(basePath + this.IMAGES_PATH + this.clonedData?.segnalazione.foto);
    this.firmaComp?.setImage(basePath + this.IMAGES_PATH + this.clonedData?.segnalazione.firma);
    super.revertDetail();
  }

  print() {
    const basePath = this.segnalazioniControllerService.configuration.basePath;
    const segnalazioneToPrint: SegnalazioneDetail = JSON.parse(JSON.stringify(this.data));
    segnalazioneToPrint.segnalazione.descrizione_unitaOperativa = this.unitaSelected?.descrizioneUnita;
    if (this.data.segnalazione.foto != null) {
      segnalazioneToPrint.segnalazione.foto = basePath + this.IMAGES_PATH + this.data.segnalazione.foto;
    }
    if (this.data.segnalazione.firma != null) {
      segnalazioneToPrint.segnalazione.firma = basePath + this.IMAGES_PATH + this.data.segnalazione.firma;
    }
    this.printService.$segnalazionePrint.next(segnalazioneToPrint);
  }
}

