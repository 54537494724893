import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ClienteContrattoLookUp,
  ClientiContrattiControllerService,
  ContrattiControllerService,
  ContrattoLookUp,
  GetListaClientiContrattiRequest,
  GetListaServiziClientiContrattiRequest,
  ServiziClientiContrattiControllerService,
  ServizioClienteContrattoLookUp
} from '../../api';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-serclicon-search',
  templateUrl: './serclicon-search.component.html',
  styleUrls: ['./serclicon-search.component.scss']
})
export class SercliconSearchComponent implements OnInit {

  @Input() idContratto?: number;
  @Input() idCliente?: number;
  @Input() idSerCliCon?: number;

  listaContratti?: ContrattoLookUp[] = [];
  listaContrattiFiltered?: ContrattoLookUp[] = [];
  listaClienti?: ClienteContrattoLookUp[] = [];
  listaClientiFiltered?: ClienteContrattoLookUp[] = [];
  listaServizi?: ServizioClienteContrattoLookUp[] = [];
  listaServiziFiltered?: ServizioClienteContrattoLookUp[] = [];

  @Output() search = new EventEmitter<any>();
  @Output() servizioChanged = new EventEmitter<number>();
  @Output() servizioDetailChanged = new EventEmitter<ServizioClienteContrattoLookUp>();

  constructor(
    public router: Router, public activatedRoute: ActivatedRoute,
    private contrattiService: ContrattiControllerService,
    private clientiService: ClientiContrattiControllerService,
    private serviziService: ServiziClientiContrattiControllerService) {
    this.activatedRoute.queryParams.subscribe(params => {
      const idContratto = params?.ic && +params?.ic;
      const idCliente = params?.icl && +params?.icl;
      const idSerCliCon = params?.iscc && +params?.iscc;
      this.searchContratto(idContratto, idCliente, idSerCliCon);
    });
  }

  ngOnInit(): void {
  }

  public searchContratto(idContratto?: number, idCliente?: number, idSerCliCon?: number): void {
    this.contrattiService.getListaLookUpContrattiUsingPOST().subscribe((lc) => {
      this.listaContratti = lc.contrattiLookUp;
      if (this.listaContratti?.length === 1) {
        this.idContratto = this.listaContratti[0].id;
      } else {
        this.idContratto = idContratto;
      }
      this.listaContrattiFiltered = this.listaContratti;
      this.searchCliente(this.idContratto, idCliente, idSerCliCon);
    });
  }

  public searchCliente(idContratto?: number, idCliente?: number, idSerCliCon?: number): void {
    if (this.idContratto) {
      const req: GetListaClientiContrattiRequest = {
        parametriRicerca: {
          idCon: this.idContratto
        }
      };
      this.clientiService.getListaLookUpClientiContrattiUsingPOST(req).subscribe((lc) => {
        this.listaClienti = lc.clientiContrattiLookUp;
        if (this.listaClienti?.length === 1) {
          this.idCliente = this.listaClienti[0].idCli;
        } else {
          this.idCliente = idContratto;
        }
        this.listaClientiFiltered = this.listaClienti;
        this.searchServizio(idContratto, this.idCliente, idSerCliCon);
      });
    } else {
      this.listaClienti = [];
      this.listaServizi = [];
      this.idCliente = undefined;
      this.idSerCliCon = undefined;
    }
  }

  public searchServizio(idContratto?: number, idCliente?: number, idSerCliCon?: number): void {
    if (this.idCliente && this.idContratto) {
      const req: GetListaServiziClientiContrattiRequest = {
        parametriRicerca: {
          idCli: idCliente,
          idCon: idContratto
        }
      };
      this.serviziService.getListaLookUpServiziClientiContrattiUsingPOST(req)
        .subscribe((ls) => {
          this.listaServizi = ls.serviziClientiContrattiLookUp;
          if (this.listaServizi?.length === 1) {
            this.idSerCliCon = this.listaServizi[0].id;
          } else {
            this.idSerCliCon = idSerCliCon;
          }
          this.emittServizioChanged();
          this.listaServiziFiltered = this.listaServizi;
          this.continueSearch(idContratto, idCliente, this.idSerCliCon);
        });
    } else {
      this.listaServizi = [];
      this.idSerCliCon = undefined;
    }
  }

  private emittServizioChanged(): void {
    this.servizioChanged.emit(this.idSerCliCon);
    const servizio = this.listaServizi?.find(s => s.id === this.idSerCliCon);
    this.servizioDetailChanged.emit(servizio);
  }

  protected continueSearch(idContratto?: number, idCliente?: number, idSerCliCon?: number): void {
    this.handleSearch();
  }

  public handleContrattoChange(e: any): void {
    this.listaClienti = [];
    this.listaServizi = [];
    this.idCliente = undefined;
    this.idSerCliCon = undefined;
    this.searchCliente(this.idContratto, undefined, undefined);
  }

  public handleClienteChange(e: any): void {
    this.listaServizi = [];
    this.idSerCliCon = undefined;
    this.searchServizio(this.idContratto, this.idCliente, undefined);
  }

  public handleServizioChange(e: any): void {
    this.idSerCliCon = e;
    this.emittServizioChanged();
    this.updateQueryParams();
  }

  public handleSearch(): void {
    this.search.emit(this.getSearchParams());
    this.updateQueryParams();
  }

  protected getSearchParams(): any {
    return this.idSerCliCon;
  }

  protected updateQueryParams(): void {
    const queryParams: Params = {
      ic: this.idContratto || '',
      icl: this.idCliente || '',
      iscc: this.idSerCliCon || ''
    };
    this.router.navigate(['./'], {relativeTo: this.activatedRoute, queryParams, queryParamsHandling: 'merge'});
  }

  public handleContrattiFilter(value: any): void {
    this.listaContrattiFiltered = this.listaContratti?.filter(
      (s) => s.numero?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleClienteFilter(value: any): void {
    this.listaClientiFiltered = this.listaClienti?.filter(
      (s) => s.ragSocCliente?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }

  public handleServiziFilter(value: any): void {
    this.listaServiziFiltered = this.listaServizi?.filter(
      (s) => s.descrizioneServizio?.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }
}
