<div class="search-panel row">
  <div class="col-md-10">
    <div class="row">
      <label class="col-2">Contratto</label>
      <kendo-combobox class="col-10"
                      [data]="listaContrattiFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleContrattiFilter($event)"
                      [textField]="'numero'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idContratto"
                      (valueChange)="handleContrattoChange($event)"
                      #idCcontrattoCmb
                      (focusin)="idCcontrattoCmb.toggle(true)"
      >
      </kendo-combobox>
    </div>
    <div class="row">
      <label class="col-2">Cliente</label>
      <kendo-combobox class="col-10"
                      [data]="listaClientiFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleClienteFilter($event)"
                      [textField]="'ragSocCliente'"
                      [valueField]="'idCli'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idCliente"
                      (valueChange)="handleClienteChange($event)"
                      #idClienteCmb
                      (focusin)="idClienteCmb.toggle(true)"
      >
      </kendo-combobox>
    </div>
    <div class="row">
      <label class="col-2">Servizio</label>
      <kendo-combobox class="col-10"
                      [data]="listaServiziFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleServiziFilter($event)"
                      [textField]="'descrizioneServizio'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [ngModel]="idSerCliCon"
                      (ngModelChange)="handleServizioChange($event)"
                      #idServizioCmb
                      (focusin)="idServizioCmb.toggle(true)"
      >
      </kendo-combobox>
    </div>
  </div>
  <div class="col-md-2">
    <button kendoButton [primary]="true" (click)="handleSearch()">Cerca</button>

  </div>
</div>
