import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LoginResponseBean} from '../../api';
import {MenuService} from './menu.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  /*
   * IMPORTANT:
   * key 'userData' must not be changed because the app running angularjs use the same key to retrieve user data and check session.
   */
  static LOCAL_STORAGE_USER_KEY = 'userData';

  constructor(public router: Router) {
  }

  public isLoggedIn(): boolean {
    return !!this.getUser();
  }

  public setUser(user: LoginResponseBean | null): void {
    if (user) {
      localStorage.setItem(AuthService.LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
    } else {
      this.clearLocalStorage();
    }
  }

  public getUser(): LoginResponseBean | null {
    const jsonUser = localStorage.getItem(AuthService.LOCAL_STORAGE_USER_KEY);
    return (jsonUser) ? JSON.parse(jsonUser) : null;
  }

  public goToLogin(): void {
    this.router.navigateByUrl('/').then(value => {
      location.reload();
    });
  }

  public clearLocalStorage(): void {
    localStorage.removeItem(AuthService.LOCAL_STORAGE_USER_KEY);
    localStorage.removeItem(MenuService.MENU_KEY);
  }

}
