import {Injectable} from '@angular/core';
import {empty, Observable} from 'rxjs';
import {
  GetListaStatSegnalazioneRequest,
  GetMediaStatsSegnalazioneRequest,
  StatisticheSegnalazioniControllerService
} from '../../../api';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {map} from 'rxjs/operators';
import {DialogService} from '@progress/kendo-angular-dialog';
import {ErrorService} from '../../../shared/core/error.service';

@Injectable({
  providedIn: 'root'
})
export class StatSegnSegnalazioneService extends CrudDatasource<any, any, any> {

  private tabSelected = '';

  constructor(private service: StatisticheSegnalazioniControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  protected callDelete(values: any): Observable<any> {
    throw new Error('not implemented');
  }

  protected callGetByKey(key: any): Observable<any | undefined> {
    return empty();
  }

  protected callInsert(values: any): Observable<any | undefined> {
    throw new Error('not implemented');
  }

  protected callLoad(searchRequest: any): Observable<any[] | undefined> {
    if (this.tabSelected && (this.tabSelected === 'Segnalazioni' || this.tabSelected === '')) {
      const req: GetListaStatSegnalazioneRequest = {
        search: searchRequest
      };
      return this.service.getListaStatsSegnalazioneUsingPOST(req)
        .pipe(
          map(resp => {
            return resp.listaStats;
          })
        );
    } else if (this.tabSelected && this.tabSelected === 'Analisi Segnalazione') {
      const req: GetMediaStatsSegnalazioneRequest = {
        search: searchRequest
      };
      return this.service.getMediaStatiUsingPOST(req)
        .pipe(
          map(resp => {
            return resp.listaMedie;
          })
        );
    } else if (this.tabSelected && this.tabSelected === 'Analisi Segnalatori') {
      const req: GetListaStatSegnalazioneRequest = {
        search: searchRequest
      };
      return this.service.getMediaSegnalatoriStatsUsingPOST(req)
        .pipe(
          map(resp => {
            return resp.listaMedie;
          })
        );
    } else {
      const req: GetListaStatSegnalazioneRequest = {
        search: searchRequest
      };
      return this.service.getListaStatsSegnalazioneUsingPOST(req)
        .pipe(
          map(resp => {
            return resp.listaStats;
          })
        );
    }

  }

  protected callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaStatSegnalazioneRequest = {
      search: loadOptions
    };
    return this.service.getListaStatsSegnalazioneUsingPOST(req)
      .pipe(
        map(resp => {
          return resp.listaStats?.length || 0;
        })
      );
  }

  protected callUpdate(key: any, values: any): Observable<any | undefined> {
    throw new Error('not implemented');
  }

  getKeyField(): string {
    return 'id';
  }

  setTabSelected(tabSelected: string): void {
    this.tabSelected = tabSelected;
  }
}
