<nav class="sidebar-nav">
  <ul class="nav">
    <ng-template ngFor let-item [ngForOf]="menuItems" let-i="index">

      <!--Questo div viene ignorato, è utilizzato solo per la scelta del template da utilizzare-->
      <ng-container *ngIf="item.tipo !== 'NODO_HIDDEN'">
        <div *ngIf="item.sottovoci && item.sottovoci.length <= 0; then singleItem; else itemWithSubItem"></div>

          <ng-template #singleItem>
            <li class="nav-item">
              <a class="nav-link" routerLinkActive="active" [routerLink]="[item.url]"><i [ngClass]="item.icona || ''"></i>{{ item.descrizione }}</a>
            </li>
          </ng-template>

          <ng-template #itemWithSubItem>
            <li class="nav-item nav-dropdown" appNavDropdown>
              <a class="nav-link nav-dropdown-toggle" routerLinkActive="active" appNavDropdownToggle><i [ngClass]="item.icona || ''"></i>{{ item.descrizione }}</a>
              <ul class="nav-dropdown-items">
                <li class="nav-item" *ngFor="let subItem of item.sottovoci" ngClass="menuSubItem">
<!--                  ALL SUBITEMS-->
                  <a *ngIf="subItem.url != 'main.accediqlik'" class="nav-link" routerLinkActive="active" [routerLink]="[subItem.url]" [queryParamsHandling]="'merge'" >
                    <img [src]="'assets/img/svg/' + subItem.icona || ''" onerror="this.style.display='none'">
                    <i [ngClass]="subItem.icona || ''"></i>{{ subItem.descrizione }}</a>
<!--                   ACCEDI QLIK ONLY-->
                  <a *ngIf="subItem.url == 'main.accediqlik'" class="nav-link" (click)="onAccediQLikSense()" >
                    <img [src]="'assets/img/svg/' + subItem.icona || ''" onerror="this.style.display='none'">
                    <i [ngClass]="subItem.icona || ''"></i>{{ subItem.descrizione }}</a>
                </li>
              </ul>
            </li>
          </ng-template>
      </ng-container>
    </ng-template>
  </ul>
</nav>
