import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {GruppoUnitaOperativaComponent} from './gruppo-unita-operativa/gruppo-unita-operativa.component';
import {UnitaOperativaComponent} from './unita-operativa/unita-operativa.component';
import {SegnalazioneComponent} from './segnalazione/segnalazione.component';
import {AbilitazioneSegnalazioniComponent} from './abilitazione-segnalazioni/abilitazione-segnalazioni.component';
import {StatSegnalazioniComponent} from './stat-segnalazioni/stat-segnalazioni.component';

const routes: Routes = [{
  path: 'main.grpunitaoperative',
  component: GruppoUnitaOperativaComponent
}, {
  path: 'main.unitaoperative',
  component: UnitaOperativaComponent
}, {
  path: 'main.abilsegnalazioni',
  component: AbilitazioneSegnalazioniComponent
}, {
  path: 'main.segnalazioni',
  component: SegnalazioneComponent
}, {
  path: 'main.statsegn',
  component: StatSegnalazioniComponent
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SegnalazioniRoutingModule {
}
