import {NgModule} from '@angular/core';
import {MainRoutingModule} from './main-routing.module';
import {MainContainerComponent} from './main-container.component';
import {CommonModule} from '@angular/common';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {IframeComponent} from './iframe/iframe.component';
import {SharedModule} from '../shared/shared.module';
import { EmptyComponent } from './empty/empty.component';
import { HomeComponent } from './home/home.component';
import { ShowIframeComponent } from './show-iframe/show-iframe.component';
import {SegnalazioniModule} from './segnalazioni/segnalazioni.module';
import { DialogModule } from '@progress/kendo-angular-dialog';
import {TabelleModule} from './tabelle/tabelle.module';

@NgModule({
  imports: [
    MainRoutingModule,
    SharedModule,
    CommonModule,
    NgxJsonViewerModule,
    SegnalazioniModule,
    TabelleModule,
    DialogModule,
  ],
  declarations: [
    MainContainerComponent,
    IframeComponent,
    EmptyComponent,
    HomeComponent,
    ShowIframeComponent
  ]
})
export class MainModule {
}
