<!-- Toolbar Detail START -->
<div class="toolbar-detail">

  <!-- Reading mode -->
  <div *ngIf="readOnly">

    <div ng-show="!locked" style="display: inline-block">
      <!-- Validate -->
      <div class="toolbar-btn-container" *ngIf="updateEnabled && validateEnabled && showValidate">
        <a class="k-button validate" (click)="validateDetail.emit()">
          <span class="fas fa-certificate"></span> <span>{{'TOOLBAR_DETAIL.VALIDATE' | translate}}</span>
        </a>
      </div>
      <!-- Add Record -->
      <div class="toolbar-btn-container" *ngIf="insertEnabled && showAddRow">
        <a class="k-button" (click)="addRow.emit()">
          <span class="fa fa-plus"></span> <span>{{'TOOLBAR_GRID.ADD_RECORD' | translate}}</span>
        </a>
      </div>
      <!-- Open Record -->
      <div class="toolbar-btn-container pull-left" *ngIf="showOpenRow">
        <a class="k-button" (click)="openRow.emit()">
          <span class="fas fa-folder-open"></span> <span>{{'TOOLBAR_DETAIL.OPEN_DETAIL' | translate}}</span>
        </a>
      </div>
      <!-- Edit Detail -->
      <div class="toolbar-btn-container" *ngIf="updateEnabled && showEditRow">
        <a class="k-button" (click)="editRow.emit()">
          <span class="fas fa-edit"></span> <span>{{'TOOLBAR_DETAIL.EDIT_DETAIL' | translate}}</span>
        </a>
      </div>
      <!-- Custom Button 1 -->
      <div class="toolbar-btn-container" *ngIf="function1Enabled != null ? function1Enabled : showBtn1">
        <a class="k-button" (click)="function1.emit()">
          <span [ngClass]="iconBtn1"></span> <span>{{ textBtn1 | translate}}</span>
        </a>
      </div>
      <!-- Custom Button 2 -->
      <div class="toolbar-btn-container" *ngIf="function2Enabled  != null ? function2Enabled : showBtn2">
        <a class="k-button" (click)="function2.emit()">
          <span [ngClass]="iconBtn2"></span> <span>{{ textBtn2 | translate}}</span>
        </a>
      </div>
      <!-- Clone Detail -->
      <div class="toolbar-btn-container" *ngIf="insertEnabled && showCloneRow">
        <a class="k-button" (click)="cloneRow.emit()">
          <span class="fa fa-clone" aria-hidden="true"></span>
          <span>{{'TOOLBAR_DETAIL.CLONE_DETAIL' | translate}}</span>
        </a>
      </div>
      <!-- Delete Detail -->
      <div class="toolbar-btn-container" *ngIf="deleteEnabled && showDeleteRow">
        <a class="k-button" (click)="deleteRow.emit()">
          <span class="fa fa-trash"></span>
          <span>{{'TOOLBAR_DETAIL.DELETE_DETAIL' | translate}}</span>
        </a>
      </div>
    </div>
    <!-- Reset Detail -->
    <div class="toolbar-btn-container" *ngIf="resetEnabled && showReset">
      <a class="k-button" (click)="resetDetail.emit()" >
        <span class="fa fa-undo"></span> <span>{{'TOOLBAR_DETAIL.RESET_DETAIL' | translate}}</span>
      </a>
    </div>
    <!-- Print -->
    <div class="toolbar-btn-container" *ngIf="showPrint">
      <a class="k-button" (click)="print.emit()" >
        <span class="fa fa-print"></span> <span>{{'TOOLBAR_DETAIL.PRINT' | translate}}</span>
      </a>
    </div>
    <!-- Close Detail -->
    <div class="toolbar-btn-container" *ngIf="showClose">
      <a class="k-button" (click)="closeDetail.emit()" >
        <span class="fa fa-times"></span> <span>{{'TOOLBAR_DETAIL.CLOSE_DETAIL' | translate}}</span>
      </a>
    </div>
    <!-- Concurrency Error Message -->
    <div class="toolbar-btn-container record-blocked" *ngIf="locked">
      <span class="fas fa-ban"></span> <span>{{'TOOLBAR_DETAIL.BLOCKED_MSG' | translate}}</span>
    </div>

  </div>

  <!-- Editing mode -->

  <div *ngIf="!readOnly">
    <!-- Delete -->
    <div class="toolbar-btn-container" *ngIf="deleteEnabled && showConfirmDeleteRow && deleting" >
      <a class="k-button save" (click)="confirmDelete.emit()">
        <span class="fas fa-check"></span> <span>{{'TOOLBAR_DETAIL.DELETE_DETAIL' | translate}}</span>
      </a>
    </div>
    <!-- Update -->
    <div class="toolbar-btn-container" *ngIf="updateEnabled && showSave && !deleting">
      <a class="k-button save" (click)="save.emit()">
        <span class="fas fa-check"></span> <span>{{'TOOLBAR_DETAIL.UPDATE' | translate}}</span>
      </a>
    </div>
    <!-- Save draft -->
    <div class="toolbar-btn-container" *ngIf="updateEnabled && showSaveDraft">
      <a class="k-button save-draft" (click)="saveDraft.emit()">
        <span class="fas fa-save"></span> <span>{{'TOOLBAR_DETAIL.SAVE_DRAFT' | translate}}</span>
      </a>
    </div>
    <!-- Undo -->
    <div class="toolbar-btn-container" *ngIf="updateEnabled || deleteEnabled">
      <a class="k-button undo" (click)="cancel.emit()">
        <span class="fas fa-undo"></span> <span>{{'TOOLBAR_DETAIL.UNDO' | translate}}</span>
      </a>
    </div>

    <!-- Custom Editing  -->
    <div class="toolbar-btn-container" *ngIf="customEditFunctionEnabled != null ? customEditFunctionEnabled : showCustomEditBtn">
      <a class="k-button" (click)="customEditFunction.emit()">
        <span ng-class="iconCustomEditBtn"></span> <span>{{ textCustomEditBtn | translate}}</span>
      </a>
    </div>
  </div>
</div>
<!-- Toolbar Detail END -->
