import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {SegnalazioniRoutingModule} from './segnalazioni-routing.module';
import {GruppoUnitaOperativaComponent} from './gruppo-unita-operativa/gruppo-unita-operativa.component';
import {UnitaOperativaComponent} from './unita-operativa/unita-operativa.component';
import {ExcelModule, GridModule, PDFModule} from '@progress/kendo-angular-grid';
import {FormsModule} from '@angular/forms';
import {SegnalazioneComponent} from './segnalazione/segnalazione.component';
import {AbilitazioneSegnalazioniComponent} from './abilitazione-segnalazioni/abilitazione-segnalazioni.component';
import {SegnalazioneDetailComponent} from './segnalazione/segnalazione-detail.component';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {SharedModule} from '../../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {ComboBoxModule, DropDownListModule} from '@progress/kendo-angular-dropdowns';
import {TextBoxModule} from '@progress/kendo-angular-inputs';
import {CheckBoxModule} from '@progress/kendo-angular-treeview';
import {StatSegnalazioniComponent} from './stat-segnalazioni/stat-segnalazioni.component';
import {StatSegnalazioniSearchComponent} from './stat-segnalazioni/stat-segnalazioni-search.component';
import {ButtonGroupModule, ButtonModule} from '@progress/kendo-angular-buttons';
import {DatePickerModule} from '@progress/kendo-angular-dateinputs';
import {SegnalazionePrintComponent} from './stampa-segnalazione/segnalazione-print.component';
import {ButtonsModule} from 'ngx-bootstrap/buttons';


@NgModule({
  declarations: [
    GruppoUnitaOperativaComponent,
    UnitaOperativaComponent,
    SegnalazioneComponent,
    AbilitazioneSegnalazioniComponent,
    SegnalazioneDetailComponent,
    StatSegnalazioniComponent,
    StatSegnalazioniSearchComponent,
    SegnalazionePrintComponent
  ],
  exports: [
    SegnalazionePrintComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    SegnalazioniRoutingModule,
    GridModule,
    TabsModule,
    ExcelModule,
    PDFModule,
    TranslateModule,
    ComboBoxModule,
    TextBoxModule,
    CheckBoxModule,
    DropDownListModule,
    ButtonModule,
    ButtonGroupModule,
    DatePickerModule,
    ButtonsModule
  ]
})
export class SegnalazioniModule {
}
