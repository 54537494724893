import {Injectable} from '@angular/core';
import {CrudDatasource} from '../../../shared/crud/crud-datasource';
import {
  CreaCriticitaContrattoRequest,
  CriticitaContratto,
  CriticitaContrattoControllerService,
  CriticitaContrattoSearch,
  EliminaCriticitaContrattoRequest,
  GetCriticitaContrattoRequest,
  GetListaCriticitaContrattoRequest,
  ModificaCriticitaContrattoRequest,
  SovrascriviListaCriticitaContrattoRequest
} from '../../../api';
import {Observable, of} from 'rxjs';
import {ErrorService} from '../../../shared/core/error.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CriticitaContrattiService extends CrudDatasource<CriticitaContratto, CriticitaContratto, CriticitaContrattoSearch> {

  constructor(private service: CriticitaContrattoControllerService, errorService: ErrorService) {
    super([], errorService);
  }

  protected callLoad(searchRequest: CriticitaContrattoSearch | undefined): Observable<CriticitaContratto[] | undefined> {
    if (searchRequest) {
      const req: GetListaCriticitaContrattoRequest = {
        parametriRicerca: searchRequest
      };
      return this.service.getListaCriticitaContrattoUsingPOST(req).pipe(
        map(res => res.listaCriticitaContratto)
      );
    } else {
      return of([]);
    }
  }

  protected callGetByKey(key: any): Observable<CriticitaContratto | undefined> {
    const req: GetCriticitaContrattoRequest = {
      idCriticitaContratto: key
    };
    return this.service.getCriticitaContrattoUsingPOST(req).pipe(
      map(res => res.criticitaContratto)
    );
  }

  protected callInsert(values: CriticitaContratto): Observable<CriticitaContratto | undefined> {
    const req: CreaCriticitaContrattoRequest = {
      criticitaContratto: values
    };
    return this.service.creaCriticitaContrattoUsingPOST(req).pipe(
      map(res => res.criticitaContratto)
    );
  }

  protected callDelete(values: CriticitaContratto): Observable<any> {
    const req: EliminaCriticitaContrattoRequest = {
      criticitaContratto: values
    };
    return this.service.eliminaCriticitaContrattoUsingPOST(req);
  }

  protected callTotalCount(loadOptions: any): Observable<number> {
    const req: GetListaCriticitaContrattoRequest = {};
    return this.service.getListaCriticitaContrattoUsingPOST(req).pipe(
      map(res => res.listaCriticitaContratto?.length || 0)
    );
  }

  protected callUpdate(key: any, values: CriticitaContratto): Observable<CriticitaContratto | undefined> {
    // FIXME gestire update PK
    const req: ModificaCriticitaContrattoRequest = {
      criticitaContratto: values
    };
    return this.service.modificaCriticitaContrattoUsingPOST(req).pipe(
      map(res => res.criticitaContratto)
    );
  }

  getKeyField(): string {
    return 'id';
  }

  public overwriteAll(values: CriticitaContratto[], idContratto: number): Observable<CriticitaContratto[] | undefined> {
    this.reset();
    return this.callOverwriteAll(values, idContratto)
      .pipe(
        this.refreshAndErrorHandling()
      );
  }

  callOverwriteAll(values: CriticitaContratto[], idContratto: number): Observable<CriticitaContratto[] | undefined> {
    const req: SovrascriviListaCriticitaContrattoRequest = {
      listaCriticitaContratto: values,
      idContratto
    };
    return this.service.sovrascriviListaCriticitaContrattoUsingPOST(req).pipe(
      map(res => res.listaCriticitaContratto)
    );
  }

}
