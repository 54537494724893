import {BehaviorSubject, MonoTypeOperatorFunction, Observable, throwError} from 'rxjs';
import {deepCopy} from './deep-copy';
import {map, switchMap, tap} from 'rxjs/operators';
import {ErrorService} from '../core/error.service';

export abstract class CrudDatasource<T, L, S> extends BehaviorSubject<L[] | undefined> {
  data?: L[];
  searchParams?: S;
  nextId = 0;

  constructor(value: L[] | undefined, private errorService: ErrorService) {
    super(value);
  }

  public abstract getKeyField(): string;

  protected abstract callLoad(searchRequest?: S): Observable<L[] | undefined>;

  protected abstract callTotalCount(loadOptions: any): Observable<number>;

  protected abstract callGetByKey(key: any): Observable<T | undefined>;

  protected abstract callInsert(values: T): Observable<T | undefined>;

  protected abstract callUpdate(key: any, values: T): Observable<T | undefined>;

  protected abstract callDelete(values: T): Observable<any>;

  public newRecord(): T {
    return this.newRecordWithKey(this.nextNewRecordId());
  }

  public newRecordWithKey(key?: any): T {
    const newRow: any = {};
    newRow[this.getKeyField()] = key;
    return newRow;
  }

  public nextNewRecordId(): number {
    return --this.nextId;
  }

  public cloneRecord(values: T): T {
    return deepCopy(values); // Object.assign({}, values);
  }

  public cloneRecordByKey(key: any): Observable<T> {
    return this.getByKey(key).pipe(
      map((recordToClone) => {
        if (recordToClone) {
          const newRecord = deepCopy(recordToClone);
          (newRecord as any)[this.getKeyField()] = this.nextNewRecordId();
          return newRecord;
        } else {
          return this.newRecord();
        }
      })
    );
  }

  public copy(source: T, target: T): T {
    return Object.assign(target, source);
  }

  public getRecordId(record?: T): any {
    if (record) {
      return (record as any)[this.getKeyField()];
    } else {
      return undefined;
    }
  }

  public read(searchRequest?: S): void {
    if (this.data?.length && (!searchRequest || JSON.stringify(searchRequest) === JSON.stringify(this.searchParams))) {
      super.next(this.data);
    } else if (!searchRequest) {
      this.getList(this.searchParams).subscribe();
    } else {
      this.getList(searchRequest).subscribe();
    }
  }

  public getList(searchRequest?: S): Observable<L[] | undefined> {

    return this.callLoad((searchRequest ? searchRequest : this.searchParams))
      .pipe(
        tap(
          (data?: L[]) => {
            if (searchRequest) {
              this.searchParams = deepCopy(searchRequest);
            }
            this.data = data;
            super.next(this.data);
          }
        )
      );
  }

  public save(key: any, data: T, isNew?: boolean): Observable<T | undefined> {
    this.reset();
    if (isNew) {
      return this.callInsert(data)
        .pipe(
          this.refreshAndErrorHandling()
        );
    } else {
      return this.callUpdate(key, data)
        .pipe(
          this.refreshAndErrorHandling()
        );
    }
  }

  public getByKey(key: any): Observable<T | undefined> {
    // const record = this.data?.find((rec: T) => this.getRecordId(rec) === key);
    // if (record) {
    //   return of(record);
    // } else {
    return this.callGetByKey(key);
    // }
  }

  public remove(data: T): Observable<any> {
    return this.callDelete(data)
      .pipe(
        this.refreshAndErrorHandling()
      );
  }

  protected refreshAndErrorHandling(): MonoTypeOperatorFunction<any> {
    return tap(
      (value) => {
        this.read();
      },
      (jsonKoResult) => {
        this.errorService.showErrorDialog(jsonKoResult);
        this.read();
      }
    );
  }

  public deleteByKey(key: any): Observable<any> {
    this.reset();
    return this.getByKey(key).pipe(
      switchMap(rec => {
          if (rec) {
            return this.callDelete(rec)
              .pipe(
                this.refreshAndErrorHandling()
              );
          } else {
            return throwError('Record with key=' + key + ' cannot be found');
          }
        }
      )
    );
  }

  public resetItem(dataItem?: T): void {
    if (!dataItem) {
      return;
    }

    // find orignal data item
    const originalDataItem = this.data?.find(
      (item) => (item as any)[this.getKeyField()] === (dataItem as any)[this.getKeyField()]
    );

    // revert changes
    if (originalDataItem) {
      Object.assign(originalDataItem, dataItem);
    }

    super.next(this.data);
  }

  public clear(): void {
    this.reset();
    super.next(this.data);
  }

  protected reset(): void {
    this.data = [];
  }
}

