<div class="inline-crud-container">
  <app-contratti-search (search)="handleSearch($event)"></app-contratti-search>

  <form novalidate #myForm="ngForm">
    <app-toolbar-grid
      [editing]="editingEnabled"
      [updateEnabled]="updateEnabled"
      [insertEnabled]="false"
      [deleteEnabled]="false"
      [deleteClickable]="false"
      [openClickable]="false"
      [addClickable]="false"
      [updateClickable]="updateClickable"
      [refreshClickable]="false"
      [exportPdfClickable]="false"
      [exportXlsClickable]="false"
      (editRow)="editRecord()"
      (save)="saveList()"
      (cancel)="cancelEditing()"
    ></app-toolbar-grid>

    <p-pickList [source]="availableElementiControllo"
                [target]="selectedElementiControllo"
                sourceHeader="Elementi di controllo disponibili"
                targetHeader="Elementi di controllo selezionati"
                [dragdrop]="true"
                [responsive]="true"
                [sourceStyle]="{'height':'calc(100vh - 272px)'}"
                [targetStyle]="{'height':'calc(100vh - 272px)'}"
                filterBy="codice,descr"
                sourceFilterPlaceholder=""
                targetFilterPlaceholder=""
                [showSourceControls]="false"
                [showTargetControls]="false"
                [disabled]="!editingEnabled"
    >
      <ng-template let-elementoControllo pTemplate="item">
        <div class="item">
          <span class="codice">{{elementoControllo.codice}}</span><span class="descr">{{elementoControllo.descr}}</span>
        </div>
      </ng-template>
    </p-pickList>
  </form>
</div>
