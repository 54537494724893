<div class="search-panel row">
  <div class="col-md-10">
    <div class="row">
      <label class="col-1">Contratto</label>
      <kendo-combobox class="col-5"
                      [data]="listaContrattiFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleContrattiFilter($event)"
                      [textField]="'numero'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idContratto"
                      (valueChange)="handleContrattoChange($event)"
                      #idCtrCmb
                      (focusin)="idCtrCmb.toggle(true)"
      >
      </kendo-combobox>
      <label class="col-1">Gruppo U.O.</label>
      <kendo-combobox class="col-5"
                      [data]="listaGruppiUOFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleGruppiUOFilter($event)"
                      [textField]="'descrizioneGruppo'"
                      [valueField]="'idGruppoUnitaOp'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idGruppoUO"
                      (valueChange)="handleGruppoUoChange($event)"
                      #idGUOCmb
                      (focusin)="idGUOCmb.toggle(true)"
      >
      </kendo-combobox>
    </div>
    <div class="row">
      <label class="col-1">Cliente</label>
      <kendo-combobox class="col-5"
                      [data]="listaClientiFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleClienteFilter($event)"
                      [textField]="'ragSocCliente'"
                      [valueField]="'idCli'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idCliente"
                      (valueChange)="handleClienteChange($event)"
                      #idCliCmb
                      (focusin)="idCliCmb.toggle(true)"
      >
      </kendo-combobox>
      <label class="col-1">Dal</label>
      <kendo-datepicker [format]="format" [(value)]="fromDate" class="col-2">
        <kendo-datepicker-messages
          today="OGGI"
        ></kendo-datepicker-messages>
      </kendo-datepicker>
      <label class="col-1">Al</label>
      <kendo-datepicker [format]="format" [(value)]="toDate" class="col-2">
        <kendo-datepicker-messages
          today="OGGI"
        ></kendo-datepicker-messages>
      </kendo-datepicker>
    </div>
    <div class="row">
      <label class="col-1">Servizio</label>
      <kendo-combobox class="col-5"
                      [data]="listaServiziFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleServiziFilter($event)"
                      [textField]="'descrizioneServizio'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [ngModel]="idSerCliCon"
                      (ngModelChange)="handleServizioChange($event)"
                      #idSerCmb
                      (focusin)="idSerCmb.toggle(true)"
      >
      </kendo-combobox>
      <label class="col-2">Stato</label>
      <kendo-buttongroup selection="multiple" class="col-4" [disabled]="disabled">
        <button *ngFor="let button of buttonTypes"
                kendoButton
                [toggleable]="true"
                (selectedChange)="statoChange($event, button)"
                [ngClass]="'stato-filter-btn stato-filter-btn-' + button.toString()">
          {{ buttonsAndDescr && buttonsAndDescr[button] ? buttonsAndDescr[button] : button }}
        </button>
      </kendo-buttongroup>

    </div>
  </div>
  <div class="col-md-2">
    <button kendoButton [primary]="true" (click)="handleSearch()">Cerca</button>
  </div>
</div>
