import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() title!: string;
  @Input() message!: string;
  @Input() data: any;
  @Input() showCancel!: boolean;

  @Output() onConfirm: EventEmitter<any> = new EventEmitter();
  @Output() onCancel: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  confirm(): void {
    if (this.onConfirm) {
      this.onConfirm.emit(true);
    }

    this.bsModalRef.hide();
  }

  cancel(): void {
    if (this.onCancel) {
      this.onCancel.emit(false);
    }

    this.bsModalRef.hide();
  }
}
