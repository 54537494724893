<div class="search-panel row">
  <div class="col-md-12">
    <div class="row">
      <label class="col-1">Contratto</label>
      <kendo-combobox class="col"
                      [data]="listaContrattiFiltered"
                      [suggest]="true"
                      [filterable]="true"
                      (filterChange)="handleContrattiFilter($event)"
                      [textField]="'numero'"
                      [valueField]="'id'"
                      [valuePrimitive]="true"
                      [(ngModel)]="idContratto"
                      (valueChange)="handleContrattoChange($event)"
                      #idCcontrattoCmb
                      (focusin)="idCcontrattoCmb.toggle(true)"
      >
      </kendo-combobox>
    </div>
  </div>
</div>
