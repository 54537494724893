import {Component, NgZone, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseComponent} from '../shared/core/base-component';
import {IframeState, NavStateService} from '../shared/core/nav-state.service';
import {MessageBusService} from '../shared/core/message-bus.service';
import {AuthService} from '../shared/core/auth.service';
import {ShowIframeComponent} from './show-iframe/show-iframe.component';
import {environment} from '../../environments/environment';
import {SegnalazioneDetail} from './segnalazioni/segnalazione/segnalazione.service';
import {PrintService} from '../shared/core/print.service';

@Component({
  selector: 'app-main',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainContainerComponent extends BaseComponent implements OnInit {
  public menuVisible = true;
  public disabled = false;
  public status: { isopen: boolean } = {isopen: false};

  version!: string;

  iframeState!: IframeState;
  showIframe = false;
  showLoading = true;

  segnalazionePrint: SegnalazioneDetail | null = null;
  printLoading = false;

  constructor(private router: Router,
              private authService: AuthService,
              private msgBus: MessageBusService,
              private navState: NavStateService,
              private route: ActivatedRoute,
              private ngZone: NgZone,
              private printService: PrintService) {
    super();
    this.version = environment.VERSION;
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  ngOnInit(): void {
    this.navState.init();
    super.addSubscription(
      this.route.queryParamMap.subscribe((params) => {
        // Global URL changed
        this.navState.navigationFromBrowser(params);
      })
    );
    super.addSubscription(
      this.navState.onStateChanged().subscribe((state) => {
        if (state) {
          // App redirect to /login
          if (state.url === '/login') {
            this.authService.setUser(null);
            setTimeout(() => this.router.navigateByUrl(''), 0);
          }
          // App State changed
          this.iframeState = state;
        }
      })
    );
    super.addSubscription(
      this.navState.firstNavigation.subscribe((value) => {
        this.showLoading = !value;
      })
    );

    super.addSubscription(
      this.printService.$segnalazionePrint.subscribe(value => {
        this.segnalazionePrint = value;
        this.printLoading = true;
      })
    );
  }

  routerActivated($event: any): void {
    if ($event instanceof ShowIframeComponent) {
      this.showIframe = true;
    } else {
      this.showIframe = false;
    }
  }

  toggleMenu(): void {
    this.menuVisible = !this.menuVisible;
  }

  afterPrint() {
    this.segnalazionePrint = null;
    this.printLoading = false;
  }
}
