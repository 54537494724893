import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/core/base-component';
import {LoginControllerService, LoginRequestBean, SsoParams} from '../../api';
import {ActivatedRoute, Router} from '@angular/router';
import CryptoES from 'crypto-es';
import {AuthService} from '../../shared/core/auth.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends BaseComponent implements OnInit {
  isLoading: Boolean = false;
  username = '';
  password = '';
  ssoParams?: SsoParams = undefined;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private loginService: LoginControllerService,
              private authService: AuthService,
              public toastrService: ToastrService) {
    super();

    this.route.queryParams.subscribe((params: { [x: string]: any; }) => {
      let t = params['token'];
      let u = params['extuid'];
      let d = params['dataora'];

      if(t != null && u != null && d != null) {
        this.ssoParams = {
          token: t,
          extUid: u,
          dataOra: d
        }
      }
    });
  }

  ngOnInit(): void {
    if(this.ssoParams != null){
      // SSO automatic login
      this.tryLogIn(true)
    }
  }

  tryLogIn(isSSO: Boolean): void {
    this.isLoading = true;
    this.authService.clearLocalStorage();

    let requestBean: LoginRequestBean;
    if(isSSO){
      requestBean = {
        ssoParams: this.ssoParams
      }
    } else {
      requestBean = {
        username: this.username,
        password: CryptoES.SHA256(this.password).toString(CryptoES.enc.Base64)
      }
    }

    this.loginService.loginUsingPOST(requestBean).subscribe(
      (resp) => {
        this.authService.setUser(resp);
        this.router.navigate(['main']);
      }, error => {
        this.isLoading = false;
        console.log(error);
        this.toastrService.error(error, 'ERROR');
      });
  }

}
