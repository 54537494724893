/**
 * Data contained in messages of type {@link BusMessageType.URL_UPDATED}
 */
export interface BusMessageDataUrlUpdated {
  url: string;
  params: any;
  path: string;
}

/**
 * Data contained in messages of type {@link BusMessageType.NAVIGATE_TO}
 */
export interface BusMessageDataNavigateTo {
  path: string;
  params: {};
}

/**
 * Message types
 */
export enum BusMessageType {
  URL_UPDATED = 'URL_UPDATED',
  NAVIGATE_TO = 'NAVIGATE_TO',
}

/**
 * Syntactic-sugar for messages managed internally by the {@link MessageBusService}
 */
export type BusMessage = {
  type: BusMessageType;
  data: BusMessageData;
};

/**
 * Syntactic-sugar for messages data managed internally by the {@link MessageBusService}
 */
export type BusMessageData = BusMessageDataUrlUpdated | BusMessageDataNavigateTo;
