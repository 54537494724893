import {Component, OnInit} from '@angular/core';

import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {environment} from '../../environments/environment';
import {AuthService} from './core/auth.service';
import {LoginControllerService} from '../api';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-right-nav-items',
  templateUrl: './right-nav-items.component.html',
  styleUrls: ['./right-nav-items.component.scss'],
  providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}}]
})
export class RightNavItemsComponent implements OnInit {

  bsModalRef!: BsModalRef;
  username?: string;
  version = environment.VERSION;

  constructor(
    public api: LoginControllerService,
    public auth: AuthService,
    // public settings: BaseSettingsService,
    public modalService: BsModalService) {
  }

  public logout(): void {
    this.api.logoutUsingPOST().subscribe((x: any) => {
      this.auth.setUser(null);
      this.auth.goToLogin();
    });
  }

  ngOnInit(): void {
    this.username = this.auth.getUser()?.description;
  }

  //public modificaPasswordClick(): void {
  // const user = this.settings.getSettings('user');
  // this.bsModalRef = this.modalService.show(ModifyPasswordModalComponent, {
  //   ignoreBackdropClick: true,
  //   class: 'modal-md'
  // });
  // this.bsModalRef.content.idUser = user.id;
  //}

  public showHelp(): void {
    window.open('assets/help/MANUALE.pdf', 'escape_help');
  }

  contactClick() {

  }
}
